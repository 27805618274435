import qs from 'qs';
import { ProjectIssueRootCause } from '../model/entities';
import { proxyApiAxios } from './CustomAxios';

class ProjectIssueRootCauseApi {
    list = async (hubId: string, projectId: string[]): Promise<ProjectIssueRootCause[]> => {
        const response = await proxyApiAxios.get<ProjectIssueRootCause[]>('/project-issue-root-causes', {
            params: { hubId, projectId },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        return response.data;
    };
}

const projectIssueRootCauseApi: ProjectIssueRootCauseApi = new ProjectIssueRootCauseApi();
export default projectIssueRootCauseApi;
