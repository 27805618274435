import { Result } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';

const LoginErrorPage = (): React.ReactElement => {
    /*** EFFECTS ***/

    /** METHODS **/

    /*** COMPONENTS ***/

    return (
        <LayoutComponent pageId="home" style={{ background: '#f2f2f2' }}>
            <Result
                status="500"
                title={<FormattedMessage id="loginError.title" />}
                subTitle={<FormattedMessage id="loginError.description" />}
            />
        </LayoutComponent>
    );
};

export default LoginErrorPage;
