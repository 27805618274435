import React from 'react';
import { useIntl } from 'react-intl';
import Search from 'antd/lib/input/Search';

import { useTaskProjectContext } from './TaskProjectContext';

function TaskProjectToolbar() {
    const intl = useIntl();
    const placeholder: string = intl.formatMessage({ id: 'taskProjectActions.search' });
    const { onSearch } = useTaskProjectContext();

    return (
        <div className="toolbar">
            <Search placeholder={placeholder} onSearch={onSearch} size="large" className="search" />
        </div>
    );
}

export default TaskProjectToolbar;
