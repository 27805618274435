import FileSaver from 'file-saver';
import moment from 'moment';
import { Page } from '../model/elements';
import { TaskSyncFileTarget } from '../model/entities';
import { TaskStatusType } from '../model/types';
import { syncApiAxios } from './CustomAxios';

class TaskSyncFileTargetApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskSyncFileId: number,
        searchText?: string,
    ): Promise<Page<TaskSyncFileTarget>> => {
        const response = await syncApiAxios.get<Page<TaskSyncFileTarget>>('/task-sync-file-targets', {
            params: { page, size, sortField, sortOrder, taskSyncFileId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.audit!.created = d.audit!.created && moment.utc(d.audit!.created).local();
            d.audit!.updated = d.audit!.updated && moment.utc(d.audit!.updated).local();
        });

        return response.data;
    };

    listByTaskSync = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskSyncId: number,
        statuses: TaskStatusType[],
        searchText?: string,
    ): Promise<Page<TaskSyncFileTarget>> => {
        const response = await syncApiAxios.get<Page<TaskSyncFileTarget>>('/task-sync-file-targets', {
            params: { page, size, sortField, sortOrder, taskSyncId, statuses: statuses.join(','), searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.audit!.created = d.audit!.created && moment.utc(d.audit!.created).local();
            d.audit!.updated = d.audit!.updated && moment.utc(d.audit!.updated).local();
        });

        return response.data;
    };

    retry = async (id: number): Promise<TaskSyncFileTarget> => {
        const response = await syncApiAxios.post<TaskSyncFileTarget>(`/task-sync-file-targets/${id}/retries`);

        return response.data;
    };

    exportSpreadSheet = async (
        sortField: string,
        sortOrder: boolean,
        taskSyncId: number,
        statuses: TaskStatusType[],
        searchText?: string,
    ) => {
        const response = await syncApiAxios.get<Blob>(`/task-sync-file-targets/spreadsheets`, {
            responseType: 'blob',
            params: { sortField, sortOrder, taskSyncId, statuses: statuses.join(','), searchText },
        });
        FileSaver.saveAs(response.data, 'task-sync-file-targets.xlsx');
    };
}

const taskSyncFileTargetApi: TaskSyncFileTargetApi = new TaskSyncFileTargetApi();
export default taskSyncFileTargetApi;
