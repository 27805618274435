import Icon from '@ant-design/icons';
import { Button, Empty, List } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { EntityWithStatus, Project, ProjectMember } from '../../../../../model/entities';
import { ReactComponent as ArrowLeftSvg } from '../../../../../resources/images/arrow-left.svg';
import styles from './ResultComponent.module.scss';

/**
 * Returns the project issues import result component.
 * @props the props
 * @returns the project issues import result component.
 */
const ResultComponent = (props: Props): React.ReactElement => {
    const { result, hubId, projects } = props;
    const projectIdsAsParam = projects.map((project) => 'projectId=' + project.id).join('&');
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <>
            <FailuresComponent result={result} projects={projects} />
            <JobsComponent result={result} />
            <SuccessesComponent result={result} projects={projects} />
            {result.length === 0 && <Empty className={styles.empty} />}
            <div className={styles.buttons}>
                <Link to={`/setup/project-members?hubId=${hubId}&${projectIdsAsParam}`}>
                    <Button type="primary" size="large" icon={<Icon component={ArrowLeftSvg} />}>
                        <FormattedMessage id="projectMembers.import.steps.3.back" tagName="span" />
                    </Button>
                </Link>
            </div>
        </>
    );
};

export default ResultComponent;

interface Props {
    hubId: string;
    projects: Project[];
    result: EntityWithStatus<ProjectMember>[];
}

const FailuresComponent = (props: FailuresProps): React.ReactElement => {
    const { result, projects } = props;

    /*** HOOKS ***/

    const intl = useIntl();

    /*** COMPONENTS ***/

    const failures = result.filter((r) => r.status.type !== 'OK' && r.status.type !== 'ACCEPTED');
    if (failures.length > 0) {
        return (
            <List
                header={
                    <h2>
                        <FormattedMessage id="projectMembers.import.steps.3.failure" />
                    </h2>
                }
                dataSource={failures}
                renderItem={(failure, index) => {
                    const projectMember = failure.entity;
                    const email = projectMember.email || '...';
                    const project = projects.find((p) => p.id === projectMember.project?.id)?.name || '...';
                    return (
                        <List.Item key={index}>
                            <List.Item.Meta title={email} description={project} />
                            <span className={styles.error}>
                                {failure.status.error
                                    ? intl.formatMessage({ id: failure.status.error })
                                    : failure.status.message}
                            </span>
                        </List.Item>
                    );
                }}
                className={styles.failure}
            />
        );
    } else {
        return <></>;
    }
};

interface FailuresProps {
    projects: Project[];
    result: EntityWithStatus<ProjectMember>[];
}

const JobsComponent = (props: JobsProps): React.ReactElement => {
    const { result } = props;

    /*** HOOKS ***/

    const intl = useIntl();

    /*** COMPONENTS ***/

    const jobs = result.filter((r) => r.status.type === 'ACCEPTED');
    if (jobs.length > 0) {
        return (
            <List
                header={
                    <h2>
                        <FormattedMessage id="projectMembers.import.steps.3.jobs" />
                    </h2>
                }
                dataSource={jobs.slice(0, 1)}
                renderItem={(job, index) => {
                    return (
                        <List.Item key={index}>
                            <span className={styles.error}>
                                {intl.formatMessage({ id: 'projectMembers.import.steps.3.jobs.message' })}
                            </span>
                        </List.Item>
                    );
                }}
                className={styles.success}
            />
        );
    } else {
        return <></>;
    }
};

interface JobsProps {
    result: EntityWithStatus<ProjectMember>[];
}

const SuccessesComponent = (props: FailuresProps): React.ReactElement => {
    const { result, projects } = props;

    /*** HOOKS ***/

    /*** COMPONENTS ***/

    const successes = result.filter((r) => r.status.type === 'OK');
    if (successes.length > 0) {
        return (
            <List
                header={
                    <h2>
                        <FormattedMessage id="projectMembers.import.steps.3.success" />
                    </h2>
                }
                dataSource={successes}
                renderItem={(success, index) => {
                    const projectMember = success.entity;
                    const email = projectMember.email || '...';
                    const project = projects.find((p) => p.id === projectMember.project?.id)?.name;
                    return (
                        <List.Item key={index}>
                            <List.Item.Meta title={email} description={project} />
                        </List.Item>
                    );
                }}
                className={styles.success}
            />
        );
    } else {
        return <></>;
    }
};
