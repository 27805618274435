import qs from 'qs';
import { Location } from '../model/entities';
import { proxyApiAxios } from './CustomAxios';

class LocationApi {
    list = async (hubId: string, projectId: string[]): Promise<Location[]> => {
        const response = await proxyApiAxios.get<Location[]>('/locations', {
            params: { hubId, projectId },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        return response.data;
    };
}

const locationApi: LocationApi = new LocationApi();
export default locationApi;
