import React, { useEffect } from 'react';
import authService from '../../security/IAuthService';

const LoginPage = (): React.ReactElement => {
    /*** EFFECTS ***/
    useEffect(() => {
        const init = async () => {
            try {
                const isAuthenticated = await authService.isAuthenticated();
                if (isAuthenticated) {
                    window.location.href = '/';
                } else {
                    await authService.signIn();
                }
            } catch (error) {}
        };

        init();
    }, []);

    /** METHODS **/

    /*** COMPONENTS ***/

    return <></>;
};

export default LoginPage;
