import { ConnectionLink, ConnectionLinkHubImport, ConnectionLinkImport, EntityWithStatus } from '../model/entities';
import adminApiAxios, { proxyApiAxios } from './CustomAxios';

class ConnectionLinkApi {
    list = async (connectionId: number): Promise<ConnectionLink[]> => {
        const response = await adminApiAxios.get<ConnectionLink[]>('/connection-links', {
            params: { connectionId },
        });

        return response.data;
    };

    get = async (id: number): Promise<ConnectionLink> => {
        const response = await adminApiAxios.get<ConnectionLink>(`/connection-links/${id}`);
        return response.data;
    };

    create = async (connectionLink: ConnectionLink): Promise<ConnectionLink> => {
        const response = await adminApiAxios.post<ConnectionLink>('/connection-links', connectionLink);
        return response.data;
    };

    update = async (connectionLink: ConnectionLink): Promise<ConnectionLink> => {
        const response = await adminApiAxios.put<ConnectionLink>(
            `/connection-links/${connectionLink.id}`,
            connectionLink,
        );
        return response.data;
    };

    delete = async (connectionLink: ConnectionLink): Promise<void> => {
        await adminApiAxios.delete(`/connection-links/${connectionLink.id}`);
    };

    loadSpreadsheet = async (file: any, hubImport: ConnectionLinkHubImport): Promise<ConnectionLinkImport[]> => {
        const formData = this.buildFormData(hubImport, file);
        const response = await proxyApiAxios.post<ConnectionLinkImport[]>('/connection-links/spreadsheets', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    };

    import = async (connectionLinks: ConnectionLink[]): Promise<EntityWithStatus<ConnectionLink>[]> => {
        const response = await adminApiAxios.post<EntityWithStatus<ConnectionLink>[]>(
            '/connection-links/bulk',
            connectionLinks,
        );
        return response.data;
    };

    /**
     * Builds a form data from a hub import object.
     * @param hubImport the hub import object
     * @param file the file
     * @returns the formdata
     */
    private buildFormData = (hubImport: ConnectionLinkHubImport, file: any) => {
        const formData: FormData = new FormData();
        const hubImportJson = JSON.stringify(hubImport);
        const hubImportBlob = new Blob([hubImportJson], { type: 'application/json' });
        formData.append('hubImport', hubImportBlob);
        formData.append('attachment', file);

        return formData;
    };
}

const connectionLinkApi: ConnectionLinkApi = new ConnectionLinkApi();
export default connectionLinkApi;
