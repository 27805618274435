import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Button, Descriptions, Space, Tooltip } from 'antd';
import Icon from '@ant-design/icons';

import { ReactComponent as BackSvg } from '../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../resources/images/refresh.svg';
import TaskStatusComponent from '../../../../components/TaskStatusComponent/TaskStatusComponent';
import { useTaskProjectContext } from './TaskProjectContext';
import styles from './TaskProjectPage.module.scss';

function TaskProjectSummary() {
    const { task, list } = useTaskProjectContext();

    return (
        <Descriptions
            title={
                <div className={styles.titlebox}>
                    <span className={styles.title}>
                        #{task?.id} - {task?.name && <FormattedMessage id={task?.name} />}
                    </span>
                    <TaskStatusComponent status={task?.status} />
                    <Space>
                        <Tooltip title={<FormattedMessage id="button.back" />}>
                            <Link to="/tasks">
                                <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                            </Link>
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id="button.refresh" />}>
                            <Button type="ghost" size="small" icon={<Icon component={RefreshSvg} />} onClick={list} />
                        </Tooltip>
                    </Space>
                </div>
            }
            column={2}
            size="small"
            className={styles.summary}
        >
            <Descriptions.Item label={<FormattedMessage id="task.audit.creator" />} span={task?.hub ? 1 : 2}>
                {task?.audit?.creator.firstName} {task?.audit?.creator.lastName}
            </Descriptions.Item>
            {task?.hub && (
                <Descriptions.Item label={<FormattedMessage id="task.audit.hub" />}>
                    {`${task?.hub?.name} [${task?.hub?.provider}]`}
                </Descriptions.Item>
            )}
            <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                <FormattedDate
                    value={task?.audit?.created as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            </Descriptions.Item>
            <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                <FormattedDate
                    value={task?.audit?.updated as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            </Descriptions.Item>
            <Descriptions.Item>{task?.description}</Descriptions.Item>
        </Descriptions>
    );
}

export default TaskProjectSummary;
