import moment from 'moment';
import { Page } from '../model/elements';
import { TaskProjectIssueActionFile } from '../model/entities';
import adminApiAxios from './CustomAxios';

class TaskProjectIssueActionFileApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskProjectIssueActionId: number,
        searchText?: string,
    ): Promise<Page<TaskProjectIssueActionFile>> => {
        const response = await adminApiAxios.get<Page<TaskProjectIssueActionFile>>('/task-project-issue-action-files', {
            params: { page, size, sortField, sortOrder, taskProjectIssueActionId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.audit!.created = d.audit!.created && moment.utc(d.audit!.created).local();
            d.audit!.updated = d.audit!.updated && moment.utc(d.audit!.updated).local();
        });

        return response.data;
    };
}

const taskProjectIssueActionFileApi: TaskProjectIssueActionFileApi = new TaskProjectIssueActionFileApi();
export default taskProjectIssueActionFileApi;
