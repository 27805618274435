import moment from 'moment';
import { TaskSmartDelivery } from '../model/entities';
import { syncApiAxios } from './CustomAxios';

class TaskSmartDeliveryApi {
    get = async (id: number): Promise<TaskSmartDelivery> => {
        const response = await syncApiAxios.get<TaskSmartDelivery>(`/task-smart-deliveries/${id}`);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };
}

const taskSmartDeliveryApi: TaskSmartDeliveryApi = new TaskSmartDeliveryApi();
export default taskSmartDeliveryApi;
