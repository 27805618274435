import { CacheLookupPolicy, InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';
import userApi from '../api/UserApi';
import configuration from '../configs/IConfiguration';
import { CustomAuth, Organization, Principal } from '../model/entities';
import autodeskService from '../services/AutodeskService';
import { IAuthService } from './IAuthService';

export default class AzureAuthService implements IAuthService {
    readonly azureUsername = 'azureUsername';
    auth: CustomAuth | undefined;
    azureEntraIdClient: PublicClientApplication;

    constructor() {
        this.azureEntraIdClient = new PublicClientApplication(configuration.getAuth());
    }

    getAuth = async (): Promise<CustomAuth | undefined> => {
        try {
            await this.azureEntraIdClient.initialize();

            // check if there is an oauth2 redirect (initialize authentication from the authorization code)
            const tokenResponse = await this.azureEntraIdClient.handleRedirectPromise();

            // if authorization code token response exists (user is just authenticated), create auth (principal)
            if (tokenResponse) {
                this.azureEntraIdClient.setActiveAccount(tokenResponse.account);
                this.auth = await this.createAuthFromAzureEntraId();
                localStorage.setItem(this.azureUsername, this.auth.email);
            }
            // if there are already accounts, active first account and get access token (cache, silent, redirect to login)
            else {
                const accessToken = await this.getAccessToken();
                if (accessToken) {
                    this.auth = await this.createAuthFromAzureEntraId();
                    localStorage.setItem(this.azureUsername, this.auth.email);
                } else {
                    this.auth = undefined;
                    localStorage.removeItem(this.azureUsername);
                    await this.azureEntraIdClient.loginRedirect({
                        authority: configuration.getAuth().auth.authority as string,
                        scopes: configuration.getAuth().auth.OIDCOptions.defaultScopes,
                        loginHint: localStorage.getItem(this.azureUsername) || undefined,
                    });
                }
            }
        } catch (error) {
            console.log(error);
            this.auth = undefined;
            localStorage.removeItem(this.azureUsername);
            await this.azureEntraIdClient.loginRedirect({
                authority: configuration.getAuth().auth.authority as string,
                scopes: configuration.getAuth().auth.OIDCOptions.defaultScopes,
                loginHint: localStorage.getItem(this.azureUsername) || undefined,
            });
        }

        return this.auth;
    };

    getAuthByOrganization = async (organization: Organization): Promise<CustomAuth | undefined> => {
        try {
            if (this.auth) {
                this.auth.organization = organization;
                this.auth = await this.createAuthFromAzureEntraId();
                localStorage.setItem(this.azureUsername, this.auth.email);
            }
        } catch (error) {
            console.log(error);
            this.auth = undefined;
            localStorage.removeItem(this.azureUsername);
            await this.azureEntraIdClient.loginRedirect({
                authority: configuration.getAuth().auth.authority as string,
                scopes: configuration.getAuth().auth.OIDCOptions.defaultScopes,
                loginHint: localStorage.getItem(this.azureUsername) || undefined,
            });
        }

        return this.auth;
    };

    getCachedAuth = (): CustomAuth | undefined => this.auth;

    /**
     * Returns the access token. It  will attempt to retrieve an access token from the cache. If the access token is expired or
     * cannot be found the refresh token will be used to acquire a new one. Finally, if the refresh token is expired, acquireTokenSilent
     * will attempt to silently acquire a new access token, id token, and refresh token.
     * @returns the access token
     */
    getAccessToken = async (): Promise<string | undefined> => {
        let accessToken: string | undefined = undefined;
        if (this.azureEntraIdClient.getAllAccounts().length > 0) {
            this.azureEntraIdClient.setActiveAccount(this.azureEntraIdClient.getAllAccounts()[0]);
            accessToken = await this.getAccessTokenFromCache();
            if (!accessToken) {
                accessToken = await this.refreshAcessToken();
            }
        }

        return accessToken;
    };

    getOrganizationId = (): string | undefined => {
        let organizationId: string | undefined;
        if (this.auth && this.auth.organization) {
            organizationId = this.auth.organization.id;
        } else {
            organizationId = localStorage.getItem('organizationId') || undefined;
        }

        return organizationId;
    };

    signIn = async () => {
        await this.azureEntraIdClient.loginRedirect();
    };

    signOut = async () => {
        autodeskService.logOut();
        this.auth = undefined;
        localStorage.removeItem(this.azureUsername);
        await this.azureEntraIdClient.logoutRedirect();
    };

    isAuthenticated = async () => {
        const accessToken = await this.getAccessTokenFromCache();

        return !!accessToken;
    };

    /**
     * Returns the access token from the cache if the user is authenticated. It doesn't attempt to renew access or refresh tokens.
     * @returns the access token
     */
    private getAccessTokenFromCache = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;

        try {
            const account = this.azureEntraIdClient.getActiveAccount();
            if (account) {
                const accessTokenRequest = {
                    account: account,
                    cacheLookupPolicy: CacheLookupPolicy.AccessToken,
                    authority: configuration.getAuth().auth.authority as string,
                    scopes: configuration.getAuth().auth.OIDCOptions.defaultScopes,
                    loginHint: localStorage.getItem(this.azureUsername) || undefined,
                };
                const authenticationResult = await this.azureEntraIdClient.acquireTokenSilent(accessTokenRequest);
                accessToken = authenticationResult.accessToken;
            }
        } catch (error) {
            console.log(error);
        }

        return accessToken;
    };

    /**
     * Refreshes the access token. It  will attempt to retrieve an access token from the cache. If the access token is expired or
     * cannot be found the refresh token will be used to acquire a new one. Finally, if the refresh token is expired, acquireTokenSilent
     * will attempt to silently acquire a new access token, id token, and refresh token.
     * @returns the access token
     */
    private refreshAcessToken = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;
        const account = this.azureEntraIdClient.getActiveAccount();

        const accessTokenRequest = {
            account: account || undefined,
            redirectUri: `${configuration.getAuth().auth.redirectUri}/blank.html`,
            authority: configuration.getAuth().auth.authority as string,
            scopes: configuration.getAuth().auth.OIDCOptions.defaultScopes,
            loginHint: localStorage.getItem(this.azureUsername) || undefined,
        };

        // get access token from the browser session or retrieve new one
        try {
            const authenticationResult = await this.azureEntraIdClient.acquireTokenSilent(accessTokenRequest);
            accessToken = authenticationResult.accessToken;
        } catch (errorSilentRefresh) {
            if (errorSilentRefresh instanceof InteractionRequiredAuthError) {
                try {
                    const accessTokenRequest = {
                        account: account || undefined,
                        redirectUri: configuration.getAuth().auth.redirectUri,
                        authority: configuration.getAuth().auth.authority as string,
                        scopes: configuration.getAuth().auth.OIDCOptions.defaultScopes,
                        loginHint: localStorage.getItem(this.azureUsername) || undefined,
                    };
                    await this.azureEntraIdClient.acquireTokenRedirect(accessTokenRequest);
                } catch (errorTokenRedirect) {
                    console.log(errorTokenRedirect);
                    this.azureEntraIdClient.clearCache({
                        account: account,
                    });
                    this.auth = undefined;
                    localStorage.removeItem(this.azureUsername);
                }
            } else {
                console.log(errorSilentRefresh);
                this.azureEntraIdClient.clearCache({
                    account: account,
                });
                this.auth = undefined;
                localStorage.removeItem(this.azureUsername);
            }
        }

        return accessToken;
    };

    /**
     * Returns the auth from the current user.
     * @param organization the organization
     * @returns the auth
     */
    private async createAuthFromAzureEntraId(): Promise<CustomAuth> {
        // get principal
        let customAuth: CustomAuth | undefined;
        try {
            const principal: Principal = await userApi.getPrincipal();

            customAuth = {
                id: principal.id!,
                email: principal.email!,
                firstName: principal.firstName!,
                lastName: principal.lastName!,
                organization: principal.organization,
                organizations: principal.organizations,
                authorities: principal.authorities,
                initialized: principal.authorities.length > 0,
                members: [],
            };
        } catch (error: any) {
            console.log(error);
            // user not authenticated in resource server - non initialized user
            customAuth = {
                id: '',
                email: '',
                firstName: '',
                lastName: '',
                organizations: [],
                authorities: [],
                initialized: false,
                members: [],
            };
        }

        return customAuth;
    }
}
