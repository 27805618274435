import Icon from '@ant-design/icons';
import { Button, DatePicker, DatePickerProps, Input, Select, Spin } from 'antd';
import { Moment } from 'moment';
import React, { useCallback, useState } from 'react';

import { ReactComponent as CheckmarkSvg } from '../../../../../resources/images/checkmark.svg';
import { ReactComponent as CloseSvg } from '../../../../../resources/images/close-outline.svg';
import { ReactComponent as EditSvg } from '../../../../../resources/images/edit.svg';

import { ProjectImport } from '../../../../../model/entities';
import { bim360ProjectServicesTypes, currencyTypes, projectImportTypes } from '../../../../../model/types';
import styles from '../ImportPage.module.scss';
import { useSetupProjectsImport } from '../SetupProjectsImportContext';

const projectNumberValitationRegex = /([\w0-9]+-?[\w0-9]?)/;

const ColumnWithErrorMessage: React.FC<{
    errorMessage?: string;
    children: React.ReactNode;
    isErrorIncluded?: () => boolean;
}> = ({ children, errorMessage, isErrorIncluded }) => {
    return isErrorIncluded && isErrorIncluded() ? (
        <div className={styles.itembox}>
            <div>{children}</div>
            <div className={styles.errormessage}>{errorMessage}</div>
        </div>
    ) : (
        <>{children}</>
    );
};

interface Props {
    projectIndex: number;
}

const EditableCell: React.FC<Props> = ({ projectIndex }) => {
    const [isEditable, setIsEditable] = useState(false);

    const { templates, projects, hub, isTemplatesLoading, setProjects, platform } = useSetupProjectsImport();

    const getServiceTypeTitles = (services?: string[]) => {
        if (!services) return [];
        return services
            .map((s) => bim360ProjectServicesTypes.find((t) => t.value === `${s}`)?.key)
            .filter((s) => s !== undefined) as string[];
    };

    const [startDate, setStartDate] = useState(projects[projectIndex].startDate);
    const [endDate, setEndDate] = useState(projects[projectIndex].endDate);
    const [template, setTemplate] = useState(projects[projectIndex].template);
    const [serviceTypes, setServiceTypes] = useState(getServiceTypeTitles(projects[projectIndex].serviceTypes));
    const [editedValue, setEditedValue] = useState<Partial<ProjectImport>>(projects[projectIndex]);

    const handleIconClick = () => {
        setIsEditable(true);
    };

    const handleCancel = () => {
        setIsEditable(false);
    };

    const isProjectNameInvalid = () => {
        return !editedValue.name;
    };

    const isProjectTypeInvalid = () => {
        return !editedValue.type;
    };

    const isProjectNumberInvalid = () => {
        return !projectNumberValitationRegex.test(`${editedValue.jobNumber}`) && !!editedValue.jobNumber;
    };

    const isProjectDateInvalid = () => {
        return startDate && endDate && endDate?.diff(startDate) <= 0;
    };

    const isProjectValueInvalid = () => {
        return !!editedValue.value && !/[0-9]/gi.test(`${editedValue.value}`);
    };

    const handleSave = useCallback(() => {
        const updatedProjectValue = editedValue;
        updatedProjectValue.startDate = startDate;
        updatedProjectValue.endDate = endDate;
        updatedProjectValue.template = template;

        const serviceTypesSet = new Set(
            serviceTypes
                .map((st) => bim360ProjectServicesTypes.find((t) => st === t.key)?.value)
                .filter((st) => st !== undefined) as string[],
        );

        serviceTypesSet.add('doc_manager');

        setProjects((previousProjects) => {
            previousProjects[projectIndex] = {
                ...previousProjects[projectIndex],
                ...updatedProjectValue,
                serviceTypes: Array.from(serviceTypesSet),
            };
            return previousProjects;
        });
        setIsEditable(false);
    }, [editedValue, endDate, projectIndex, serviceTypes, setProjects, startDate, template]);

    const handleChangeInput = (e: any) => {
        const reg = /^-?\d*(\.\d*)?$/;

        setEditedValue((previousValue) => {
            if (e.target.name === 'value' && !reg.test(e.target.value)) {
                return previousValue;
            }
            return {
                ...previousValue,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleStartDateChange = (value: DatePickerProps['value']) => {
        setStartDate(value as Moment);
    };

    const handleEndDateChange = (value: DatePickerProps['value']) => {
        setEndDate(value as Moment);
    };

    if (platform === 'BIM360') {
        return (
            <tr>
                <td>
                    <ColumnWithErrorMessage>
                        {isEditable ? (
                            <Input
                                name="name"
                                status={isProjectNameInvalid() ? 'error' : undefined}
                                value={editedValue.name}
                                onChange={handleChangeInput}
                            />
                        ) : (
                            projects[projectIndex].name
                        )}
                    </ColumnWithErrorMessage>
                </td>
                <td>
                    <ColumnWithErrorMessage>
                        {isEditable ? (
                            <Select
                                style={{ width: 'fit-content' }}
                                status={isProjectTypeInvalid() ? 'error' : undefined}
                                showSearch
                                defaultValue={projects[projectIndex].type}
                                value={editedValue.type}
                                options={projectImportTypes.map((t) => ({ value: t, label: t }))}
                                onChange={(value) => {
                                    setEditedValue((v) => {
                                        return {
                                            ...v,
                                            type: value,
                                        };
                                    });
                                }}
                            />
                        ) : (
                            projects[projectIndex].type
                        )}
                    </ColumnWithErrorMessage>
                </td>
                <td>
                    <ColumnWithErrorMessage>
                        {isEditable ? (
                            <Input
                                style={{ minWidth: '50px' }}
                                name="value"
                                status={isProjectValueInvalid() ? 'error' : undefined}
                                value={editedValue.value}
                                onChange={handleChangeInput}
                            />
                        ) : (
                            projects[projectIndex].value
                        )}
                    </ColumnWithErrorMessage>
                </td>
                <td>
                    <ColumnWithErrorMessage>
                        {isEditable ? (
                            <Select
                                style={{ width: 'fit-content' }}
                                showSearch
                                defaultValue={projects[projectIndex].currency}
                                value={editedValue.currency}
                                options={currencyTypes.map((c) => ({ value: c, label: c }))}
                                onChange={(value) => {
                                    setEditedValue((v) => {
                                        return {
                                            ...v,
                                            currency: value,
                                        };
                                    });
                                }}
                            />
                        ) : (
                            projects[projectIndex].currency
                        )}
                    </ColumnWithErrorMessage>
                </td>
                <td>
                    <ColumnWithErrorMessage>
                        {isEditable ? (
                            <Input name="jobNumber" value={editedValue.jobNumber} onChange={handleChangeInput} />
                        ) : (
                            projects[projectIndex].jobNumber || '-'
                        )}
                    </ColumnWithErrorMessage>
                </td>
                <td>
                    {isEditable ? (
                        <DatePicker value={startDate} onChange={handleStartDateChange} />
                    ) : (
                        <ColumnWithErrorMessage>
                            {projects[projectIndex].startDate?.format('YYYY-MM-DD')}
                        </ColumnWithErrorMessage>
                    )}
                </td>
                <td>
                    {isEditable ? (
                        <DatePicker value={endDate} onChange={handleEndDateChange} />
                    ) : (
                        <ColumnWithErrorMessage>
                            {projects[projectIndex].endDate?.format('YYYY-MM-DD')}
                        </ColumnWithErrorMessage>
                    )}
                </td>
                <td>
                    <ColumnWithErrorMessage>{projects[projectIndex].language}</ColumnWithErrorMessage>
                </td>
                <td>
                    <ColumnWithErrorMessage>
                        {isEditable ? (
                            <Select
                                mode="multiple"
                                style={{ width: 'fit-content', minWidth: '150px' }}
                                showSearch
                                defaultValue={getServiceTypeTitles(
                                    projects[projectIndex].serviceTypes || ['doc_manager'],
                                )}
                                value={serviceTypes}
                                options={bim360ProjectServicesTypes.map((t) => ({ value: t.key, label: t.key }))}
                                onChange={(serviceTypeKeys: string[]) => {
                                    setServiceTypes(serviceTypeKeys);
                                }}
                            />
                        ) : (
                            `${getServiceTypeTitles(projects[projectIndex].serviceTypes).join(', ')}`
                        )}
                    </ColumnWithErrorMessage>
                </td>
                <td>
                    <ColumnWithErrorMessage>{projects[projectIndex].projectTemplate}</ColumnWithErrorMessage>
                </td>
                <td>
                    <ColumnWithErrorMessage>
                        {isEditable ? (
                            <Select
                                style={{ width: 'fit-content', minWidth: '150px' }}
                                showSearch
                                disabled={templates.length === 0}
                                defaultValue={projects[projectIndex].template?.name}
                                value={template?.name}
                                options={templates.map((t) => ({ value: t.id, label: t.name }))}
                                onChange={(value) => {
                                    setTemplate(templates.find((t) => t.id === value));
                                }}
                            />
                        ) : (
                            projects[projectIndex].template?.name
                        )}
                    </ColumnWithErrorMessage>
                </td>
                <td className={isEditable ? styles.edit : styles.action}>
                    <ColumnWithErrorMessage>
                        {isEditable ? (
                            <div className={styles.iconbuttons}>
                                <Button
                                    style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                                    type="link"
                                    icon={<Icon component={CheckmarkSvg} className="primary-color-hover" />}
                                    disabled={
                                        isProjectDateInvalid() ||
                                        isProjectNumberInvalid() ||
                                        isProjectNameInvalid() ||
                                        isProjectTypeInvalid()
                                    }
                                    onClick={handleSave}
                                />
                                <Button
                                    type="link"
                                    icon={<Icon component={CloseSvg} className="primary-color-hover" />}
                                    onClick={handleCancel}
                                />
                            </div>
                        ) : (
                            <div className={styles.iconbuttons}>
                                {isTemplatesLoading ? (
                                    <Spin size="small" />
                                ) : (
                                    <Button
                                        type="link"
                                        icon={<Icon component={EditSvg} className="primary-color-hover" />}
                                        disabled={false}
                                        onClick={handleIconClick}
                                    />
                                )}
                            </div>
                        )}
                    </ColumnWithErrorMessage>
                </td>
            </tr>
        );
    }

    return (
        <tr>
            <td>
                <ColumnWithErrorMessage>
                    {isEditable ? (
                        <Input
                            name="name"
                            status={isProjectNameInvalid() ? 'error' : undefined}
                            value={editedValue.name}
                            onChange={handleChangeInput}
                        />
                    ) : (
                        projects[projectIndex].name
                    )}
                </ColumnWithErrorMessage>
            </td>
            <td>
                <ColumnWithErrorMessage>
                    {isEditable ? (
                        <Input
                            name="jobNumber"
                            status={isProjectNumberInvalid() ? 'error' : undefined}
                            value={editedValue.jobNumber}
                            onChange={handleChangeInput}
                        />
                    ) : (
                        projects[projectIndex].jobNumber || '-'
                    )}
                </ColumnWithErrorMessage>
            </td>
            <td>
                <ColumnWithErrorMessage>{hub?.name}</ColumnWithErrorMessage>
            </td>
            <td>
                <ColumnWithErrorMessage>
                    {isEditable ? (
                        <Input
                            name="type"
                            status={isProjectTypeInvalid() ? 'error' : undefined}
                            value={editedValue.type}
                            onChange={handleChangeInput}
                        />
                    ) : (
                        projects[projectIndex].type
                    )}
                </ColumnWithErrorMessage>
            </td>
            <td>
                <ColumnWithErrorMessage>
                    {isEditable ? (
                        <Select
                            style={{ width: 'fit-content' }}
                            showSearch
                            disabled={templates.length === 0}
                            defaultValue={projects[projectIndex].template?.name}
                            value={template?.name}
                            options={templates.map((t) => ({ value: t.id, label: t.name }))}
                            onChange={(value) => {
                                setTemplate(templates.find((t) => t.id === value));
                            }}
                        />
                    ) : (
                        projects[projectIndex].template?.name
                    )}
                </ColumnWithErrorMessage>
            </td>
            <td>
                {isEditable ? (
                    <DatePicker value={startDate} onChange={handleStartDateChange} />
                ) : (
                    <ColumnWithErrorMessage>
                        {projects[projectIndex].startDate?.format('YYYY-MM-DD')}
                    </ColumnWithErrorMessage>
                )}
            </td>
            <td>
                {isEditable ? (
                    <DatePicker value={endDate} onChange={handleEndDateChange} />
                ) : (
                    <ColumnWithErrorMessage>
                        {projects[projectIndex].endDate?.format('YYYY-MM-DD')}
                    </ColumnWithErrorMessage>
                )}
            </td>
            <td className={isEditable ? styles.edit : styles.action}>
                <ColumnWithErrorMessage>
                    {isEditable ? (
                        <div className={styles.iconbuttons}>
                            <Button
                                style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                                type="link"
                                icon={<Icon component={CheckmarkSvg} className="primary-color-hover" />}
                                disabled={
                                    isProjectDateInvalid() ||
                                    isProjectNumberInvalid() ||
                                    isProjectNameInvalid() ||
                                    isProjectTypeInvalid()
                                }
                                onClick={handleSave}
                            />
                            <Button
                                type="link"
                                icon={<Icon component={CloseSvg} className="primary-color-hover" />}
                                onClick={handleCancel}
                            />
                        </div>
                    ) : (
                        <div className={styles.iconbuttons}>
                            {isTemplatesLoading ? (
                                <Spin size="small" />
                            ) : (
                                <Button
                                    type="link"
                                    icon={<Icon component={EditSvg} className="primary-color-hover" />}
                                    disabled={false}
                                    onClick={handleIconClick}
                                />
                            )}
                        </div>
                    )}
                </ColumnWithErrorMessage>
            </td>
        </tr>
    );
};

export default EditableCell;
