import * as React from 'react';
import './SelectTableComponent.css';
import { Table } from 'antd';
import { TableProps } from 'antd';
import SelectionComponent from '../../components/SelectionComponent/SelectionComponent';
import { FormattedMessage } from 'react-intl';

const SelectTableComponent: React.FC<Props<any>> = (props: Props<any>) => {
    const { selectedItems, pagination, onUnselectAll, className } = props;

    const renderSelected = (): React.ReactElement | undefined => {
        if (selectedItems.length > 0) {
            return (
                <SelectionComponent
                    label={<FormattedMessage id="selectTable.selected" />}
                    items={selectedItems}
                    onClearAll={onUnselectAll}
                />
            );
        }
    };
    const customPagination = Object.assign({}, pagination, {
        showTotal: renderSelected,
    });

    return <Table {...props} pagination={customPagination} className={`select-table ${className}`} />;
};
export default SelectTableComponent;

interface Props<RecordType> extends TableProps<RecordType> {
    selectedItems: React.ReactNode[] | string[];
    onUnselectAll: () => void;
}
