import React from 'react';
import { Table } from 'antd';
import tableService from '../../../../services/TableService';

import TaskProjectActionComponent from './TaskProjectActionComponent';
import { useTaskProjectContext } from './TaskProjectContext';
import { TaskProjectAction } from '../../../../model/entities';
import { FormattedDate, FormattedMessage } from 'react-intl';
import TaskStatusComponent from '../../../../components/TaskStatusComponent/TaskStatusComponent';
import { ColumnsType } from 'antd/lib/table';

function TaskProjectTable() {
    const { isLoading, paginate, taskProjectActionsPage, task } = useTaskProjectContext();
    const items = taskProjectActionsPage ? taskProjectActionsPage.content : [];

    const columns: ColumnsType<TaskProjectAction> = [
        {
            title: <FormattedMessage id="taskProjectAction.name" />,
            dataIndex: 'name',
            key: 'name',
            width: 250,
            sorter: true,
            render: (value: string, taskProjectAction: TaskProjectAction) => value,
        },
        {
            title: <FormattedMessage id="taskProjectAction.jobNumber" />,
            dataIndex: 'jobNumber',
            key: 'jobNumber',
            width: 250,
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string, taskProjectAction: TaskProjectAction) => value,
        },
        {
            title: <FormattedMessage id="taskProjectAction.platform" />,
            dataIndex: 'platform',
            key: 'platform',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string, taskProjectAction: TaskProjectAction) => value,
        },
        {
            title: <FormattedMessage id="taskProjectAction.projectType" />,
            dataIndex: 'projectType',
            key: 'projectType',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string, taskProjectAction: TaskProjectAction) => value,
        },
        {
            title: <FormattedMessage id="taskProjectAction.action" />,
            dataIndex: 'action',
            key: 'action',
            width: 250,
            render: (action: any, taskProjectAction: TaskProjectAction) => (
                <TaskProjectActionComponent task={task!} taskProjectAction={taskProjectAction} />
            ),
        },
        {
            title: <FormattedMessage id="taskProjectAction.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'created',
            sorter: true,
            width: 240,
            align: 'center',
            render: (value: string, taskProjectAction: TaskProjectAction) => (
                <FormattedDate
                    value={taskProjectAction.audit!.created as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskProjectAction.status" />,
            dataIndex: task?.hub ? 'projectStatus' : 'status',
            key: task?.hub ? 'projectStatus' : 'status',
            sorter: true,
            width: 200,
            align: 'center',
            render: (value: string, taskProjectAction: TaskProjectAction) => (
                <TaskStatusComponent
                    status={task?.hub ? taskProjectAction.projectStatus : taskProjectAction.status}
                    error={taskProjectAction.action?.status?.error}
                />
            ),
        },
    ];

    return (
        <Table
            dataSource={items}
            columns={columns}
            pagination={tableService.createPagination(taskProjectActionsPage, { position: ['bottomRight'] })}
            rowKey="id"
            onChange={paginate}
            sortDirections={['ascend', 'descend']}
            showSorterTooltip={false}
            loading={isLoading}
        />
    );
}

export default TaskProjectTable;
