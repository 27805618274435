import Icon, { ExportOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import connectionApi from '../../../../../api/ConnectionApi';
import CustomContext from '../../../../../context/CustomContext';
import { Connection, ConnectionClone, CustomAuth } from '../../../../../model/entities';
import { ReactComponent as CheckmarkSvg } from '../../../../../resources/images/checkmark.svg';
import notificationService from '../../../../../services/NotificationService';
import styles from './ConnectionCloneModal.module.scss';

/**
 * This component represents a clone connection modal.
 * @param props - the props
 */
export const ConnectionCloneModal: React.FC<Props> = (props) => {
    const { connectionId } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [connection, setConnection] = useState<Connection>();
    const [loading, setLoading] = useState<'loading' | 'cloning'>();

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                const connection = await connectionApi.get(connectionId);
                form.setFieldsValue({
                    name: `${connection.name} ${intl.formatMessage({ id: 'connectionClone.name.suffix' })}`,
                    includeTargets: false,
                });
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth.id, connectionId, form, intl]);

    /*** METHODS ***/

    const clone = async () => {
        try {
            setLoading('cloning');
            const values = await form.validateFields();
            let connectionClone: ConnectionClone = Object.assign({}, { connectionId }, values);
            const connection = await connectionApi.clone(connectionClone);
            setConnection(connection);
        } catch (error) {
            notificationService.displayError(error, intl, [
                { status: 409, message: 'connection.status.duplicate' },
                { status: 403, message: 'connectionClone.status.forbiddenError' },
            ]);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    /*** VISUAL ***/

    return (
        <Modal
            title={<FormattedMessage id="connectionClone.modal.title" />}
            visible={true}
            width={800}
            onCancel={cancel}
            onOk={connection ? props.onSave : clone}
            okText={
                connection ? (
                    <FormattedMessage id="button.ok" tagName="span" />
                ) : (
                    <FormattedMessage id="button.clone" tagName="span" />
                )
            }
            okButtonProps={{ loading: loading === 'cloning' }}
            cancelButtonProps={{ disabled: loading === 'cloning', hidden: !!connection }}
        >
            {!connection && (
                <Form form={form} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="connectionClone.name" />}
                        name="name"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input size="large" maxLength={100} />
                    </Form.Item>

                    <Form.Item
                        name="includeTargets"
                        valuePropName="checked"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Checkbox>
                            <FormattedMessage id="connectionClone.includeTargets" />
                        </Checkbox>
                    </Form.Item>
                </Form>
            )}
            {connection && <SuccessPanel connection={connection} />}
        </Modal>
    );
};
export default ConnectionCloneModal;

interface Props {
    connectionId: number;
    onSave: () => void;
    onCancel: () => void;
}

const SuccessPanel: React.FC<SuccessPanelProps> = (props) => {
    const { connection } = props;

    return (
        <>
            <p>
                <FormattedMessage id="connectionClone.success" />
            </p>
            <p className={styles.connection}>
                <Icon component={CheckmarkSvg} className={styles.icon} />
                <span>{connection.name}</span>
                <a href={`/sync/connections/${connection.id}`}>
                    <Button icon={<ExportOutlined />} className={styles.navigate}></Button>
                </a>
            </p>
        </>
    );
};

interface SuccessPanelProps {
    connection: Connection;
}
