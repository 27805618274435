import Icon from '@ant-design/icons';
import { Button, Descriptions, Space, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import taskSyncApi from '../../../../../api/TaskSyncApi';
import taskSyncFileApi from '../../../../../api/TaskSyncFileApi';
import taskSyncFileTargetApi from '../../../../../api/TaskSyncFileTargetApi';
import taskSyncLinkApi from '../../../../../api/TaskSyncLinkApi';
import LayoutComponent from '../../../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../../../components/TaskStatusComponent/TaskStatusComponent';
import { Page } from '../../../../../model/elements';
import { TaskSync, TaskSyncFile, TaskSyncFileTarget, TaskSyncLink } from '../../../../../model/entities';
import { TaskStatusType } from '../../../../../model/types';
import { ReactComponent as BackSvg } from '../../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../../resources/images/refresh.svg';
import notificationService from '../../../../../services/NotificationService';
import tableService from '../../../../../services/TableService';
import styles from './TaskSyncFilePage.module.scss';

/**
 * Returns the task sync file page.
 * @returns the task sync file page.
 */
const TaskSyncFilePage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [task, setTask] = useState<TaskSync>();
    const [taskLink, setTaskLink] = useState<TaskSyncLink>();
    const [taskSyncFile, setTaskSyncFile] = useState<TaskSyncFile>();
    const [taskSyncFileTargetsPage, setTaskSyncFileTargetsPage] = useState<Page<TaskSyncFileTarget>>();
    const [loading, setLoading] = useState<'loading' | string>();
    const [searchText, setSearchText] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const id = +params.id;
                const page = taskSyncFileTargetsPage?.number || 0;
                const size = taskSyncFileTargetsPage?.size || tableService.pageSize;
                const sortField = taskSyncFileTargetsPage?.sort.field || 'targetProjectName';
                const sortOrder = taskSyncFileTargetsPage?.sort.order || true;
                const responses = await Promise.all([
                    taskSyncFileApi.get(id),
                    listTaskFiles(page, size, sortField, sortOrder, id, searchText),
                ]);

                const taskSyncFile = responses[0];
                const taskLink = await taskSyncLinkApi.get(taskSyncFile.taskLinkId);
                const task = await taskSyncApi.get(taskLink.taskId!);

                setTask(task);
                setTaskLink(taskLink);
                setTaskSyncFile(taskSyncFile);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [
        intl,
        params.id,
        searchText,
        taskSyncFileTargetsPage?.number,
        taskSyncFileTargetsPage?.size,
        taskSyncFileTargetsPage?.sort.field,
        taskSyncFileTargetsPage?.sort.order,
    ]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading('loading');
            const id = +params.id;
            const page = taskSyncFileTargetsPage?.number || 0;
            const size = taskSyncFileTargetsPage?.size || tableService.pageSize;
            const sortField = taskSyncFileTargetsPage?.sort.field || 'targetProjectName';
            const sortOrder = taskSyncFileTargetsPage?.sort.order || true;
            const responses = await Promise.all([
                taskSyncFileApi.get(id),
                listTaskFiles(page, size, sortField, sortOrder, id, searchText),
            ]);

            const taskSyncFile = responses[0];
            const taskLink = await taskSyncLinkApi.get(taskSyncFile.taskLinkId);
            const task = await taskSyncApi.get(taskLink.taskId!);

            setTask(task);
            setTaskLink(taskLink);
            setTaskSyncFile(taskSyncFile);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading('loading');
            const page = pagination.current! - 1;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            await listTaskFiles(page, pageSize, sortField, sortOrder, taskSyncFile!.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading('loading');
            setSearchText(searchText);
            const page = 0;
            const pageSize = taskSyncFileTargetsPage!.size;
            const sortField = taskSyncFileTargetsPage!.sort.field!;
            const sortOrder = taskSyncFileTargetsPage!.sort.order!;
            await listTaskFiles(page, pageSize, sortField, sortOrder, taskSyncFile!.id!, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const listTaskFiles = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskLinkId: number,
        searchText?: string,
    ) => {
        const taskSyncFileTargetsPage = await taskSyncFileTargetApi.list(
            page,
            size,
            sortField,
            sortOrder,
            taskLinkId,
            searchText,
        );
        setTaskSyncFileTargetsPage(taskSyncFileTargetsPage);
    };

    const retry = async (taskSyncFileTarget: TaskSyncFileTarget) => {
        try {
            setLoading(`retrying-${taskSyncFileTarget.id}`);

            await taskSyncFileTargetApi.retry(taskSyncFileTarget.id);
            await list();
        } catch (error) {
            notificationService.displayError(error, intl, [{ status: 400, message: 'task.retry.status.notAllowed' }]);
        } finally {
            setLoading(undefined);
        }
    };

    const isRetryEnabled = (taskSyncFileTarget: TaskSyncFileTarget): boolean => {
        return (
            !!taskSyncFile &&
            !!taskSyncFile.status &&
            ['SUCCESSFUL', 'ERROR', 'FAILED'].includes(taskSyncFile.status) &&
            ['ERROR', 'FAILED'].includes(taskSyncFileTarget.status)
        );
    };

    /*** COMPONENTS ***/

    const placeholder: string = intl.formatMessage({ id: 'taskLinkFileTargets.search' });

    const items = taskSyncFileTargetsPage ? taskSyncFileTargetsPage.content : [];
    const columns: ColumnsType<TaskSyncFileTarget> = [
        {
            title: <FormattedMessage id="taskSyncFileTarget.target.project" />,
            dataIndex: 'targetProjectName',
            key: 'targetProjectName',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string, taskSyncFileTarget: TaskSyncFileTarget) => taskSyncFileTarget.target?.project.name,
        },
        {
            title: <FormattedMessage id="taskSyncFileTarget.target.folder" />,
            dataIndex: 'targetFolderName',
            key: 'targetFolderName',
            sorter: true,
            render: (value: string, taskSyncFileTarget: TaskSyncFileTarget) => taskSyncFileTarget.target?.folder.name,
        },
        {
            title: <FormattedMessage id="taskSyncFileTarget.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'auditCreated',
            sorter: true,
            width: 240,
            align: 'center',
            render: (value: string, taskSyncFileTarget: TaskSyncFileTarget) => (
                <FormattedDate
                    value={taskSyncFileTarget.audit.created as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskSyncFileTarget.audit.updated" />,
            dataIndex: 'auditUpdated',
            key: 'auditUpdated',
            sorter: true,
            width: 240,
            align: 'center',
            render: (value: string, taskSyncFileTarget: TaskSyncFileTarget) => (
                <FormattedDate
                    value={taskSyncFileTarget.audit.updated as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskSyncFileTarget.status" />,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 200,
            align: 'center',
            render: (status: TaskStatusType, taskSyncFileTarget: TaskSyncFileTarget) => (
                <Space>
                    <TaskStatusComponent key="status" status={status} error={taskSyncFileTarget.error} />
                    <Tooltip key="retry" title={<FormattedMessage id="button.retry" />}>
                        <Button
                            type="ghost"
                            size="small"
                            icon={<Icon component={RefreshSvg} />}
                            onClick={() => retry(taskSyncFileTarget)}
                            hidden={!isRetryEnabled(taskSyncFileTarget)}
                            loading={loading === `retrying-${taskSyncFileTarget.id}`}
                            disabled={!!loading}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <LayoutComponent pageId="tasks">
            <Descriptions
                title={
                    <>
                        <span className={styles.title}>
                            #{task?.id} - {task?.name} - {taskSyncFile?.name}
                        </span>
                        <TaskStatusComponent status={taskSyncFile?.status} />
                        <Space>
                            <Tooltip title={<FormattedMessage id="button.back" />}>
                                <Link to={`/task-sync-links/${taskLink?.id}`}>
                                    <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.refresh" />}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    icon={<Icon component={RefreshSvg} />}
                                    onClick={list}
                                />
                            </Tooltip>
                        </Space>
                    </>
                }
                column={2}
                size="small"
                className={styles.summary}
            >
                <Descriptions.Item label={<FormattedMessage id="task.source" />}>
                    {task?.source?.provider} &gt; {task?.source?.name} &gt; {taskLink?.source?.project?.name} &gt;{' '}
                    {taskLink?.source?.folder?.name}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.target" />}>
                    {task?.target?.provider} &gt; {task?.target?.name}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                    <FormattedDate
                        value={taskSyncFile?.audit?.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                    <FormattedDate
                        value={taskSyncFile?.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
            </Descriptions>
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(taskSyncFileTargetsPage, { position: ['bottomRight'] })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
            />
        </LayoutComponent>
    );
};

export default TaskSyncFilePage;

type ParamsType = { id: string };
