import { Role } from '../model/entities';
import { proxyApiAxios } from './CustomAxios';

class RoleApi {
    list = async (hubId: string, projectId: string): Promise<Role[]> => {
        const response = await proxyApiAxios.get<Role[]>('/industry-roles', {
            params: {
                hubId,
                projectId,
            },
        });
        return response.data;
    };
}

const roleApi: RoleApi = new RoleApi();
export default roleApi;
