import React, { useContext } from 'react';
import styles from './HomePage.module.scss';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import configuration from '../../configs/IConfiguration';
import CustomContext from '../../context/CustomContext';
import cellnexTasksImage from '../../resources/images/cellnex/cellnex-tasks-home.svg';
import cellnexSetupImage from '../../resources/images/cellnex/cellnex-setup-home.svg';
import cellnexSyncImage from '../../resources/images/cellnex/cellnex-sync-home.svg';
import modelicalTasksImage from '../../resources/images/modelical-tasks-home.svg';
import modelicalSetupImage from '../../resources/images/modelical-setup-home.svg';
import modelicalSyncImage from '../../resources/images/modelical-sync-home.svg';
import rolesService from '../../services/RolesService';

/**
 * Returns the home page.
 * @returns the home page.
 */
const HomePage = (): React.ReactElement => {
    /*** HOOKS ***/

    const context = useContext(CustomContext);
    const { auth } = context;

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <LayoutComponent pageId="home" style={{ background: '#f2f2f2' }}>
            <div className={styles.homepagelayout}>
                <div className={styles.boxes}>
                    <div className={styles.box}>
                        <Link to={rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_USER']) ? '/setup' : '#'}>
                            <div
                                className={`${styles.application} ${
                                    rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_USER'])
                                        ? styles.enabled
                                        : styles.disabled
                                }`}
                            >
                                <CustomImageComponent
                                    cellnex={cellnexSetupImage}
                                    predefined={modelicalSetupImage}
                                    alt={'setup'}
                                />
                                <div className={styles.textbox}>
                                    <p className={styles.title}>
                                        <FormattedMessage id="home.setup" />
                                    </p>
                                    <p className={styles.description}>
                                        <FormattedMessage id="home.setup.description" />
                                    </p>
                                </div>
                                <div className={styles.chips}>
                                    <span className={styles.chip}>Users</span>
                                    <span className={styles.chip}>Issues</span>
                                    <span className={styles.chip}>Projects</span>
                                    <span className={styles.chip}>Directory</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className={styles.box}>
                        <Link to={rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_USER']) ? '/sync' : '#'}>
                            <div
                                className={`${styles.application} ${
                                    rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_USER'])
                                        ? styles.enabled
                                        : styles.disabled
                                }`}
                            >
                                <CustomImageComponent
                                    cellnex={cellnexSyncImage}
                                    predefined={modelicalSyncImage}
                                    alt={'sync'}
                                />
                                <div className={styles.textbox}>
                                    <p className={styles.title}>
                                        <FormattedMessage id="home.sync" />
                                    </p>
                                    <p className={styles.description}>
                                        <FormattedMessage id="home.sync.description" />
                                    </p>
                                </div>
                                <div className={styles.chips}>
                                    <span className={styles.chip}>Connections</span>
                                    <span className={styles.chip}>Files attributes</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className={styles.box}>
                        <Link to={rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_USER']) ? '/tasks' : '#'}>
                            <div
                                className={`${styles.application} ${
                                    rolesService.hasAnyRole(auth, ['ROLE_ADMIN', 'ROLE_USER'])
                                        ? styles.enabled
                                        : styles.disabled
                                }`}
                            >
                                <CustomImageComponent
                                    cellnex={cellnexTasksImage}
                                    predefined={modelicalTasksImage}
                                    alt={'tasks'}
                                />
                                <div className={styles.textbox}>
                                    <p className={styles.title}>
                                        <FormattedMessage id="home.tasks" />
                                    </p>
                                    <p className={styles.description}>
                                        <FormattedMessage id="home.tasks.description" />
                                    </p>
                                </div>
                                <div className={styles.chips}>
                                    <span className={styles.chip}>Tasks</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </LayoutComponent>
    );
};

export default HomePage;

/**
 * Returns the custom image component.
 * @returns the custom image component
 */
const CustomImageComponent = (props: CustomImageProps): React.ReactElement => {
    const { cellnex, predefined, alt } = props;
    /*** HOOKS ***/

    /*** COMPONENTS ***/

    if (configuration.getTenant() === 'cellnex') {
        return <img src={cellnex} alt={alt} className={styles.image} />;
    } else {
        return <img src={predefined} alt={alt} className={styles.image} />;
    }
};

interface CustomImageProps {
    cellnex: any;
    predefined: any;
    alt: string;
}
