import Icon from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import countries from 'i18n-iso-countries';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import projectSubmissionApi from '../../../api/ProjectSubmissionApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import { Page } from '../../../model/elements';
import { ProjectSubmission } from '../../../model/entities';
import { ProjectSubmissionState } from '../../../model/types';
import { ReactComponent as AddSvg } from '../../../resources/images/add.svg';
import notificationService from '../../../services/NotificationService';
import tableService from '../../../services/TableService';
import ProjectSubmissionStateComponent from './ProjectSubmissionStateComponent';

/**
 * Returns the project submissions page.
 * @returns the project submissions page.
 */
const ProjectSubmissionsPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [projectSubmissionPage, setProjectSubmissionsPage] = useState<Page<ProjectSubmission>>();
    const [loading, setLoading] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);

                const projectSubmissionPage = await projectSubmissionApi.list(
                    0,
                    tableService.pageSize,
                    'audit.updated',
                    false,
                );

                setProjectSubmissionsPage(projectSubmissionPage);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    const list = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current!;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            const projectSubmissionPage = await projectSubmissionApi.list(
                page - 1,
                pageSize,
                sortField,
                sortOrder,
                searchText,
            );
            setProjectSubmissionsPage(projectSubmissionPage);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);

            const pageSize = projectSubmissionPage!.size;
            const sortField = projectSubmissionPage!.sort.field!;
            const sortOrder = projectSubmissionPage!.sort.order!;
            const projectSubmissionPageNew = await projectSubmissionApi.list(
                0,
                pageSize,
                sortField,
                sortOrder,
                searchText,
            );

            setProjectSubmissionsPage(projectSubmissionPageNew);
            setSearchText(searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    /*** COMPONENTS ***/

    const items = projectSubmissionPage ? projectSubmissionPage.content : [];
    const columns: ColumnsType<ProjectSubmission> = [
        {
            title: <FormattedMessage id="projectSubmission.projectCode" />,
            dataIndex: 'projectCode',
            key: 'projectCode',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 180,
            render: (value: string, projectSubmission: ProjectSubmission) => (
                <Link to={`/setup/project-submissions/${projectSubmission.id}`}>{value}</Link>
            ),
        },
        {
            title: <FormattedMessage id="projectSubmission.projectCity" />,
            dataIndex: 'projectCity',
            key: 'projectCity',
            sorter: true,
            width: 180,
            render: (value: string, projectSubmission: ProjectSubmission) => (
                <Link to={`/setup/project-submissions/${projectSubmission.id}`}>{value}</Link>
            ),
        },
        {
            title: <FormattedMessage id="projectSubmission.projectCountry" />,
            dataIndex: 'projectCountry',
            key: 'projectCountry',
            sorter: true,
            width: 180,
            render: (value: string, projectSubmission: ProjectSubmission) => (
                <Link to={`/setup/project-submissions/${projectSubmission.id}`}>{countries.getName(value, 'en')}</Link>
            ),
        },
        {
            title: <FormattedMessage id="projectSubmission.projectType" />,
            dataIndex: 'projectType',
            key: 'projectType',
            sorter: true,
            width: 180,
            render: (value: string, projectSubmission: ProjectSubmission) => (
                <Link to={`/setup/project-submissions/${projectSubmission.id}`}>
                    {value && <FormattedMessage id={value} />}
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="projectSubmission.audit.creator" />,
            dataIndex: 'audit.creator.firstName',
            key: 'creator',
            sorter: true,
            width: 200,
            render: (value: string, projectSubmission: ProjectSubmission) => (
                <Link to={`/setup/project-submissions/${projectSubmission.id}`}>
                    {projectSubmission.audit?.creator.firstName} {projectSubmission.audit?.creator.lastName}
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="projectSubmission.audit.updated" />,
            dataIndex: 'audit.updated',
            key: 'updated',
            sorter: true,
            defaultSortOrder: 'descend',
            width: 150,
            align: 'center',
            render: (value: string, projectSubmission: ProjectSubmission) => (
                <Link to={`/setup/project-submissions/${projectSubmission.id}`}>
                    <FormattedDate
                        value={projectSubmission.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                    />
                </Link>
            ),
        },
        {
            title: <FormattedMessage id="projectSubmission.state" />,
            dataIndex: 'state',
            key: 'state',
            sorter: true,
            width: 180,
            align: 'center',
            render: (value: ProjectSubmissionState, projectSubmission: ProjectSubmission) => (
                <Link to={`/setup/project-submissions/${projectSubmission.id}`}>
                    <ProjectSubmissionStateComponent state={value} />
                </Link>
            ),
        },
    ];

    const placeholder: string = intl.formatMessage({ id: 'projectSubmissions.search' });

    return (
        <LayoutComponent pageId="projectSubmissions">
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
                <Link to="/setup/project-submissions/new">
                    <Tooltip title={<FormattedMessage id="projectSubmissions.new.tooltip" />}>
                        <Button type="primary" size="large" icon={<Icon component={AddSvg} />}>
                            <FormattedMessage id="projectSubmissions.new" tagName="span" />
                        </Button>
                    </Tooltip>
                </Link>
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(projectSubmissionPage)}
                rowKey="id"
                onChange={list}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </LayoutComponent>
    );
};

export default ProjectSubmissionsPage;
