import Icon from '@ant-design/icons';
import { Button, Col, Form, List, Row, Select, Space, Upload } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { UploadFile } from 'antd/lib/upload/interface';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import hubApi from '../../../../../api/HubApi';
import memberApi from '../../../../../api/MemberApi';
import FileSizeComponent from '../../../../../components/FileSizeComponent/FileSizeComponent';
import { Hub, Member, MemberConfiguration } from '../../../../../model/entities';
import { ReactComponent as AttachmentSvg } from '../../../../../resources/images/attachment.svg';
import { ReactComponent as CloudUpload } from '../../../../../resources/images/cloud-upload.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../resources/images/trash-can.svg';
import notificationService from '../../../../../services/NotificationService';
import styles from './UploadComponent.module.scss';
import CustomContext from '../../../../../context/CustomContext';

/**
 * Returns the members page import upload component.
 * @props the props
 * @returns the members page import upload component.
 */
const UploadComponent = (props: Props): React.ReactElement => {
    const { changeHubId, hubId, onLoadMembers } = props;
    const maxFileSize = 20 * 1024 * 1024;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const { auth } = useContext(CustomContext);
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<boolean>();
    const [hubs, setHubs] = useState<Hub[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);

                let hubs = await hubApi.list();
                hubs = hubs.filter((h) => auth && auth.members.some((m) => m.hubId === h.id && m.bim360 === 'ADMIN'));
                form.setFieldsValue({ hubId: hubId });

                setHubs(hubs);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [auth, form, hubId, intl]);

    /*** METHODS ***/

    const load = async (values: any) => {
        try {
            setLoading(true);
            let members: Member[];
            if (files.length > 0) {
                const configuration: MemberConfiguration = { hubId: values.hubId };
                const file: any = files[0];
                members = await memberApi.loadSpreadsheet(configuration, file);
            } else {
                const hubId: string = values.hubId;
                const csv: string = values.text;
                members = await memberApi.loadCsv(hubId, csv);
            }
            changeHubId(values.hubId);
            onLoadMembers(members);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    const validateFile = (
        rule: RuleObject,
        value: StoreValue,
        callback: (error?: string) => void,
    ): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files,
        });
        setFiles(files);
    };

    /*** COMPONENTS ***/

    const hubOptions = hubs.map((hub) => (
        <Select.Option key={hub.id} value={hub.id}>
            {`${hub.provider} > ${hub.name}`}
        </Select.Option>
    ));

    return (
        <Form form={form} onFinish={load} colon={false} layout="vertical">
            <Form.Item label={<FormattedMessage id="projectMembers.hub" />} name="hubId" className={styles.selecthub}>
                <Select
                    size="large"
                    className={styles.hubs}
                    loading={loading}
                    placeholder={<FormattedMessage id="projectMembers.hub.placeholder" />}
                >
                    {hubOptions}
                </Select>
            </Form.Item>
            <Form.Item label={<FormattedMessage id="members.import.steps.1.text" />} name="text">
                <TextArea
                    maxLength={10000}
                    size="large"
                    rows={8}
                    placeholder={intl.formatMessage({ id: 'members.import.steps.1.text.placeholder' })}
                    disabled={files.length > 0}
                />
            </Form.Item>
            <Row>
                <Col span={8}>
                    <Form.Item
                        name="file"
                        valuePropName="files"
                        label={
                            <>
                                <span>
                                    <FormattedMessage id="members.import.steps.1.upload" />
                                </span>
                                <a
                                    href={`${process.env.PUBLIC_URL}/members-import-template.xlsx`}
                                    download={intl.formatMessage({ id: 'members.import.steps.1.upload.file' })}
                                    className={`${styles.link} primary-color`}
                                >
                                    <FormattedMessage id="members.import.steps.1.upload.link" />
                                </a>
                            </>
                        }
                        rules={[
                            {
                                validator: validateFile,
                            },
                        ]}
                        extra={
                            <>
                                <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                            </>
                        }
                    >
                        <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                            <Icon component={CloudUpload} />{' '}
                            <FormattedMessage id="members.import.steps.1.upload.button" />
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <List
                        className={styles.files}
                        itemLayout="horizontal"
                        dataSource={files}
                        locale={{ emptyText: <></> }}
                        renderItem={(file) => (
                            <List.Item
                                actions={[
                                    <Button
                                        icon={<Icon component={TrashCanSvg} />}
                                        danger
                                        size="small"
                                        onClick={removeFile}
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={<Icon component={AttachmentSvg} />}
                                    title={file.name}
                                    description={<FileSizeComponent value={file.size} />}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>

            <Form.Item className="buttons">
                <Space>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        icon={<Icon component={CloudUpload} />}
                        loading={loading}
                    >
                        <FormattedMessage id="button.load" tagName="span" />
                    </Button>
                    <Link to="/setup/members">
                        <Button type="text" size="large">
                            <FormattedMessage id="button.back" tagName="span" />
                        </Button>
                    </Link>
                </Space>
            </Form.Item>
        </Form>
    );
};

export default UploadComponent;

interface Props {
    onLoadMembers: (members: Member[]) => void;
    hubId: string;
    changeHubId: (hubId: string) => void;
}
