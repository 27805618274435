import fileApi from '../api/FileApi';
import folderApi from '../api/FolderApi';
import projectApi from '../api/ProjectApi';
import { CustomDataNode, FolderDataNode, HubDataNode, ProjectDataNode, FileDataNode } from '../model/elements';
import { Folder, Hub, Project, File as ProjectFile } from '../model/entities';

class TreeFolderService {
    createHubNode = (hub: Hub): HubDataNode => {
        const hubNode: HubDataNode = {
            key: hub.id!,
            title: hub.name,
            isLeaf: false,
            hub: hub,
            path: hub.name,
            value: hub.id!,
        };

        return hubNode;
    };

    createProjectNode = (project: Project, parent?: HubDataNode): ProjectDataNode => {
        const projectNode: ProjectDataNode = {
            key: project.id!,
            value: project.id!,
            title: project.name,
            isLeaf: false,
            project: project,
            hub: parent?.hub,
            path: parent ? `${parent.path}/${project.name}` : project.name!,
        };

        return projectNode;
    };

    createFolderNode = (folder: Folder, parent?: CustomDataNode): FolderDataNode => {
        const folderNode: FolderDataNode = {
            folder: Object.assign({}, folder),
            key: folder.id!,
            value: folder.id!,
            title: folder.name,
            isLeaf: false,
            path: parent ? `${parent.path}/${folder.name}` : folder.name!,
        };

        return folderNode;
    };

    createFileNode = (file: ProjectFile, parent?: CustomDataNode): FileDataNode => {
        const fileNode: FileDataNode = {
            file: Object.assign({}, file),
            key: file.id!,
            value: file.id!,
            title: file.name,
            isLeaf: true,
            path: parent ? `${parent.path}/${file.name}` : file.name!,
        };
        return fileNode;
    };

    loadSubnodes = async (key: string | number, nodes: CustomDataNode[], isFileIncluded: boolean = false) => {
        const node = this.find(key, nodes);
        if (node && !node.children) {
            if ('folder' in node) {
                const folderNode = node as FolderDataNode;
                const subfolders = await folderApi.list(
                    folderNode.folder.provider!,
                    folderNode.folder.hubId!,
                    folderNode.folder.projectId!,
                    folderNode.folder.id,
                );
                const subFiles = isFileIncluded
                    ? await fileApi.list(
                          folderNode.folder.provider!,
                          folderNode.folder.projectId!,
                          folderNode.folder.id!,
                      )
                    : [];
                if (
                    (subfolders.length === 0 && !isFileIncluded) ||
                    (isFileIncluded && subFiles.length === 0 && subfolders.length === 0)
                ) {
                    folderNode.isLeaf = true;
                } else {
                    folderNode.children = isFileIncluded
                        ? [
                              ...subfolders.map((f) => this.createFolderNode(f, folderNode)),
                              ...subFiles.map((f) => this.createFileNode(f, folderNode)),
                          ]
                        : subfolders.map((f) => this.createFolderNode(f, folderNode));
                }
            } else if ('project' in node) {
                const projectNode = node as ProjectDataNode;
                const folders = await folderApi.list(
                    projectNode.hub!.provider,
                    projectNode.hub!.id,
                    projectNode.project.id,
                );
                if (folders.length === 0) {
                    projectNode.isLeaf = true;
                } else {
                    projectNode.children = folders.map((f) => this.createFolderNode(f, projectNode));
                }
            } else if ('hub' in node) {
                const hubNode = node as HubDataNode;
                const projects = await projectApi.list(hubNode.hub.provider, hubNode.hub.id);
                const projectNodes = projects.map((p) => this.createProjectNode(p, hubNode));
                projectNodes.forEach((n) => (n.checkable = false));
                hubNode.children = projectNodes;
            } else if ('file' in node) {
                const fileNode = node as FileDataNode;
                fileNode.isLeaf = true;
            }
        }

        return node;
    };

    find = (key: string | number, nodes: CustomDataNode[]): CustomDataNode | undefined => {
        let node: CustomDataNode | undefined;

        for (const currentNode of nodes) {
            if (key === currentNode.key) {
                node = currentNode;
                break;
            } else if (currentNode.children) {
                node = this.find(key, currentNode.children as FolderDataNode[]);
                if (node) {
                    break;
                }
            }
        }

        return node;
    };
}

const treeFolderService: TreeFolderService = new TreeFolderService();
export default treeFolderService;
