import Icon from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, List, Popconfirm, Row, Select, Space, Upload, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import FileSaver from 'file-saver';
import countries from 'i18n-iso-countries';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useParams } from 'react-router-dom';
import projectSubmissionApi from '../../../../api/ProjectSubmissionApi';
import FileSizeComponent from '../../../../components/FileSizeComponent/FileSizeComponent';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import WrapperComponent from '../../../../components/WrapperComponent/WrapperComponent';
import { ProjectSubmission } from '../../../../model/entities';
import { ProjectSubmissionState, projectSubmissionDesignTemplateTypes, projectTypes } from '../../../../model/types';
import { ReactComponent as AttachmentSvg } from '../../../../resources/images/attachment.svg';
import { ReactComponent as CheckmarkSvg } from '../../../../resources/images/checkmark.svg';
import { ReactComponent as CloudUploadSvg } from '../../../../resources/images/cloud-upload.svg';
import { ReactComponent as EditSvg } from '../../../../resources/images/edit.svg';
import { ReactComponent as SaveSvg } from '../../../../resources/images/save.svg';
import { ReactComponent as TrashCanSvg } from '../../../../resources/images/trash-can.svg';
import notificationService from '../../../../services/NotificationService';
import stringService from '../../../../services/StringService';
import ProjectSubmissionStateComponent from '../ProjectSubmissionStateComponent';
import styles from './ProjectSubmissionPage.module.scss';
import CustomContext from '../../../../context/CustomContext';

/**
 * Returns the project submission page.
 * @returns the project submission page.
 */
const ProjectSubmissionPage = (): React.ReactElement => {
    const maxFileSize = 5 * 1024 * 1024;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const history = useHistory();
    const params = useParams<ParamsType>();
    const { auth } = useContext(CustomContext);
    const organizationId = auth!.organization!.id;
    const [projectSubmission, setProjectSubmission] = useState<ProjectSubmission>();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [readonly, setReadonly] = useState<boolean>(true);
    const [saving, setSaving] = useState<'saving' | 'deleting' | ProjectSubmissionState>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                if (params.id === 'new') {
                    const projectSubmission: ProjectSubmission = {
                        organizationId,
                        state: 'DRAFT',
                        contactsFile: {},
                    };
                    const readonly = isReadonly(projectSubmission);
                    setProjectSubmission(projectSubmission);
                    setReadonly(readonly);
                    form.setFieldsValue(projectSubmission);
                } else {
                    const projectSubmission = await projectSubmissionApi.get(+params.id);
                    const files: any[] = [
                        {
                            name: projectSubmission.contactsFile!.name,
                        },
                    ];
                    const readonly = isReadonly(projectSubmission);
                    setProjectSubmission(projectSubmission);
                    setFiles(files);
                    setReadonly(readonly);
                    form.setFieldsValue(projectSubmission);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [form, intl, organizationId, params.id]);

    /*** METHODS ***/

    const isReadonly = (projectSubmission?: ProjectSubmission): boolean => {
        let readonly = true;
        if (projectSubmission && !projectSubmission.id) {
            readonly = false;
        } else if (projectSubmission && projectSubmission.state === 'DRAFT') {
            readonly = false;
        }

        return readonly;
    };

    const saveWithState = async (state: ProjectSubmissionState) => {
        try {
            const values = form.validateFields();
            await save(values, state);
        } catch (error) {
            notificationService.displayError(error, intl);
        }
    };

    const save = async (values: any, state?: ProjectSubmissionState) => {
        try {
            setSaving(state || 'saving');
            const file: any = files[0] instanceof File ? files[0] : undefined;
            let projectSubmissionUpdated: ProjectSubmission = Object.assign({}, projectSubmission, values, {
                state: state || 'DRAFT',
            });
            if (file && projectSubmissionUpdated.contactsFile) {
                projectSubmissionUpdated.contactsFile.name = file.name;
            }
            projectSubmissionUpdated = projectSubmissionUpdated.id
                ? await projectSubmissionApi.update(projectSubmissionUpdated, file)
                : await projectSubmissionApi.create(projectSubmissionUpdated, file);
            const readonly = isReadonly(projectSubmissionUpdated);

            message.success(intl.formatMessage({ id: 'status.saved' }));
            setProjectSubmission(projectSubmissionUpdated);
            setReadonly(readonly);
        } catch (error) {
            notificationService.displayError(error, intl, [
                { status: 409, message: 'projectSubmission.status.duplicate' },
            ]);
        } finally {
            setSaving(undefined);
        }
    };

    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    const validateFile = (
        rule: RuleObject,
        value: StoreValue,
        callback: (error?: string) => void,
    ): Promise<void> | void => {
        if (value && !value.file && !value.name) {
            callback(intl.formatMessage({ id: 'status.mandatory' }));
        }
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const downloadFile = async (projectSubmission: ProjectSubmission) => {
        try {
            if (projectSubmission.id) {
                await projectSubmissionApi.download(projectSubmission);
            } else if (files.length > 0) {
                const file = files[0];
                FileSaver.saveAs(file as any, file.name);
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        }
    };

    const remove = async () => {
        try {
            setSaving('deleting');
            await projectSubmissionApi.delete(projectSubmission!);
            history.push(`/setup/project-submissions`);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setSaving(undefined);
        }
    };

    /*** COMPONENTS ***/

    const projectTypeOptions = projectTypes.map((projectType) => (
        <Select.Option key={projectType} value={projectType}>
            <FormattedMessage id={projectType} />
        </Select.Option>
    ));
    const designTemplateTypeOptions = projectSubmissionDesignTemplateTypes.map((designTemplate) => (
        <Select.Option key={designTemplate} value={designTemplate}>
            {designTemplate}
        </Select.Option>
    ));
    const countryOptions = Object.entries(countries.getNames('en', { select: 'official' })).map((country: any[]) => (
        <Select.Option key={country[0]} value={country[0]}>
            {country[1]}
        </Select.Option>
    ));

    return (
        <LayoutComponent pageId="projectSubmissions">
            <WrapperComponent title={<FormattedMessage id="projectSubmission.title" />}>
                <Form form={form} onFinish={save} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col span={8}>
                            <Form.Item
                                label={<FormattedMessage id="projectSubmission.projectCode" />}
                                name="projectCode"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={10} size="large" disabled={readonly} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={<FormattedMessage id="projectSubmission.projectCountry" />}
                                name="projectCountry"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select
                                    size="large"
                                    showSearch
                                    filterOption={stringService.filterOptions}
                                    disabled={readonly}
                                >
                                    {countryOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={<FormattedMessage id="projectSubmission.projectCity" />}
                                name="projectCity"
                            >
                                <Input maxLength={200} size="large" disabled={readonly} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={8}>
                            <Form.Item
                                label={<FormattedMessage id="projectSubmission.designTemplate" />}
                                name="designTemplate"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select size="large" disabled={readonly}>
                                    {designTemplateTypeOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label={<FormattedMessage id="projectSubmission.templateGeneration" />}
                                name="templateGeneration"
                            >
                                <Input maxLength={50} size="large" disabled={readonly} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={8}>
                            <Form.Item
                                label={<FormattedMessage id="projectSubmission.projectType" />}
                                name="projectType"
                            >
                                <Select size="large" disabled={readonly} allowClear>
                                    {projectTypeOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={<FormattedMessage id="projectSubmission.practicalCompletionDate" />}
                                name="practicalCompletionDate"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <DatePicker size="large" format="DD/MM/YYYY" disabled={readonly} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label={<FormattedMessage id="projectSubmission.state" />} name="state">
                                <ProjectSubmissionStateComponent state={projectSubmission?.state} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Form.Item
                                name="contactsFile"
                                valuePropName="files"
                                label={
                                    <>
                                        <span>
                                            <FormattedMessage id="projectSubmission.contactsFile" />
                                        </span>
                                        <a
                                            href={`${process.env.PUBLIC_URL}/project-submission-contacts-template.xlsx`}
                                            download={intl.formatMessage({
                                                id: 'projectSubmission.contactsFile.file',
                                            })}
                                            className={`${styles.link} primary-color`}
                                        >
                                            <FormattedMessage id="projectSubmission.contactsFile.link" />
                                        </a>
                                    </>
                                }
                                rules={[
                                    { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                    {
                                        validator: validateFile,
                                    },
                                ]}
                                extra={
                                    <>
                                        <FormattedMessage id="attachment.size" />:{' '}
                                        <FileSizeComponent value={maxFileSize} />
                                    </>
                                }
                            >
                                <Upload.Dragger
                                    beforeUpload={uploadFile}
                                    fileList={files}
                                    showUploadList={false}
                                    disabled={readonly}
                                >
                                    <Icon component={CloudUploadSvg} />{' '}
                                    <FormattedMessage id="projectSubmission.contactsFile.upload" />
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                        <Col span={14}>
                            <List
                                className={styles.files}
                                itemLayout="horizontal"
                                dataSource={files}
                                locale={{ emptyText: <></> }}
                                renderItem={(file) => (
                                    <List.Item onClick={() => downloadFile(projectSubmission!)} className={styles.file}>
                                        <List.Item.Meta
                                            avatar={<Icon component={AttachmentSvg} />}
                                            title={file.name}
                                            description={
                                                <FileSizeComponent
                                                    value={file.size || projectSubmission!.contactsFile!.size}
                                                />
                                            }
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>

                    <Form.Item className="buttons">
                        {projectSubmission && (
                            <Space>
                                {projectSubmission.state === 'DRAFT' && (
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        icon={<Icon component={SaveSvg} />}
                                        loading={saving === 'saving'}
                                    >
                                        <FormattedMessage id="button.save" tagName="span" />
                                    </Button>
                                )}
                                {projectSubmission.state === 'DRAFT' && projectSubmission.id && (
                                    <Popconfirm
                                        title={<FormattedMessage id="projectSubmission.submit.confirmation" />}
                                        onConfirm={() => saveWithState('SUBMITTED')}
                                        okText={<FormattedMessage id="button.yes" />}
                                        cancelText={<FormattedMessage id="button.no" />}
                                        placement="right"
                                    >
                                        <Button
                                            type="primary"
                                            danger
                                            size="large"
                                            icon={<Icon component={CheckmarkSvg} />}
                                            loading={saving === 'SUBMITTED'}
                                        >
                                            <FormattedMessage id="button.submit" tagName="span" />
                                        </Button>
                                    </Popconfirm>
                                )}
                                {projectSubmission.state === 'SUBMITTED' && (
                                    <Popconfirm
                                        title={<FormattedMessage id="projectSubmission.approve.confirmation" />}
                                        onConfirm={() => saveWithState('APPROVED')}
                                        okText={<FormattedMessage id="button.yes" />}
                                        cancelText={<FormattedMessage id="button.no" />}
                                        placement="right"
                                    >
                                        <Button
                                            type="primary"
                                            size="large"
                                            icon={<Icon component={CheckmarkSvg} />}
                                            loading={saving === 'APPROVED'}
                                        >
                                            <FormattedMessage id="button.approve" tagName="span" />
                                        </Button>
                                    </Popconfirm>
                                )}
                                {projectSubmission.state === 'SUBMITTED' && (
                                    <Popconfirm
                                        title={<FormattedMessage id="projectSubmission.reject.confirmation" />}
                                        onConfirm={() => saveWithState('REJECTED')}
                                        okText={<FormattedMessage id="button.yes" />}
                                        cancelText={<FormattedMessage id="button.no" />}
                                        placement="right"
                                    >
                                        <Button
                                            type="primary"
                                            danger
                                            size="large"
                                            icon={<Icon component={CheckmarkSvg} />}
                                            loading={saving === 'REJECTED'}
                                        >
                                            <FormattedMessage id="button.reject" tagName="span" />
                                        </Button>
                                    </Popconfirm>
                                )}
                                {projectSubmission.state === 'REJECTED' && (
                                    <Popconfirm
                                        title={<FormattedMessage id="projectSubmission.draft.confirmation" />}
                                        onConfirm={() => saveWithState('DRAFT')}
                                        okText={<FormattedMessage id="button.yes" />}
                                        cancelText={<FormattedMessage id="button.no" />}
                                        placement="right"
                                    >
                                        <Button
                                            type="default"
                                            size="large"
                                            icon={<Icon component={EditSvg} />}
                                            loading={saving === 'DRAFT'}
                                        >
                                            <FormattedMessage id="projectSubmission.draft" tagName="span" />
                                        </Button>
                                    </Popconfirm>
                                )}
                                {projectSubmission &&
                                    projectSubmission.id &&
                                    projectSubmission.state !== 'APPROVED' &&
                                    projectSubmission.state !== 'REJECTED' && (
                                        <Popconfirm
                                            title={<FormattedMessage id="projectSubmission.delete.confirmation" />}
                                            onConfirm={remove}
                                            okText={<FormattedMessage id="button.yes" />}
                                            cancelText={<FormattedMessage id="button.no" />}
                                        >
                                            <Button
                                                type="ghost"
                                                size="large"
                                                icon={<Icon component={TrashCanSvg} />}
                                                loading={saving === 'deleting'}
                                            >
                                                <FormattedMessage id="button.delete" tagName="span" />
                                            </Button>
                                        </Popconfirm>
                                    )}
                                <Link to="/setup/project-submissions">
                                    <Button type="text" size="large">
                                        <FormattedMessage id="button.back" tagName="span" />
                                    </Button>
                                </Link>
                            </Space>
                        )}
                    </Form.Item>
                </Form>
            </WrapperComponent>
        </LayoutComponent>
    );
};

export default ProjectSubmissionPage;

type ParamsType = { id: string };
