import { WindowsOutlined } from '@ant-design/icons';
import { Badge, Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import notificationService from '../../services/NotificationService';
import sharePointService from '../../services/SharePointService';
import styles from './SharePointComponent.module.scss';

/**
 * Returns the header component.
 * @returns the header component.
 */
const SharePointComponent = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [active, setActive] = useState<boolean>();

    /*** EFFECTS ***/

    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = await sharePointService.getAccessTokenFromCache();
                setActive(!!accessToken);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        initialize();
    }, [intl]);

    /*** METHODS ***/

    const connect = async () => {
        try {
            await sharePointService.signIn();
            const accessToken = await sharePointService.getAccessTokenFromCache();
            setActive(!!accessToken);
        } catch (error) {
            notificationService.displayError(error, intl);
        }
    };

    /*** COMPONENTS ***/

    if (active) {
        return (
            <Tooltip
                title={<FormattedMessage id="header.settings.sharepoint.active.tooltip" />}
                className={styles.icon}
            >
                <Badge dot offset={[-3, 5]} color="green">
                    <Button type="link" icon={<WindowsOutlined />} />
                </Badge>
            </Tooltip>
        );
    } else if (active === false) {
        return (
            <Tooltip
                title={<FormattedMessage id="header.settings.sharepoint.inactive.tooltip" />}
                className={styles.icon}
            >
                <Badge dot offset={[-3, 5]}>
                    <Button type="link" icon={<WindowsOutlined />} onClick={connect} />
                </Badge>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip
                title={<FormattedMessage id="header.settings.sharepoint.connecting.tooltip" />}
                className={styles.icon}
            >
                <Button type="link" icon={<WindowsOutlined />} />
            </Tooltip>
        );
    }
};
export default SharePointComponent;
