import { Tree } from 'antd';
import Search from 'antd/lib/input/Search';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FolderDataNode, HubDataNode } from '../../../../model/elements';
import { Hub } from '../../../../model/entities';
import notificationService from '../../../../services/NotificationService';
import treeFolderService from '../../../../services/TreeFolderService';
import styles from './FoldersComponent.module.scss';

/**
 * Returns the folders component.
 * @props the props
 * @returns the folders component.
 */
const FoldersComponent = (props: Props): React.ReactElement => {
    const { hubs, onFolderSelected } = props;
    /*** HOOKS ***/

    const intl = useIntl();
    const [nodes, setNodes] = useState<HubDataNode[]>([]);
    const [searchText, setSearchText] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const hubNodes: HubDataNode[] = hubs.map((h) => treeFolderService.createHubNode(h));
                hubNodes.forEach((node) => (node.checkable = false));

                setNodes(hubNodes);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [hubs, intl]);

    /*** METHODS ***/

    const load = async (selectNode: EventDataNode<DataNode>) => {
        const nodesAux = [...nodes];
        await treeFolderService.loadSubnodes(selectNode.key, nodesAux);
        setNodes(nodesAux);
    };

    const check = async (checked: any, info: any) => {
        const folderNode = info.node as FolderDataNode;
        const selected: boolean = info.checked;
        onFolderSelected(folderNode, selected);
    };

    const search = (event: ChangeEvent<HTMLInputElement>) => {
        const searchText = event.target.value;
        setSearchText(searchText);
    };

    const highlightNode = (node: DataNode, searchText?: string): DataNode => {
        let nodeHighlighted: DataNode = node;
        if (searchText) {
            const title = node.title ? node.title.toString() : '';
            const index = title.indexOf(searchText);
            const beforeStr = title.substr(0, index);
            const afterStr = title.substr(index + searchText.length);
            const highlightedTitle =
                index > -1 ? (
                    <span>
                        {beforeStr}
                        <span className={styles.highlightedNode}>{searchText}</span>
                        {afterStr}
                    </span>
                ) : (
                    <span>{node.title}</span>
                );
            nodeHighlighted = Object.assign({}, node, { title: highlightedTitle });

            if (nodeHighlighted.children) {
                nodeHighlighted.children = nodeHighlighted.children.map((n) => highlightNode(n, searchText));
            }
        }

        return nodeHighlighted;
    };

    /*** COMPONENTS ***/

    const highlightNodes = nodes.map((n) => highlightNode(n, searchText));

    return (
        <>
            <Search
                placeholder={intl.formatMessage({ id: 'files.searchFolders' })}
                onChange={search}
                className={styles.search}
                size="large"
            />
            <Tree
                treeData={highlightNodes}
                checkable
                checkStrictly
                loadData={load}
                onCheck={check}
                className={styles.tree}
            />
        </>
    );
};

export default FoldersComponent;

interface Props {
    hubs: Hub[];
    onFolderSelected: (folderNode: FolderDataNode, selected: boolean) => void;
}
