import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import CustomContext from '../../context/CustomContext';
import { AuthorityType } from '../../model/types';

/**
 * This component represents a protected route. It redirect to the landing page if the user is not authorized to view a route.
 * @param props - the route props
 */
const ProtectedRoute: React.FC<Props> = (props: Props) => {
    const context = useContext(CustomContext);
    const { authorities, type, initialized } = props;
    const { auth } = context;

    const deeplink = sessionStorage.getItem('deeplink');
    if (auth && auth.initialized && deeplink) {
        sessionStorage.removeItem('deeplink');
        return <Redirect to={deeplink} />;
    } else if (auth && auth.initialized && !authorities) {
        return <Route {...props} />;
    } else if (
        auth &&
        auth.initialized &&
        authorities &&
        type === 'all' &&
        authorities.every((a) => auth.authorities)
    ) {
        return <Route {...props} />;
    } else if (
        auth &&
        auth.initialized &&
        authorities &&
        !type &&
        auth.authorities.some((a) => authorities.includes(a))
    ) {
        return <Route {...props} />;
    } else if (auth && !auth.initialized && initialized) {
        return <Route {...props} />;
    } else if (auth && !auth.initialized) {
        return <Redirect to="/login-error" />;
    } else {
        return <Redirect to="/login" />;
    }
};
export default ProtectedRoute;

export const ProtectedAdminRoute: React.FC<RouteProps> = (props) => {
    return <ProtectedRoute {...props} authorities={['ROLE_ADMIN']} />;
};
export const ProtectedSetupRoute: React.FC<RouteProps> = (props) => {
    return <ProtectedRoute {...props} authorities={['ROLE_ADMIN', 'ROLE_USER']} />;
};
export const ProtectedSyncRoute: React.FC<RouteProps> = (props) => {
    return <ProtectedRoute {...props} authorities={['ROLE_ADMIN', 'ROLE_USER']} />;
};

interface Props extends RouteProps {
    authorities?: AuthorityType[];
    type?: 'all' | 'some';
    initialized?: boolean;
}
