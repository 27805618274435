import { Select, Steps, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import projectApi from '../../../../api/ProjectApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import { EntityWithStatus, Project, ProjectMember } from '../../../../model/entities';
import { ProviderType } from '../../../../model/types';
import notificationService from '../../../../services/NotificationService';
import stringService from '../../../../services/StringService';
import styles from './ImportPage.module.scss';
import ResultComponent from './ResultComponent/ResultComponent';
import SummaryComponent from './SummaryComponent/SummaryComponent';
import UploadComponent from './UploadComponent/UploadComponent';

/**
 * Returns the project members import page.
 * @returns the project members import page.
 */
const ImportPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [step, setStep] = useState<number>(0);
    const [projects, setProjects] = useState<Project[]>([]);
    const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
    const [projectMembers, setProjectMembers] = useState<ProjectMember[]>([]);
    const [result, setResult] = useState<EntityWithStatus<ProjectMember>[]>();
    const [searchText, setSearchText] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const projects = await projectApi.list(params.provider, params.hubId);
                const selectedProjectIds = urlParams.getAll('projectId');
                setProjects(projects);
                setSelectedProjectIds(selectedProjectIds);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [intl, params.hubId, params.provider]);

    /*** METHODS ***/

    const showProjectMembers = (projectMembers: ProjectMember[]) => {
        const step = 1;
        setProjectMembers(projectMembers);
        setStep(step);
    };

    const showResults = (result: EntityWithStatus<ProjectMember>[]) => {
        const step = 2;
        setResult(result);
        setStep(step);
    };

    const back = () => {
        const stepUpdated = step - 1;
        setStep(stepUpdated);
    };

    const search = (event: any) => {
        const searchText: string = event.target.value;
        if (event.key === 'Enter' && searchText.includes(',')) {
            const searchedProjectsNames = searchText.split(',');
            const selectedProjects = projects.filter(
                (project) => searchedProjectsNames.find((s) => s === project.name) && project,
            );
            if (selectedProjects.length > 0) {
                const selectedProjectsIdsUpdated: string[] = Object.assign([], selectedProjectIds);
                selectedProjects.forEach((sp) => {
                    const projectId = selectedProjectIds.find((id) => id === sp.id);
                    if (projectId === undefined) {
                        selectedProjectsIdsUpdated.push(sp.id);
                    }
                });
                setSelectedProjectIds(selectedProjectsIdsUpdated);
                setSearchText(undefined);
            }
        }
    };

    const selectProjects = (values: string[], option: any) => {
        if (values.length > 0 && values[values.length - 1] === 'all') {
            if (selectedProjectIds.length === projects.length) {
                setSelectedProjectIds([]);
            } else {
                const selectedProjectsIds = projects.map((project) => project.id);
                setSelectedProjectIds(selectedProjectsIds);
            }
        } else {
            setSelectedProjectIds(values);
        }
    };

    /*** COMPONENTS ***/

    const selectedProjects = projects.filter((p) => selectedProjectIds.includes(p.id));
    let projectOptions = projects
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((project) => (
            <Select.Option key={project.id} value={project.id}>
                {project.name}
                {project.platform && (
                    <>
                        {' '}
                        <Tag>{project.platform}</Tag>
                    </>
                )}
            </Select.Option>
        ));

    projectOptions = [
        <Select.Option key="all" value="all">
            <FormattedMessage id="button.selectAll" />
        </Select.Option>,
        ...projectOptions,
    ];

    return (
        <LayoutComponent pageId="projectMembers">
            <h1 className={styles.title}>
                <FormattedMessage id="projectMembers.import.title" />
            </h1>
            {step === 0 && (
                <>
                    <h4>
                        <FormattedMessage id="projectMembers.import.projects" />
                    </h4>
                    <Select
                        size="large"
                        allowClear
                        className={styles.projects}
                        value={selectedProjectIds}
                        onChange={selectProjects}
                        showSearch
                        filterOption={stringService.filterOptions}
                        mode="multiple"
                        onKeyUp={search}
                        onSearch={setSearchText}
                        onSelect={() => setSearchText(undefined)}
                        searchValue={searchText}
                        maxTagCount={8}
                    >
                        {projectOptions}
                    </Select>
                </>
            )}
            {step > 0 && (
                <h4 className={styles.projects}>
                    {selectedProjects.map((p, index) => {
                        return (
                            <>
                                <span>
                                    {p.name}
                                    {p.platform && (
                                        <>
                                            {' '}
                                            <Tag style={{ margin: 0 }}>{p.platform}</Tag>
                                        </>
                                    )}
                                </span>
                                {index < selectedProjectIds.length - 1 && <>{',  '}</>}
                            </>
                        );
                    })}
                </h4>
            )}
            <Steps
                current={step}
                className={styles.steps}
                items={[
                    { title: <FormattedMessage id="projectMembers.import.steps.1" /> },
                    { title: <FormattedMessage id="projectMembers.import.steps.2" /> },
                    { title: <FormattedMessage id="projectMembers.import.steps.3" /> },
                ]}
            />
            <div hidden={step !== 0}>
                <UploadComponent
                    hubId={params.hubId}
                    projects={selectedProjects}
                    onLoadProjectMembers={showProjectMembers}
                />
            </div>
            {step === 1 && (
                <SummaryComponent
                    hubId={params.hubId}
                    projects={selectedProjects}
                    projectMembers={projectMembers}
                    onSaveMembers={showResults}
                    onBack={back}
                />
            )}
            {step === 2 && result && (
                <ResultComponent result={result} hubId={params.hubId} projects={selectedProjects} />
            )}
        </LayoutComponent>
    );
};

export default ImportPage;

type ParamsType = { provider: ProviderType; hubId: string };
