import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Tag } from 'antd';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import projectIssueAttachmentApi from '../../../../api/ProjectIssueAttachmentApi';
import taskProjectIssuesApi from '../../../../api/TaskProjectIssueApi';
import { Hub, Project, ProjectIssue, Task, TaskProjectIssue } from '../../../../model/entities';
import notificationService from '../../../../services/NotificationService';
import stringService from '../../../../services/StringService';
import styles from './CopyProjectIssuesModal.module.scss';
import CustomContext from '../../../../context/CustomContext';

/**
 * Returns the copy project issues modal.
 * @props the props
 * @returns the copy project issues modal
 */
const CopyProjectIssuesModal = (props: Props): React.ReactElement => {
    const { hub, onCancel, projects, selectedProjectIssues, sourceProject } = props;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const { auth } = useContext(CustomContext);
    const organizationId = auth!.organization!.id;
    const [loading, setLoading] = useState<'saving'>();
    const [task, setTask] = useState<Task>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('saving');

            // create issue task
            const targetProject = projects.find((p) => p.id === values.projectId)!;
            let task: TaskProjectIssue = {
                organizationId,
                sourceHub: hub,
                sourceProject,
                issues: selectedProjectIssues.map((i) => ({
                    issueId: i.id,
                    title: i.title,
                    identifier: i.identifier,
                    attachments: [],
                    action: i,
                    targetHub: hub,
                    targetProject: targetProject,
                })),
            };
            await loadAttachments(task);
            task = await taskProjectIssuesApi.create(task);

            setTask(task);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const loadAttachments = async (task: TaskProjectIssue) => {
        for (const taskProjectIssueAction of task.issues) {
            const attachments = await projectIssueAttachmentApi.list(
                task.sourceHub.id,
                task.sourceProject.id,
                taskProjectIssueAction.issueId,
            );
            taskProjectIssueAction.attachments = attachments.map((a) => ({ name: a.name, attachmentId: a.id! }));
        }
    };

    const copy = async () => {
        try {
            const values = await form.validateFields();
            await save(values);
        } catch (error) {
            // validated by form
        }
    };

    /*** COMPONENTS ***/

    const projectOptions = projects
        .filter((p) => p.id !== sourceProject.id)
        .map((project) => (
            <Select.Option key={project.id} value={project.id}>
                {project.name}
                {project.platform && (
                    <>
                        {' '}
                        <Tag>{project.platform}</Tag>
                    </>
                )}
            </Select.Option>
        ));

    return (
        <Modal
            title={<FormattedMessage id="projectIssues.copy.modal.title" />}
            open={true}
            className={styles.modal}
            width={800}
            onCancel={onCancel}
            footer={[
                <Button key="cancel" onClick={onCancel} hidden={!!task}>
                    <FormattedMessage id="button.cancel" />
                </Button>,
                <Button
                    key="copy"
                    type="primary"
                    htmlType="submit"
                    onClick={copy}
                    hidden={!!task}
                    loading={loading === 'saving'}
                >
                    <FormattedMessage id="button.copy" tagName="span" />
                </Button>,
                <Button key="close" onClick={onCancel} hidden={!task}>
                    <FormattedMessage id="button.close" />
                </Button>,
                <Link key="task" to={task ? `/task-project-issues/${task?.id}` : '/tasks'}>
                    <Button type="primary" hidden={!task} className={styles.task}>
                        <FormattedMessage id="projectIssues.copy.modal.viewTask" />
                    </Button>
                </Link>,
            ]}
        >
            {task ? (
                <div className={styles.result}>
                    <InfoCircleOutlined className={styles.icon} />
                    <FormattedMessage id="projectIssues.copy.modal.successful" />
                </div>
            ) : (
                <Form form={form} colon={false} layout="vertical">
                    <Form.Item
                        label={<FormattedMessage id="projectIssues.copy.modal.description" />}
                        name="projectId"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        className={styles.project}
                    >
                        <Select
                            size="large"
                            allowClear
                            showSearch
                            filterOption={stringService.filterOptions}
                            disabled={!!task}
                        >
                            {projectOptions}
                        </Select>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};

export default CopyProjectIssuesModal;

interface Props {
    onCancel: () => void;
    hub: Hub;
    sourceProject: Project;
    projects: Project[];
    selectedProjectIssues: ProjectIssue[];
}
