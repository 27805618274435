import FileSaver from 'file-saver';
import moment from 'moment';
import { Page } from '../model/elements';
import { Task } from '../model/entities';
import adminApiAxios from './CustomAxios';
import { TaskType } from '../model/types';

class TaskApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
        taskType?: TaskType,
    ): Promise<Page<Task>> => {
        const response = await adminApiAxios.get<Page<Task>>('/tasks', {
            params: { page, size, sortField, sortOrder, searchText, taskType },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.audit!.created = d.audit!.created && moment.utc(d.audit!.created).local();
            d.audit!.updated = d.audit!.updated && moment.utc(d.audit!.updated).local();
        });

        return response.data;
    };

    get = async (uuid: string): Promise<Task> => {
        const response = await adminApiAxios.get<Task>(`/tasks/${uuid}`);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };

    exportSpreadSheet = async (sortField: string, sortOrder: boolean, searchText?: string) => {
        const response = await adminApiAxios.get<Blob>(`/tasks/spreadsheets`, {
            responseType: 'blob',
            params: { sortField, sortOrder, searchText },
        });
        FileSaver.saveAs(response.data, 'tasks.xlsx');
    };
}

const taskApi: TaskApi = new TaskApi();
export default taskApi;
