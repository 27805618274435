import Icon, { FileExcelOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Tooltip, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { TablePaginationConfig, TableRowSelection } from 'antd/lib/table/interface';
import React, { Key, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import fileApi from '../../../../api/FileApi';
import FileSizeComponent from '../../../../components/FileSizeComponent/FileSizeComponent';
import SelectTableComponent from '../../../../components/SelectTableComponent/SelectTableComponent';
import { CustomAttribute, CustomAttributeDefinition, File, FileExportExcel } from '../../../../model/entities';
import { ReactComponent as CaretDownSvg } from '../../../../resources/images/caret-down.svg';
import { ReactComponent as EditSvg } from '../../../../resources/images/edit.svg';
import comparatorService from '../../../../services/ComparatorService';
import notificationService from '../../../../services/NotificationService';
import stringService from '../../../../services/StringService';
import tableService from '../../../../services/TableService';
import styles from './FilesComponent.module.scss';
import UpdateCustomAttributesModal from './UpdateCustomAttributesModal/UpdateCustomAttributesModal';

/**
 * Returns the files component.
 * @props the props
 * @returns the files component.
 */
const FilesComponent = (props: Props): React.ReactElement => {
    const { customAttributeDefinitions, files, onSearch, loading } = props;
    /*** HOOKS ***/

    const intl = useIntl();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [customAttributesModalVisible, setCustomAttributesModalVisible] = useState<boolean>();
    const [exporting, setExporting] = useState<boolean>();
    const [sortField, setSortField] = useState<string | undefined>('name');
    const [sortOrder, setSortOrder] = useState<boolean | undefined>(true);

    /*** EFFECTS ***/

    /*** METHODS ***/

    const search = (searchText: string) => {
        unselectAll();
        onSearch(searchText);
    };

    const select = (selectedRowKeys: Key[], selectedRows: File[]) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedFiles(selectedRows);
    };

    const unselectAll = () => {
        setSelectedRowKeys([]);
        setSelectedFiles([]);
    };

    const showCustomAttributesModal = async (customAttributesModalVisible: boolean) => {
        setCustomAttributesModalVisible(customAttributesModalVisible);
    };

    const updateCustomAttributes = async (customAttributes: CustomAttribute[]) => {
        try {
            const selectedFilesWithUpdatedAttributes = selectedFiles.map((f) =>
                Object.assign({}, f, {
                    customAttributes: customAttributes.filter((ca) => ca.projectId === f.projectId),
                }),
            );
            const filesWithStatus = await fileApi.updateCustomAttributes(selectedFilesWithUpdatedAttributes);
            unselectAll();
            showCustomAttributesModal(false);

            // display message
            if (filesWithStatus.every((f) => f.status.type === 'OK')) {
                message.success(intl.formatMessage({ id: 'status.saved' }));
            } else {
                message.warning(intl.formatMessage({ id: 'status.savedWithErrors' }), 5);
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        }
    };

    const isUpdateAttributesDisabled = (): boolean => {
        return selectedFiles.length === 0;
    };

    const exportSpreadSheet = async () => {
        try {
            setExporting(true);
            const filesAux = [...files];
            let filesToExport: File[] = [];

            // If we have applied a sorting in the table, we sort the data, else we keep it the same
            if (sortField) {
                const filesSorted = filesAux.sort((a: any, b: any) => {
                    const comparationResult = comparatorService.sort(a, b, sortField, sortOrder);
                    return comparationResult;
                });

                filesToExport = filesSorted;
            } else {
                filesToExport = files;
            }

            // Then, we transform the data to a fileexportexcel file type so we can send the date in string format
            const filesExcel: FileExportExcel[] = filesToExport.map((fs) => {
                const updatedAtString = fs.updatedAt
                    ? intl.formatDate(fs.updatedAt as any, {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                      })
                    : undefined;
                const fileExcel: FileExportExcel = {
                    name: fs.name,
                    id: fs.id,
                    updatedAt: updatedAtString,
                    updatedBy: fs.updatedBy,
                    version: fs.version,
                    size: fs.size,
                    path: fs.path,
                };
                return fileExcel;
            });

            // Finally, we send the data to the api to export it in excel format
            await fileApi.exportSpreadSheet(filesExcel);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setExporting(false);
        }
    };

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        if (sorter.order) {
            setSortField(sorter.field);
            setSortOrder(sorter.order === 'ascend');
        } else {
            setSortField(undefined);
            setSortOrder(undefined);
        }
    };

    /*** COMPONENTS ***/

    const columns: ColumnsType<File> = [
        {
            title: <FormattedMessage id="file.name" />,
            dataIndex: 'name',
            key: 'name',
            className: styles.inline,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => stringService.sort(a.name, b.name),
            render: (value: string) => <Tooltip title={value}>{value}</Tooltip>,
        },
        {
            title: <FormattedMessage id="file.path" />,
            dataIndex: 'path',
            key: 'path',
            className: styles.inline,
            sorter: (a, b) => stringService.sort(a.path, b.path),
            render: (value: string) => <Tooltip title={value}>{value}</Tooltip>,
        },
        {
            title: <FormattedMessage id="file.updatedAt" />,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            align: 'center',
            width: '180px',
            sorter: (a, b) => (a.updatedAt!.isAfter(b.updatedAt) ? 1 : -1),
            render: (value: string) => {
                return (
                    <FormattedDate
                        value={value}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                    />
                );
            },
        },
        {
            title: <FormattedMessage id="file.updatedBy" />,
            dataIndex: 'updatedBy',
            key: 'updatedBy',
            width: '200px',
            sorter: (a, b) => stringService.sort(a.updatedBy, b.updatedBy),
        },
        {
            title: <FormattedMessage id="file.version" />,
            dataIndex: 'version',
            key: 'version',
            align: 'right',
            width: '100px',
            sorter: (a, b) => stringService.sort(a.version, b.version),
        },
        {
            title: <FormattedMessage id="file.size" />,
            dataIndex: 'size',
            key: 'size',
            align: 'right',
            width: '100px',
            sorter: (a, b) => a.size! - b.size!,
            render: (value: number) => {
                return <FileSizeComponent value={value} />;
            },
        },
        // ...this.renderCustomAttributeColumns(),
    ];
    const rowSelection: TableRowSelection<File> = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: select,
    };

    const placeholder: string = intl.formatMessage({ id: 'files.search' });
    const disabled = selectedFiles.length === 0;

    return (
        <>
            <div className="toolbar">
                <Search
                    placeholder={placeholder}
                    onSearch={search}
                    size="large"
                    className="search"
                    disabled={!!loading}
                />
                <Space>
                    <Tooltip title={<FormattedMessage id="files.exportSpreadSheet" />}>
                        <Button
                            size="large"
                            icon={<FileExcelOutlined />}
                            onClick={exportSpreadSheet}
                            loading={exporting}
                            disabled={files.length === 0}
                        ></Button>
                    </Tooltip>
                    {false && (
                        <Dropdown
                            trigger={['click']}
                            placement="bottomRight"
                            disabled={disabled}
                            menu={{
                                items: [
                                    {
                                        key: 'attributes',
                                        icon: <Icon component={EditSvg} />,
                                        onClick: () => showCustomAttributesModal(true),
                                        disabled: isUpdateAttributesDisabled(),
                                        label: <FormattedMessage id="files.updateCustomAttributes" />,
                                    },
                                ],
                            }}
                        >
                            <Button type="primary" size="large">
                                <FormattedMessage id="button.actions" /> <Icon component={CaretDownSvg} />
                            </Button>
                        </Dropdown>
                    )}
                </Space>
            </div>
            <SelectTableComponent
                dataSource={files}
                columns={columns}
                pagination={tableService.createMemoryPagination(files)}
                rowKey="id"
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
                rowSelection={rowSelection}
                className={styles.table}
                selectedItems={selectedFiles.map((file) => file.name)}
                onUnselectAll={unselectAll}
                onChange={handleTableChange}
            />
            {customAttributesModalVisible && (
                <UpdateCustomAttributesModal
                    visible={customAttributesModalVisible}
                    customAttributeDefinitions={customAttributeDefinitions}
                    onUpdate={updateCustomAttributes}
                    onCancel={() => showCustomAttributesModal(false)}
                />
            )}
        </>
    );
};

export default FilesComponent;

interface Props {
    files: File[];
    customAttributeDefinitions: CustomAttributeDefinition[];
    loading?: boolean;
    onSearch: (searchText: string) => void;
}
