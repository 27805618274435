import { Select, Steps, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import projectApi from '../../../../api/ProjectApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import { IssueImport, Project, TaskProjectIssue } from '../../../../model/entities';
import { ProviderType } from '../../../../model/types';
import notificationService from '../../../../services/NotificationService';
import stringService from '../../../../services/StringService';
import styles from './ImportPage.module.scss';
import ResultComponent from './ResultComponent/ResultComponent';
import SummaryComponent from './SummaryComponent/SummaryComponent';
import UploadComponent from './UploadComponent/UploadComponent';

/**
 * Returns the project issues import page.
 * @returns the project issues import page.
 */
const ImportPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [step, setStep] = useState<number>(0);
    const [projectId, setProjectId] = useState<string | null>();
    const [projects, setProjects] = useState<Project[]>([]);
    const [issueImports, setIssueImports] = useState<IssueImport[]>([]);
    const [result, setResult] = useState<TaskProjectIssue>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const projects = await projectApi.listUserProjects(params.provider, params.hubId);
                const projectId = urlParams.get('projectId');
                setProjects(projects);
                setProjectId(projectId);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [intl, params.hubId, params.provider]);

    /*** METHODS ***/

    const showIssueImports = (issueImports: IssueImport[]) => {
        const step = 1;
        setIssueImports(issueImports);
        setStep(step);
    };

    const showResults = (result: TaskProjectIssue) => {
        const step = 2;
        setResult(result);
        setStep(step);
    };

    const back = () => {
        const stepUpdated = step - 1;
        setStep(stepUpdated);
    };

    /*** COMPONENTS ***/

    const selectedProject = projects.find((p) => p.id === projectId);
    const projectOptions = projects
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((project) => (
            <Select.Option key={project.id} value={project.id}>
                {project.name}
                {project.platform && (
                    <>
                        {' '}
                        <Tag>{project.platform}</Tag>
                    </>
                )}
            </Select.Option>
        ));

    return (
        <LayoutComponent pageId="projectIssues">
            <h1 className={styles.title}>
                <FormattedMessage id="projectMembers.import.title" />
            </h1>
            {step === 0 && (
                <>
                    <h4>
                        <FormattedMessage id="projectIssues.import.project" />
                    </h4>
                    <Select
                        size="large"
                        className={styles.projects}
                        value={projectId}
                        onChange={setProjectId}
                        showSearch
                        filterOption={stringService.filterOptions}
                    >
                        {projectOptions}
                    </Select>
                </>
            )}
            {step > 0 && selectedProject && (
                <h4 className={styles.projects}>
                    <>
                        <span>
                            {selectedProject.name}
                            {selectedProject.platform && (
                                <>
                                    {' '}
                                    <Tag style={{ margin: 0 }}>{selectedProject.platform}</Tag>
                                </>
                            )}
                        </span>
                    </>
                </h4>
            )}
            <Steps
                current={step}
                className={styles.steps}
                items={[
                    { title: <FormattedMessage id="projectMembers.import.steps.1" /> },
                    { title: <FormattedMessage id="projectMembers.import.steps.2" /> },
                    { title: <FormattedMessage id="projectMembers.import.steps.3" /> },
                ]}
            />
            <div hidden={step !== 0}>
                <UploadComponent hubId={params.hubId} project={selectedProject} onLoadIssueImports={showIssueImports} />
            </div>
            {step === 1 && (
                <SummaryComponent
                    hubId={params.hubId}
                    project={selectedProject}
                    projects={projects}
                    issueImports={issueImports}
                    onSaveIssues={showResults}
                    onBack={back}
                />
            )}
            {step === 2 && result && <ResultComponent result={result} />}
        </LayoutComponent>
    );
};

export default ImportPage;

type ParamsType = { provider: ProviderType; hubId: string };
