import moment from 'moment';
import { TaskCopyIssueFile } from '../model/entities';
import { syncApiAxios } from './CustomAxios';

class TaskCopyIssueFileApi {
    get = async (id: number): Promise<TaskCopyIssueFile> => {
        const response = await syncApiAxios.get<TaskCopyIssueFile>(`/task-copy-issue-files/${id}`);
        response.data.sourceFileUpdated =
            response.data.sourceFileUpdated && moment.utc(response.data.sourceFileUpdated).local();
        response.data.targetFileUpdated =
            response.data.targetFileUpdated && moment.utc(response.data.targetFileUpdated).local();
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };
}

const taskCopyIssueFileApi: TaskCopyIssueFileApi = new TaskCopyIssueFileApi();
export default taskCopyIssueFileApi;
