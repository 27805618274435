import Icon, { CloudUploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as CloudUpload } from '../../../../resources/images/cloud-upload.svg';
import styles from './ImportPage.module.scss';
import { useSetupProjectsImport } from './SetupProjectsImportContext';
import useUploadController from './useUploadController';

const UploadComponent: React.FC = () => {
    const intl = useIntl();

    const { handleGoBack, isTemplatesLoading, platform } = useSetupProjectsImport();
    const {
        inputValue,
        selectedFile,
        handleUpload,
        inputRef,
        dragActive,
        handleDrag,
        handleDrop,
        handleChange,
        handleInputValue,
        isInputError,
    } = useUploadController();

    return (
        <>
            <form className={styles.uploadform} onDragEnter={handleDrag}>
                <div className={styles.boxwithhelpermessage}>
                    <div className={styles.helpertext}>
                        <FormattedMessage id="projects.new.import.excel.steps.1.upload" />{' '}
                        <a
                            className={`${styles.underline} primary-color`}
                            href={`${process.env.PUBLIC_URL}/${
                                platform === 'ACC'
                                    ? 'M.Link_Projects_Import-ACC.xlsx'
                                    : 'M.Link_Projects_Import-BIM360.xlsx'
                            }`}
                            download={
                                platform === 'ACC'
                                    ? 'M.Link_Projects_Import-ACC.xlsx'
                                    : 'M.Link_Projects_Import-BIM360.xlsx'
                            }
                        >
                            <FormattedMessage id="projects.new.import.excel.steps.1.upload.link" />
                        </a>
                    </div>
                    <div className={styles.fileuploadbox}>
                        <input
                            ref={inputRef}
                            className={styles.hidden}
                            id="import-excel-file"
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                            multiple={false}
                            disabled={!!inputValue}
                            onChange={handleChange}
                        />
                        <label htmlFor="import-excel-file" className={styles.modalbody}>
                            <div className={styles.uploadbox}>
                                <CloudUploadOutlined />
                                <div className={styles.uploadhelpermessage}>
                                    <FormattedMessage id="projects.new.import.excel.file.upload.box" />
                                </div>
                            </div>
                        </label>
                        {selectedFile?.name && <div className={styles.uploadedfilename}>{selectedFile?.name}</div>}
                    </div>
                </div>

                {platform === 'ACC' && (
                    <div className={styles.boxwithhelpermessage}>
                        <div className={styles.helpertext}>
                            <FormattedMessage id="projects.new.import.excel.steps.1.text" />
                        </div>
                        <TextArea
                            className={styles.grayborderbox}
                            disabled={!!selectedFile}
                            value={inputValue}
                            onChange={handleInputValue}
                            placeholder={intl.formatMessage({
                                id: `projects.new.import.excel.steps.1.text.placeholder.${`${platform}`.toLowerCase()}`,
                            })}
                            status={isInputError ? 'error' : undefined}
                        />
                        {isInputError && !selectedFile && (
                            <div className={`${styles.helpertext} ${styles.error}`}>
                                {intl.formatMessage({ id: 'projects.new.import.excel.steps.1.input.error' })}
                            </div>
                        )}
                    </div>
                )}
                {dragActive && (
                    <div
                        className={styles.dragactive}
                        onDragEnter={handleDrag}
                        onDragLeave={handleDrag}
                        onDragOver={handleDrag}
                        onDrop={handleDrop}
                    ></div>
                )}
            </form>
            <div className={styles.buttongroup}>
                <Button
                    className={styles.loadbutton}
                    size="large"
                    type="primary"
                    icon={<Icon component={CloudUpload} />}
                    onClick={handleUpload}
                    disabled={isTemplatesLoading || isInputError || (!inputValue && !selectedFile)}
                    loading={isTemplatesLoading}
                >
                    <FormattedMessage id="button.load" />
                </Button>
                <Button className={styles.backbutton} size="large" type="link" onClick={handleGoBack} loading={false}>
                    <FormattedMessage id="button.back" />
                </Button>
            </div>
        </>
    );
};

export default UploadComponent;
