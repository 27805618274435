import React from 'react';

import { SetupProjectsProvider } from './SetupProjectsContext';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import SetupProjectsPageHeader from './SetupProjectsPageHeader';
import SetupProjectsPageBody from './SetupProjectsPageBody';

function ProjectsPage() {
    return (
        <LayoutComponent pageId="projects">
            <SetupProjectsProvider>
                <SetupProjectsPageHeader />
                <SetupProjectsPageBody />
            </SetupProjectsProvider>
        </LayoutComponent>
    );
}

export default ProjectsPage;
