import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Task, TaskProjectAction } from '../../../../model/entities';

const TaskProjectActionComponent: React.FC<{
    task: Task;
    taskProjectAction: TaskProjectAction;
}> = ({ task, taskProjectAction }) => {
    switch (task.taskType) {
        case 'PROJECT_IMPORT':
            return (
                <>
                    <FormattedMessage id={`taskProjectAction.action.${task.taskType}`} />{' '}
                </>
            );
        default:
            return <></>;
    }
};

export default TaskProjectActionComponent;
