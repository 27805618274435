import { Company } from '../model/entities';
import { proxyApiAxios } from './CustomAxios';

class CompanyApi {
    list = async (hubId: string): Promise<Company[]> => {
        const response = await proxyApiAxios.get<Company[]>('/companies', {
            params: { hubId },
        });
        return response.data;
    };
}

const companyApi: CompanyApi = new CompanyApi();
export default companyApi;
