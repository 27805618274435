import { Checkbox, Form, Input, TreeSelect } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import folderApi from '../../../../../../../../api/FolderApi';
import { FolderDataNode } from '../../../../../../../../model/elements';
import { Connection, ConnectionLinkImport } from '../../../../../../../../model/entities';
import notificationService from '../../../../../../../../services/NotificationService';
import treeFolderService from '../../../../../../../../services/TreeFolderService';

const EditableCell: React.FC<Props> = ({
    editing,
    identifier,
    children,
    form,
    connectionLinkImport,
    connection,
    onFolderChange,
    onTargetFolderChange,
    ...restProps
}) => {
    /** HOOKS */

    const intl = useIntl();
    const [sourceFolderNodes, setSourceFolderNodes] = useState<FolderDataNode[]>([]);
    const [targetFolderNodes, setTargetFolderNodes] = useState<FolderDataNode[]>([]);
    const [loading, setLoading] = useState<'loadingFolders' | 'loadingFiles'>();

    /** EFFECTS */

    useEffect(() => {
        const loadSourceFolders = async () => {
            try {
                if (editing && identifier === 'sourceFolder' && connection && connectionLinkImport) {
                    setLoading('loadingFolders');

                    // load source folders

                    const sourceProjectId = connectionLinkImport.sourceProject?.id;
                    const sourceFolders = sourceProjectId
                        ? await folderApi.list(connection.source!.provider, connection.source!.id!, sourceProjectId)
                        : [];
                    const sourceFolderNodes = sourceFolders.map((f) => treeFolderService.createFolderNode(f));

                    setSourceFolderNodes(sourceFolderNodes);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        loadSourceFolders();
    }, [connection, connectionLinkImport, editing, identifier, intl]);

    useEffect(() => {
        const loadTargetFolders = async () => {
            try {
                if (editing && identifier === 'targetFolder' && connection && connectionLinkImport) {
                    setLoading('loadingFolders');

                    // load source folders

                    const targetProjectId = connectionLinkImport.targetProject?.id;
                    const targetFolders = targetProjectId
                        ? await folderApi.list(connection.target!.provider, connection.target!.id!, targetProjectId)
                        : [];
                    const targetFolderNodes = targetFolders.map((f) => treeFolderService.createFolderNode(f));

                    setTargetFolderNodes(targetFolderNodes);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        loadTargetFolders();
    }, [connection, connectionLinkImport, editing, identifier, intl]);

    /** METHODS */

    const loadSubfolderNodes = async (folderId: string) => {
        const sourceFolderNodesAux = [...sourceFolderNodes];
        await treeFolderService.loadSubnodes(folderId, sourceFolderNodesAux);
        setSourceFolderNodes(sourceFolderNodesAux);
    };

    const loadTargetSubfolderNodes = async (folderId: string) => {
        const targetFolderNodesAux = [...targetFolderNodes];
        await treeFolderService.loadSubnodes(folderId, targetFolderNodesAux);
        setTargetFolderNodes(targetFolderNodesAux);
    };

    const updateSource = async (id: string, sourceFolderNodes: FolderDataNode[]) => {
        const folderNode: FolderDataNode = treeFolderService.find(id, sourceFolderNodes) as FolderDataNode;
        onFolderChange(folderNode, connectionLinkImport.uuid!);
    };

    const updateTarget = async (id: string, targetFolderNodes: FolderDataNode[]) => {
        const folderNode: FolderDataNode = treeFolderService.find(id, targetFolderNodes) as FolderDataNode;
        onTargetFolderChange(folderNode, connectionLinkImport.uuid!);
    };

    /** COMPONENTS */

    return (
        <td {...restProps}>
            {editing
                ? getFormItem(
                      form,
                      identifier,
                      sourceFolderNodes,
                      targetFolderNodes,
                      loadSubfolderNodes,
                      loadTargetSubfolderNodes,
                      updateSource,
                      updateTarget,
                      loading,
                  )
                : children}
        </td>
    );
};
export default EditableCell;

const getFormItem = (
    form: FormInstance | undefined,
    identifier: string,
    sourceFolderNodes: FolderDataNode[],
    targetFolderNodes: FolderDataNode[],
    loadSubfolderNodes: (id: string) => Promise<void>,
    loadTargetSubfolderNodes: (id: string) => Promise<void>,
    updateSource: (id: string, sourceFolderNodes: FolderDataNode[]) => void,
    updateTarget: (id: string, targetFolderNodes: FolderDataNode[]) => void,
    loading?: 'loadingFolders' | 'loadingFiles',
) => {
    switch (identifier) {
        case 'sourceFolder':
            return (
                <Form.Item
                    name={identifier}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <TreeSelect
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={sourceFolderNodes}
                        loadData={(folderNode: any) => loadSubfolderNodes(folderNode.folder.id)}
                        allowClear
                        size="large"
                        onChange={(id: string) => updateSource(id, sourceFolderNodes)}
                        loading={loading === 'loadingFolders'}

                        //disabled={connectionLink && connectionLink.cloned}
                    />
                </Form.Item>
            );
        case 'files':
            return (
                <Form.Item
                    style={{ margin: 0 }}
                    shouldUpdate={(prevValues, curValues) => prevValues.includeAllFiles !== curValues.includeAllFiles}
                >
                    {({ getFieldValue }) => {
                        return (
                            <Form.Item name={identifier} style={{ margin: 0 }}>
                                <Input disabled={getFieldValue('includeAllFiles') === true} />
                            </Form.Item>
                        );
                    }}
                </Form.Item>
            );
        case 'extensions':
            return (
                <Form.Item
                    style={{ margin: 0 }}
                    shouldUpdate={(prevValues, curValues) => prevValues.includeAllFiles !== curValues.includeAllFiles}
                >
                    {({ getFieldValue }) => {
                        return (
                            <Form.Item name={identifier} style={{ margin: 0 }}>
                                <Input disabled={getFieldValue('includeAllFiles') === true} />
                            </Form.Item>
                        );
                    }}
                </Form.Item>
            );
        case 'includeAllFiles':
            return (
                <Form.Item name={identifier} style={{ margin: 0 }} valuePropName="checked" noStyle>
                    <Checkbox onChange={() => form?.resetFields(['files', 'extensions'])} />
                </Form.Item>
            );
        case 'includeSubfolders':
            return (
                <Form.Item name={identifier} style={{ margin: 0 }} valuePropName="checked" noStyle>
                    <Checkbox />
                </Form.Item>
            );
        case 'maintainFolderStructure':
            return (
                <Form.Item name={identifier} style={{ margin: 0 }} valuePropName="checked" noStyle>
                    <Checkbox />
                </Form.Item>
            );
        case 'targetFolder':
            return (
                <Form.Item
                    name={identifier}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <TreeSelect
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={targetFolderNodes}
                        loadData={(folderNode: any) => loadTargetSubfolderNodes(folderNode.folder.id)}
                        allowClear
                        size="large"
                        onChange={(id: string) => updateTarget(id, targetFolderNodes)}
                        loading={loading === 'loadingFolders'}
                        //disabled={connectionLink && connectionLink.cloned}
                    />
                </Form.Item>
            );
        default:
            break;
    }
};

interface Props extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    identifier: string;
    children: React.ReactNode;
    form?: FormInstance;
    connection?: Connection;
    connectionLinkImport: ConnectionLinkImport;
    onFolderChange: (folderNode: FolderDataNode, uuid: string) => void;
    onTargetFolderChange: (folderNode: FolderDataNode, uuid: string) => void;
}
