import React from 'react';
import { Form, Select, TreeSelect } from 'antd';
import { Project } from '../../../../../../../../model/entities';
import stringService from '../../../../../../../../services/StringService';
import { FolderDataNode } from '../../../../../../../../model/elements';

const EditableCell: React.FC<Props> = ({
    editing,
    identifier,
    dataIndex,
    title,
    index,
    children,
    projects,
    folderNodes,
    onProjectChange,
    onFolderChange,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing
                ? getFormItem(identifier, dataIndex, projects, folderNodes, onProjectChange, onFolderChange)
                : children}
        </td>
    );
};
export default EditableCell;

const getFormItem = (
    identifier: string,
    dataIndex: any,
    projects: Project[],
    folderNodes: FolderDataNode[],
    onProjectChange: (projectId: string) => Promise<void>,
    onFolderChange: (folderId: string) => Promise<void>,
) => {
    switch (identifier) {
        case 'project':
            return (
                <Form.Item name={dataIndex} style={{ margin: 0 }}>
                    <Select showSearch filterOption={stringService.filterOptions} onChange={onProjectChange}>
                        {projectOptions(projects)}
                    </Select>
                </Form.Item>
            );
        case 'folder':
            return (
                <Form.Item name={dataIndex} style={{ margin: 0 }}>
                    <TreeSelect
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={folderNodes}
                        loadData={(folderNode) => onFolderChange(folderNode.folder.id)}
                    />
                </Form.Item>
            );
        default:
            break;
    }
};

const projectOptions = (projects: Project[]) =>
    projects
        .sort((a, b) => stringService.sort(a.name, b.name))
        .map((project) => (
            <Select.Option key={project.id} value={project.id}>
                {project.name}
            </Select.Option>
        ));

interface Props extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    identifier: string;
    dataIndex: string;
    title: any;
    index: number;
    children: React.ReactNode;
    projects: Project[];
    folderNodes: FolderDataNode[];
    onProjectChange: (projectId: string) => Promise<void>;
    onFolderChange: (folderId: string) => Promise<void>;
}
