import { Page } from '../model/elements';
import { proxyApiAxios } from './CustomAxios';
import FileSaver from 'file-saver';
import moment from 'moment';
import { EntityWithStatus, Member, MemberConfiguration } from '../model/entities';

class MemberApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        hubId: string,
        searchText?: string,
    ): Promise<Page<Member>> => {
        const response = await proxyApiAxios.get<Page<Member>>('/members', {
            params: { page, size, sortField, sortOrder, hubId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.bim360CreatedAt = d.bim360CreatedAt && moment.utc(d.bim360CreatedAt).local();
            d.bim360UpdatedAt = d.bim360UpdatedAt && moment.utc(d.bim360UpdatedAt).local();
            d.bim360LastSignIn = d.bim360LastSignIn && moment.utc(d.bim360LastSignIn).local();
        });

        return response.data;
    };

    get = async (email: string, hubId: string): Promise<Member> => {
        const response = await proxyApiAxios.get<Member>(`/members/${email}`, {
            params: { hubId },
        });
        return response.data;
    };

    getCurrentMembers = async (): Promise<Member[]> => {
        const response = await proxyApiAxios.get<Member[]>('/members/current');

        return response.data;
    };

    exportSpreadSheet = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        hubId: string,
        searchText?: string,
    ) => {
        const response = await proxyApiAxios.get<Blob>(`/members/spreadsheets`, {
            responseType: 'blob',
            params: { page, size, sortField, sortOrder, hubId, searchText },
        });
        FileSaver.saveAs(response.data, 'link-members.xlsx');
    };

    import = async (members: Member[], hubId: string): Promise<EntityWithStatus<Member>[]> => {
        const response = await proxyApiAxios.post<EntityWithStatus<Member>[]>('/members/imports', members, {
            params: { hubId },
        });

        return response.data;
    };

    updateCompany = async (members: Member[], hubId: string): Promise<EntityWithStatus<Member>[]> => {
        const response = await proxyApiAxios.patch<EntityWithStatus<Member>[]>('/members', members, {
            params: { company: '', hubId },
        });

        return response.data;
    };

    updateStatus = async (members: Member[], hubId: string): Promise<EntityWithStatus<Member>[]> => {
        const response = await proxyApiAxios.patch<EntityWithStatus<Member>[]>('/members', members, {
            params: { status: '', hubId },
        });

        return response.data;
    };

    loadCsv = async (hubId: string, csv: string): Promise<Member[]> => {
        const response = await proxyApiAxios.post<Member[]>('/members/csvs', { hubId, csv });
        return response.data;
    };

    loadSpreadsheet = async (configuration: MemberConfiguration, file: any): Promise<Member[]> => {
        const formData: FormData = this.buildMemberConfigurationData(configuration, file);
        const response = await proxyApiAxios.post<any>('/members/spreadsheets', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    };

    /**
     * Builds a form data from a member configuration.
     * @param configuration the member configuration
     * @param file the file
     * @returns the form data
     */
    private buildMemberConfigurationData = (configuration: MemberConfiguration, file?: File) => {
        const formData: FormData = new FormData();
        const configurationJson = JSON.stringify(configuration);
        const configurationBlob = new Blob([configurationJson], { type: 'application/json' });
        formData.append('memberConfiguration', configurationBlob);
        file && formData.append('memberFile', file);

        return formData;
    };
}

const memberApi: MemberApi = new MemberApi();
export default memberApi;
