import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import CustomContext from '../../context/CustomContext';
import { Context } from '../../model/elements';
import { ApplicationType } from '../../model/types';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import HomePage from '../../pages/HomePage/HomePage';
import LoginErrorPage from '../../pages/LoginErrorPage/LoginErrorPage';
import LoginPage from '../../pages/LoginPage/LoginPage';
import SettingsPage from '../../pages/SettingsPage/SettingsPage';
import { default as SetupMembersImportPage } from '../../pages/Setup/MembersPage/ImportPage/ImportPage';
import { default as SetupMembersPage } from '../../pages/Setup/MembersPage/MembersPage';
import { default as SetupProjectIssuesImportPage } from '../../pages/Setup/ProjectIssuesPage/ImportPage/ImportPage';
import { default as SetupProjectIssuesPage } from '../../pages/Setup/ProjectIssuesPage/ProjectIssuesPage';
import { default as SetupProjectMembersImportPage } from '../../pages/Setup/ProjectMembersPage/ImportPage/ImportPage';
import { default as SetupProjectMembersPage } from '../../pages/Setup/ProjectMembersPage/ProjectMembersPage';
import { default as SetupProjectSubmissionPage } from '../../pages/Setup/ProjectSubmissionsPage/ProjectSubmissionPage/ProjectSubmissionPage';
import { default as SetupProjectSubmissionsPage } from '../../pages/Setup/ProjectSubmissionsPage/ProjectSubmissionsPage';
import { default as SetupProjectsImportPage } from '../../pages/Setup/ProjectsPage/ImportPage/SetupProjectsImportPage';
import { default as SetupProjectsPage } from '../../pages/Setup/ProjectsPage/ProjectsPage';
import { default as SyncConnectionImportPage } from '../../pages/Sync/ConnectionsPage/ConnectionPage/ConnectionLinksComponent/ImportPage/ImportPage';
import { default as SyncConnectionPage } from '../../pages/Sync/ConnectionsPage/ConnectionPage/ConnectionPage';
import { default as SyncConnectionsPage } from '../../pages/Sync/ConnectionsPage/ConnectionsPage';
import { default as SyncFilesPage } from '../../pages/Sync/FilesPage/FilesPage';
import TaskConversionProcessFilesPage from '../../pages/Tasks/TasksPage/TaskConversionProcessProjectPage/TaskConversionProcessFilesPage/TaskConversionProcessFilesPage';
import TaskConversionProcessIssueFilesPage from '../../pages/Tasks/TasksPage/TaskConversionProcessProjectPage/TaskConversionProcessIssuesPage/TaskConversionProcessIssueFilesPage/TaskConversionProcessIssueFilesPage';
import TaskConversionProcessIssuesPage from '../../pages/Tasks/TasksPage/TaskConversionProcessProjectPage/TaskConversionProcessIssuesPage/TaskConversionProcessIssuesPage';
import TaskConversionProcessProjectPage from '../../pages/Tasks/TasksPage/TaskConversionProcessProjectPage/TaskConversionProcessProjectPage';
import TaskCopyIssueFilePage from '../../pages/Tasks/TasksPage/TaskCopyIssueFilePage/TaskCopyIssueFilePage';
import TaskMemberPage from '../../pages/Tasks/TasksPage/TaskMemberPage/TaskMemberPage';
import TaskProjectPage from '../../pages/Tasks/TasksPage/TaskProjectPage/TaskProjectPage';
import TaskSmartDeliveryPage from '../../pages/Tasks/TasksPage/TaskSmartDeliveryPage/TaskSmartDeliveryPage';
import TaskSyncFilePage from '../../pages/Tasks/TasksPage/TaskSyncPage/TaskSyncFilePage/TaskSyncFilePage';
import TaskSyncFileTargetsPage from '../../pages/Tasks/TasksPage/TaskSyncPage/TaskSyncFileTargetsPage/TaskSyncFileTargetsPage';
import TaskSyncLinkPage from '../../pages/Tasks/TasksPage/TaskSyncPage/TaskSyncLinkPage/TaskSyncLinkPage';
import TaskSyncPage from '../../pages/Tasks/TasksPage/TaskSyncPage/TaskSyncPage';
import TasksPage from '../../pages/Tasks/TasksPage/TasksPage';
import TaskProjectIssueActionPage from '../../pages/Tasks/TasksPage/TasksProjectIssuesPage/TaskProjectIssueActionPage/TaskProjectIssueActionPage';
import TasksProjectIssuesPage from '../../pages/Tasks/TasksPage/TasksProjectIssuesPage/TasksProjectIssuesPage';
import ProtectedRoute, { ProtectedSetupRoute, ProtectedSyncRoute } from '../ProtectedRoute/ProtectedRoute';

/**
 * Returns the routes component.
 * @returns the routes component.
 */
const RoutesComponent = (): React.ReactElement => {
    /*** HOOKS ***/
    const location = useLocation();
    const context = useContext(CustomContext);

    /*** EFFECTS ***/

    useEffect(() => {
        if (location) {
            const application = getApplication(location.pathname, context);
            if (application !== context.application) {
                context.updateApplication(application);
            }
        }
    }, [context, location]);

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <Switch>
            <ProtectedRoute exact path="/" component={HomePage} />
            <ProtectedRoute exact path="/autodesk/oauth" component={HomePage} />

            <ProtectedSetupRoute exact path="/setup" component={SetupProjectMembersPage} />
            <ProtectedSetupRoute exact path="/setup/project-members" component={SetupProjectMembersPage} />
            <ProtectedSetupRoute
                exact
                path="/setup/project-members/import/:provider/:hubId"
                component={SetupProjectMembersImportPage}
            />
            <ProtectedRoute
                exact
                path="/setup/project-issues"
                authorities={['ROLE_ADMIN', 'ROLE_USER']}
                component={SetupProjectIssuesPage}
                type="all"
            />
            <ProtectedSetupRoute
                exact
                path="/setup/project-issues/import/:provider/:hubId"
                component={SetupProjectIssuesImportPage}
            />
            <ProtectedSetupRoute exact path="/setup/project-submissions" component={SetupProjectSubmissionsPage} />
            <ProtectedSetupRoute exact path="/setup/project-submissions/:id" component={SetupProjectSubmissionPage} />
            <ProtectedRoute
                exact
                path="/setup/members"
                component={SetupMembersPage}
                authorities={['ROLE_ADMIN', 'ROLE_USER']}
                type="all"
            />
            <ProtectedRoute
                exact
                path="/setup/members/import"
                component={SetupMembersImportPage}
                authorities={['ROLE_ADMIN', 'ROLE_USER']}
                type="all"
            />
            <ProtectedSetupRoute exact path="/setup/projects" component={SetupProjectsPage} />
            <ProtectedSetupRoute
                exact
                path="/setup/projects/import/:hubId/:platform"
                component={SetupProjectsImportPage}
            />
            <ProtectedSyncRoute exact path="/sync" component={SyncConnectionsPage} />
            <ProtectedSyncRoute exact path="/sync/connections" component={SyncConnectionsPage} />
            <ProtectedSyncRoute exact path="/sync/connections/:id" component={SyncConnectionPage} />
            <ProtectedSyncRoute exact path="/sync/connections/:id/import" component={SyncConnectionImportPage} />
            <ProtectedSyncRoute exact path="/sync/files" component={SyncFilesPage} />

            <ProtectedRoute exact path="/tasks" component={TasksPage} />
            <ProtectedRoute exact path="/task-syncs/:id" component={TaskSyncPage} />
            <ProtectedRoute exact path="/task-syncs/:id/task-sync-file-targets" component={TaskSyncFileTargetsPage} />
            <ProtectedRoute exact path="/task-sync-links/:id" component={TaskSyncLinkPage} />
            <ProtectedRoute exact path="/task-sync-files/:id" component={TaskSyncFilePage} />
            <ProtectedRoute exact path="/task-members/:id" component={TaskMemberPage} />
            <ProtectedRoute exact path="/task-projects/:id" component={TaskProjectPage} />
            <ProtectedRoute exact path="/task-project-issues/:id" component={TasksProjectIssuesPage} />
            <ProtectedRoute exact path="/task-project-issue-actions/:id" component={TaskProjectIssueActionPage} />
            <ProtectedRoute exact path="/task-smart-deliveries/:id" component={TaskSmartDeliveryPage} />
            <ProtectedRoute exact path="/task-conversion-projects/:id" component={TaskConversionProcessProjectPage} />
            <ProtectedRoute exact path="/task-conversion-files/:id" component={TaskConversionProcessFilesPage} />
            <ProtectedRoute exact path="/task-conversion-issues/:id" component={TaskConversionProcessIssuesPage} />
            <ProtectedRoute exact path="/task-copy-issue-files/:id" component={TaskCopyIssueFilePage} />
            <ProtectedRoute
                exact
                path="/task-conversion-issue-files/:id"
                component={TaskConversionProcessIssueFilesPage}
            />

            <ProtectedRoute exact path="/settings" component={SettingsPage} />

            <ProtectedRoute exact path="/login-error" component={LoginErrorPage} initialized={true} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/error" component={ErrorPage} />
            <Route component={ErrorPage} />
        </Switch>
    );
};

export default RoutesComponent;

/**
 * Returns the application based on the current path and .
 * @param pathname - the path name
 * @returns  the application
 */
const getApplication = (pathname: string, context: Context): ApplicationType | undefined => {
    if (pathname.startsWith('/sync')) {
        return 'SYNC';
    } else if (pathname.startsWith('/setup')) {
        return 'SETUP';
    } else if (pathname.startsWith('/task') || pathname.startsWith('/settings')) {
        return context.application;
    }
};
