import React from 'react';
import { Checkbox, Form, Input, Select } from 'antd';
import { Company, ProjectMember, Role } from '../../../../../../model/entities';
import { FormattedMessage } from 'react-intl';
import { projectMemberProducts } from '../../../../../../model/types';

const EditableCell: React.FC<Props> = ({
    editing,
    identifier,
    dataIndex,
    title,
    projectMember,
    index,
    children,
    companies,
    roles,
    ...restProps
}) => {
    return (
        <td {...restProps}>
            {editing ? getFormItem(identifier, dataIndex, projectMember, companies, roles) : children}
        </td>
    );
};
export default EditableCell;

const getFormItem = (
    identifier: string,
    dataIndex: any,
    projectMember: ProjectMember,
    companies: Company[],
    roles: Role[],
) => {
    switch (identifier) {
        case 'email':
            return (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        { required: true, message: <FormattedMessage id="status.mandatory" /> },
                        {
                            type: 'email',
                            message: <FormattedMessage id="status.email.invalid" />,
                        },
                    ]}
                >
                    <Input maxLength={200} disabled={projectMember && !!projectMember.id} />
                </Form.Item>
            );
        case 'company':
            return (
                <Form.Item name={dataIndex} style={{ margin: 0 }}>
                    <Select allowClear>{companyOptions(companies)}</Select>
                </Form.Item>
            );
        case 'roles':
            return (
                <Form.Item name={identifier} style={{ margin: 0 }}>
                    <Select mode="multiple" allowClear>
                        {roleOptions(roles)}
                    </Select>
                </Form.Item>
            );
        case 'products':
            return (
                <Form.Item name={identifier} style={{ margin: 0 }}>
                    <Select mode="multiple" allowClear>
                        {productOptions()}
                    </Select>
                </Form.Item>
            );
        case 'projectAdmin':
            return (
                <Form.Item name={dataIndex} style={{ margin: 0 }} valuePropName="checked" noStyle>
                    <Checkbox disabled={projectMember && !!projectMember.id} />
                </Form.Item>
            );
        default:
            break;
    }
};

const companyOptions = (companies: Company[]) =>
    companies.map((company) => (
        <Select.Option key={company.id!} value={company.id}>
            {company.name}
        </Select.Option>
    ));

const roleOptions = (roles: Role[]) =>
    roles.map((role) => (
        <Select.Option key={role.id!} value={role.id}>
            {role.name}
        </Select.Option>
    ));

const productOptions = () =>
    projectMemberProducts.map((product) => (
        <Select.Option key={product} value={product}>
            <FormattedMessage id={`projectMember.products.${product}`} />
        </Select.Option>
    ));

interface Props extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    identifier: string;
    dataIndex: string;
    title: any;
    projectMember: ProjectMember;
    index: number;
    children: React.ReactNode;
    companies: Company[];
    roles: Role[];
}
