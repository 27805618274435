import { Form, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Company } from '../../../../model/entities';
import stringService from '../../../../services/StringService';

/**
 * This component represents an update company modal.
 * @param props - the props
 */
export const UpdateCompanyModal: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    const companyOptions = props.companies.map((company) => (
        <Select.Option key={company.id} value={company.id}>
            {company.name}
        </Select.Option>
    ));
    const cancel = async () => {
        form.resetFields();
        props.onCancel();
    };
    const update = async () => {
        try {
            setSaving(true);
            const values = await form.validateFields();
            await props.onUpdate(values);
            form.resetFields();
        } finally {
            setSaving(false);
        }
    };

    return (
        <Modal
            title={<FormattedMessage id="projectMembers.updateCompany.modal.title" />}
            open={props.visible}
            onCancel={cancel}
            onOk={update}
            okButtonProps={{ loading: saving }}
            cancelButtonProps={{ disabled: saving }}
        >
            <Form form={form} colon={false} layout="vertical">
                <Form.Item
                    label={<FormattedMessage id="projectMembers.updateCompany.modal.description" />}
                    name="company"
                    rules={[{ required: props.mandatory, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Select size="large" allowClear showSearch filterOption={stringService.filterOptions}>
                        {companyOptions}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};
export default UpdateCompanyModal;

interface Props {
    visible?: boolean;
    onUpdate: (values: any) => Promise<void>;
    onCancel: () => void;
    companies: Company[];
    mandatory?: boolean;
}
