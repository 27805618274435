import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import queryString from 'query-string';
import { RouteComponentProps, useHistory } from 'react-router';
import { useIntl } from 'react-intl';
import { message } from 'antd';

import {
    Hub,
    ProjectImport,
    ProjectTemplate,
    EntityWithStatus,
    Project,
    EntityWithJob,
    Company,
} from '../../../../model/entities';
import { usePrincipalState } from '../../../../context/UserStateContext';
import { ProviderType } from '../../../../model/types';

import memberApi from '../../../../api/MemberApi';
import projectApi from '../../../../api/ProjectApi';
import projectMemberApi from '../../../../api/ProjectMemberApi';

export enum CurrentStep {
    UPLOAD = 0,
    SUMMARY = 1,
    RESULT = 2,
}

class SetupProjectsImport {
    platform: ProviderType | undefined;
    hub: Hub | undefined;
    currentStep: CurrentStep = CurrentStep.UPLOAD;
    projects: ProjectImport[] = [];
    templates: ProjectTemplate[] = [];
    isTemplatesLoading: boolean = false;
    setProjects: React.Dispatch<React.SetStateAction<ProjectImport[]>> = () => {};
    handleGoBack: () => void = () => {};
    selectedFile: File | undefined;
    setSelectedFile: React.Dispatch<React.SetStateAction<File | undefined>> = () => {};
    inputValue: string = '';
    setInputValue: React.Dispatch<React.SetStateAction<string>> = () => {};
    handleSubmitProjects: () => Promise<void> = async () => {};
    results: EntityWithStatus<EntityWithJob<Project>>[] = [];
    isResultsLoading: boolean = false;
    handleFinishStep: () => void = () => {};
}

export const SetupProjectsImportContext = createContext(new SetupProjectsImport());

export const SetupProjectsImportProvider: React.FC<
    RouteComponentProps<{ hubId: string; platform: ProviderType }> & { children: any }
> = ({ location, match, children }) => {
    const intl = useIntl();
    const history = useHistory();
    const parsed = queryString.parse(history.location.search);
    const { principal: user } = usePrincipalState();

    const [hub] = useState<Hub>({
        id: match.params.hubId,
        provider: `${parsed.hubProvider}`.match(/ACC/i) ? 'ACC' : 'BIM360',
        name: `${parsed.hubName}`,
    });
    const [platform, setPlatform] = useState<ProviderType | undefined>(hub?.provider);
    const [currentStep, setCurrentStep] = useState<CurrentStep>(CurrentStep.UPLOAD);
    const [projects, setProjects] = useState<ProjectImport[]>([]);
    const [templates, setTemplates] = useState<ProjectTemplate[]>([]);
    const [results, setResults] = useState<EntityWithStatus<EntityWithJob<Project>>[]>([]);
    const [company, setCompany] = useState<Company>();

    const [isTemplatesLoading, setIsTemplatesLoading] = useState(false);
    const [isResultsLoading, setIsResultsLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState<File>();
    const [inputValue, setInputValue] = useState('');

    const getInit = () => {
        setCurrentStep(CurrentStep.UPLOAD);
        setProjects([]);
    };

    const addMember = useCallback(
        async (projectId: string, platform?: ProviderType) => {
            await projectMemberApi.addMember(
                {
                    email: user?.email,
                    projectAdmin: true,
                    services: [{ name: 'projectAdministration', access: 'administrator' }],
                    siblings: [],
                    roles: [],
                    company,
                },
                `${hub?.id}`,
                projectId,
                platform || 'ACC',
            );
        },
        [user?.email, company, hub],
    );

    const getCompany = useCallback(async () => {
        if (user?.email && hub) {
            const result = await memberApi.get(user.email, hub.id);
            setCompany(result.company);
        }
    }, [user, hub]);

    const handleSubmitProjects = useCallback(async () => {
        if (!hub || !hub.id || !platform) return;
        try {
            setIsResultsLoading(true);
            const results = await projectApi.import(projects, hub?.id, platform);
            if (platform === 'ACC') {
                await Promise.all(
                    results
                        .filter((result) => result.status.type === 'OK' || result.status.type === 'ACCEPTED')
                        .map(async (result) => await addMember(result.entity.entity.id, hub.provider)),
                );
            }
            setResults(results);
        } catch (e) {
            message.error(intl.formatMessage({ id: 'status.internalError' }));
        } finally {
            setIsResultsLoading(false);
        }
    }, [projects, hub, intl, addMember, platform]);

    const handleFinishStep = () => {
        history.push(`/setup/projects?hubId=${hub?.id}&platform=${platform || hub?.provider}`);
    };

    const fetchTemplates = async (hubId: string, provider: ProviderType) => {
        return await projectApi.listTemplates(hubId, provider);
    };

    const handleSetTemplates = useCallback(
        async (hubId: string, platform: ProviderType) => {
            try {
                setIsTemplatesLoading(true);
                const fetchedTemplates = await fetchTemplates(hubId, platform);
                setTemplates(fetchedTemplates);
            } catch (e) {
                message.error(intl.formatMessage({ id: 'status.internalError' }));
            } finally {
                setIsTemplatesLoading(false);
            }
        },
        [intl],
    );

    // to initialize hub
    useEffect(() => {
        const init = async () => {
            if (match.params.platform) {
                setPlatform(match.params.platform);
                handleSetTemplates(match.params.hubId, match.params.platform);
                getCompany();
            }
        };

        init();
    }, [match.params.platform, match.params.hubId, handleSetTemplates, parsed.hubProvider, parsed.hubName, getCompany]);

    // to finish step 1
    useEffect(() => {
        if (projects.length > 0) {
            setCurrentStep(CurrentStep.SUMMARY);
        }
    }, [projects, platform]);

    // to finish step 2
    useEffect(() => {
        if (results.length && !isResultsLoading) {
            setCurrentStep(CurrentStep.RESULT);
        }
    }, [isResultsLoading, results]);

    const handleInit = () => {
        getInit();
        history.goBack();
    };

    const handleGoBack = () => {
        setCurrentStep((step) => step - 1);
    };

    const value = {
        hub,
        platform,
        currentStep,
        projects,
        setProjects,
        templates,
        isTemplatesLoading,
        handleGoBack: () => {
            if (currentStep === CurrentStep.UPLOAD) {
                handleInit();
            } else {
                handleGoBack();
            }
        },
        inputValue,
        setInputValue,
        selectedFile,
        setSelectedFile,
        handleSubmitProjects,
        results,
        isResultsLoading,
        handleFinishStep,
    };
    return <SetupProjectsImportContext.Provider value={value}>{children}</SetupProjectsImportContext.Provider>;
};

export const useSetupProjectsImport = () => {
    const context = useContext(SetupProjectsImportContext);
    return context;
};
