import Icon, { CopyOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import connectionLinkApi from '../../../../../api/ConnectionLinkApi';
import { Connection, ConnectionLink, ConnectionLinkSource } from '../../../../../model/entities';
import { StatusType } from '../../../../../model/types';
import { ReactComponent as AddSvg } from '../../../../../resources/images/add.svg';
import { ReactComponent as TrashCanSvg } from '../../../../../resources/images/trash-can.svg';
import notificationService from '../../../../../services/NotificationService';
import stringService from '../../../../../services/StringService';
import tableService from '../../../../../services/TableService';
import ConnectionLinkModal from './ConnectionLinkModal/ConnectionLinkModal';
import styles from './ConnectionLinksComponent.module.scss';

/**
 * Returns the connection links component.
 * @props the props
 * @returns the connection links component.
 */
const ConnectionLinksComponent = (props: Props): React.ReactElement => {
    const { connection } = props;
    /*** HOOKS ***/

    const intl = useIntl();
    const [connectionLinks, setConnectionLinks] = useState<ConnectionLink[]>([]);
    const [connectionLink, setConnectionLink] = useState<ConnectionLink>();
    const [connectionLinkModalVisible, setConnectionLinkModalVisible] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>();
    const [deleting, setDeleting] = useState<number>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);
                const connectionLinks = await connectionLinkApi.list(connection.id!);

                setConnectionLinks(connectionLinks);
                setConnectionLinkModalVisible(false);
                setConnectionLink(undefined);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [connection.id, intl]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading(true);
            const connectionLinks = await connectionLinkApi.list(connection.id!);

            setConnectionLinks(connectionLinks);
            setConnectionLinkModalVisible(false);
            setConnectionLink(undefined);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const showConnectionLinkModal = (connectionLinkModalVisible: boolean, connectionLink?: ConnectionLink) => {
        setConnectionLinkModalVisible(connectionLinkModalVisible);
        setConnectionLink(connectionLink);
    };

    const remove = async (connectionLink: ConnectionLink) => {
        try {
            setDeleting(connectionLink.id);
            await connectionLinkApi.delete(connectionLink);
            list();
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setDeleting(undefined);
        }
    };

    /*** COMPONENTS ***/

    const columns: ColumnsType<ConnectionLink> = [
        {
            title: <FormattedMessage id="connectionLink.source" />,
            dataIndex: ['source'],
            key: 'source',
            defaultSortOrder: 'ascend',
            sorter: (a, b) =>
                stringService.sort(
                    `${a.source?.project?.name} > ${a.source?.folder?.name}`,
                    `${b.source?.project?.name} > ${b.source?.folder?.name}`,
                ),
            render: (source: ConnectionLinkSource, connectionLink: ConnectionLink) => (
                <>
                    <span className="link" onClick={() => showConnectionLinkModal(true, connectionLink)}>
                        {source?.project?.name} &gt; {source?.folder?.name}
                    </span>
                    {source && source.folder && source.folder.name && (
                        <CopyToClipboard text={source.folder.name}>
                            <Tooltip title={<FormattedMessage id="connectionLink.source.folder.copy" />}>
                                <Button type="ghost" size="small" icon={<CopyOutlined />} className={styles.copy} />
                            </Tooltip>
                        </CopyToClipboard>
                    )}
                </>
            ),
        },
        {
            title: <FormattedMessage id="connectionLink.status" />,
            dataIndex: 'status',
            key: 'status',
            width: 100,
            align: 'center',
            sorter: (a, b) =>
                stringService.sort(intl.formatMessage({ id: a.status }), intl.formatMessage({ id: b.status })),
            render: (status: StatusType, connectionLink: ConnectionLink) => (
                <span className="link" onClick={() => showConnectionLinkModal(true, connectionLink)}>
                    <FormattedMessage id={status} />
                </span>
            ),
        },
        {
            dataIndex: 'id',
            key: 'action',
            align: 'center',
            width: '100px',
            render: (id: number, connectionLink: ConnectionLink) => (
                <Popconfirm
                    title={<FormattedMessage id="connectionLink.delete" />}
                    onConfirm={() => remove(connectionLink)}
                    okText={<FormattedMessage id="button.yes" />}
                    cancelText={<FormattedMessage id="button.no" />}
                    placement="left"
                >
                    <Button type="ghost" icon={<Icon component={TrashCanSvg} />} loading={deleting === id} />
                </Popconfirm>
            ),
        },
    ];

    return (
        <>
            <div className={`toolbar ${styles.tab}`}>
                <Tooltip title={<FormattedMessage id="connectionLinks.new.import.excel.title" />}>
                    <Link to={`/sync/connections/${connection.id}/import`}>
                        <Button type="primary" ghost={true} size="large" icon={<Icon component={AddSvg} />}>
                            <FormattedMessage id="connectionLinks.new.import.excel.title" />
                        </Button>
                    </Link>
                </Tooltip>
                <Tooltip title={<FormattedMessage id="connectionLinks.new.tooltip" />}>
                    <Button
                        type="primary"
                        size="large"
                        icon={<Icon component={AddSvg} />}
                        onClick={() => showConnectionLinkModal(true)}
                    ></Button>
                </Tooltip>
            </div>
            <Table
                dataSource={connectionLinks}
                columns={columns}
                pagination={tableService.createMemoryPagination(connectionLinks, ['bottomRight'])}
                rowKey="id"
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
            {connectionLinkModalVisible && (
                <ConnectionLinkModal
                    connection={connection}
                    connectionLinkId={connectionLink?.id}
                    visible={connectionLinkModalVisible}
                    onSave={list}
                    onCancel={() => showConnectionLinkModal(false)}
                />
            )}
        </>
    );
};

export default ConnectionLinksComponent;

interface Props {
    connection: Connection;
}
