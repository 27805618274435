import jwt_decode from 'jwt-decode';
import autodeskApi from '../api/AutodeskApi';
import { message } from 'antd';
import configuration from '../configs/IConfiguration';
import userApi from '../api/UserApi';
import Cookies from 'js-cookie';
import { AutodeskOauthResponse } from '../model/entities';
import * as CryptoJS from 'crypto-js';

class AutodeskService {
    readonly clientId = configuration.getAutodeskClientId();
    readonly scope = 'user:read account:read account:write data:read data:write data:search';
    readonly urlStart = window.location.protocol + '//' + window.location.host;
    readonly redirectUrl = `${this.urlStart}/autodesk/oauth`;
    readonly autodeskCodeVerifier = 'autodeskCodeVerifier';
    readonly autodeskAccessTokenText = 'autodeskAccessToken';
    readonly autodeskAccessRefreshTokenText = 'autodeskAccessRefreshToken';

    /**
     * Initializes the autodesk access token.
     */
    init = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;
        try {
            // get autodesk authorization code (only when it comes from an autodesk authorization redirection)
            const authorizationCode = await this.getAuthorizationCode();
            accessToken = this.getCachedAccessToken();

            // if authorization code is received, retrieve access and refresh tokens from autodesk (store in cookies)
            if (authorizationCode) {
                accessToken = await this.loadAccessTokenFromAuthorizationCode(authorizationCode);
            }

            // if access token exists but it's not valid, try to refresh it
            if (accessToken && !this.isAccessTokenValid(accessToken)) {
                accessToken = await this.refreshAccessToken();
            }
            // if access token doesn't exist redirect to autodesk authorization page
            else if (!accessToken) {
                this.login();
            }
            // otherwise the access token is valid
        } catch (error: any) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.error === 'invalid_grant' &&
                error.response.data.error_description === 'The refresh token is invalid or expired.'
            ) {
                this.login();
            } else if (
                error.response &&
                error.response.data &&
                error.response.data.error === 'invalid_credentials' &&
                error.response.data.error_description === 'The client credentials are invalid.'
            ) {
                this.login();
            } else {
                this.removeAccessToken();
                this.removeRefreshAccessToken();
                message.error('Internal error');
            }
        }

        return accessToken;
    };

    /**
     * Returns the autodesk access token (from cache or via refresh access token, otherwise redirects to login)
     * @returns autodesk access token
     */
    getAccessToken = async (): Promise<string | undefined> => {
        let accessToken: string | undefined;
        try {
            accessToken = this.getCachedAccessToken();

            if (accessToken && !this.isAccessTokenValid(accessToken)) {
                accessToken = await this.refreshAccessToken();
            }
            // if access token doesn't exist redirect to autodesk authorization page
            else if (!accessToken) {
                this.login();
            }
            // otherwise the access token is valid
        } catch (error: any) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.error === 'invalid_grant' &&
                error.response.data.error_description === 'The refresh token is invalid or expired.'
            ) {
                this.login();
            } else if (
                error.response &&
                error.response.data &&
                error.response.data.error === 'invalid_credentials' &&
                error.response.data.error_description === 'The client credentials are invalid.'
            ) {
                this.login();
            } else {
                accessToken = undefined;
                this.removeAccessToken();
                this.removeRefreshAccessToken();
                message.error('Internal error');
            }
        }

        return accessToken;
    };

    logOut = async () => {
        this.removeAccessToken();
        this.removeRefreshAccessToken();
        sessionStorage.removeItem(this.autodeskCodeVerifier);
    };

    /**
     * Returns the autodesk access token.
     * @returns the autodesk access token
     */
    getCachedAccessToken = (): string | undefined => {
        return Cookies.get(this.getCookieFullname(this.autodeskAccessTokenText)) || undefined;
    };

    /**
     * Returns the autodesk refresh access token.
     * @returns the autodesk refresh access token
     */
    getCachedRefreshAccessToken = (): string | undefined => {
        return Cookies.get(this.getCookieFullname(this.autodeskAccessRefreshTokenText)) || undefined;
    };

    /**
     * Returns a cookie full name with the domain string of the current configuration
     * @param name cookie name initial text
     * @returns the cookie full name
     */
    getCookieFullname = (name: string): string => {
        return name + configuration.getCookiesSubfix();
    };

    private login = () => {
        this.removeAccessToken();
        this.removeRefreshAccessToken();

        // redirect to autodeks authorization page
        const characterArray = CryptoJS.lib.WordArray.random(34).toString();

        // we generate the code challenge for PKCE auth
        const str = CryptoJS.enc.Utf8.parse(characterArray);
        const codeVerifier = CryptoJS.enc.Base64.stringify(str)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');
        const hash = CryptoJS.SHA256(codeVerifier);
        const codeChallenge = CryptoJS.enc.Base64.stringify(hash)
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=/g, '');

        // when we have all the data to generate the url, we generate it
        const url = new URL('https://developer.api.autodesk.com/authentication/v2/authorize');
        url.searchParams.set('response_type', 'code');
        url.searchParams.set('client_id', autodeskService.clientId!);
        url.searchParams.set('redirect_uri', autodeskService.redirectUrl);
        url.searchParams.set('prompt', 'login');
        url.searchParams.set('scope', autodeskService.scope);
        url.searchParams.set('code_challenge', codeChallenge);
        url.searchParams.set('code_challenge_method', 'S256');

        // store code verifier in local storage
        sessionStorage.setItem(autodeskService.autodeskCodeVerifier, codeVerifier);

        window.location.href = url.toString();
    };

    private getAuthorizationCode = async () => {
        let authorizationCode: string | undefined;
        try {
            const urlObj = new URL(window.location.href);
            if (
                window.location.pathname === '/autodesk/oauth' &&
                urlObj.searchParams.has('code') &&
                autodeskService.clientId
            ) {
                const urlParams = new URLSearchParams(window.location.search);
                authorizationCode = urlParams.get('code') as string;
            }
        } catch (error) {
            console.log(error);
        }

        return authorizationCode;
    };

    private loadAccessTokenFromAuthorizationCode = async (authorizationCode: string): Promise<string | undefined> => {
        let accessToken: string | undefined;
        try {
            const codeVerifier = sessionStorage.getItem(this.autodeskCodeVerifier);
            if (authorizationCode && codeVerifier && this.clientId) {
                const autodeskOauthResponse = await autodeskApi.getToken(
                    authorizationCode,
                    this.clientId,
                    this.redirectUrl,
                    codeVerifier!,
                );
                accessToken = autodeskOauthResponse.access_token;

                // store autodesk access and refresh tokens
                this.storeAccessToken(autodeskOauthResponse);
                this.storeRefreshAccessToken(autodeskOauthResponse);

                // remove code verifier from session storage
                sessionStorage.removeItem(this.autodeskCodeVerifier);

                // send refresh token to api
                await userApi.createSystemUserBim360AccessToken(autodeskOauthResponse.refresh_token);
            }
        } catch (error) {
            console.log(error);
        }

        return accessToken;
    };

    private isAccessTokenValid = (accessToken: string | undefined): boolean => {
        let isValid: boolean = false;
        if (accessToken) {
            const tokenDecoded: any = jwt_decode(accessToken);
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);
            isValid = currentTimeInSeconds < tokenDecoded.exp - 300;
        }

        return isValid;
    };

    private refreshAccessToken = async (): Promise<string | undefined> => {
        const refreshToken = this.getCachedRefreshAccessToken();
        const autodeskOauthResponse = await autodeskApi.refreshToken(this.clientId, refreshToken!, this.scope);

        // store access and refresh tokens in cookies
        this.storeAccessToken(autodeskOauthResponse);
        this.storeRefreshAccessToken(autodeskOauthResponse);

        // send refresh token to api
        await userApi.createSystemUserBim360AccessToken(autodeskOauthResponse.refresh_token);

        return autodeskOauthResponse.access_token;
    };

    /**
     * Stores the access token in a cookie.
     * @param autodeskOauthResponse the autodesk oauth response
     */
    private storeAccessToken = (autodeskOauthResponse: AutodeskOauthResponse) => {
        Cookies.set(this.getCookieFullname(this.autodeskAccessTokenText), autodeskOauthResponse.access_token, {
            domain: configuration.getParentDomain(),
            secure: window.location.protocol === 'https:',
        });
    };

    /**
     * Stores the refresh token in a cookie.
     * @param autodeskOauthResponse the autodesk oauth response
     */
    private storeRefreshAccessToken = (autodeskOauthResponse: AutodeskOauthResponse) => {
        Cookies.set(this.getCookieFullname(this.autodeskAccessRefreshTokenText), autodeskOauthResponse.refresh_token, {
            domain: configuration.getParentDomain(),
            secure: window.location.protocol === 'https:',
        });
    };

    /**
     * Removes the access token cookie.
     */
    private removeAccessToken = () => {
        Cookies.remove(this.getCookieFullname(this.autodeskAccessTokenText));
    };

    /**
     * Removes the refresh tokencookie.
     */
    private removeRefreshAccessToken = () => {
        Cookies.remove(this.getCookieFullname(this.autodeskAccessRefreshTokenText));
    };
}

const autodeskService: AutodeskService = new AutodeskService();
export default autodeskService;
