import moment from 'moment';
import { TaskMember } from '../model/entities';
import adminApiAxios from './CustomAxios';

class TaskMemberApi {
    get = async (id: number): Promise<TaskMember> => {
        const response = await adminApiAxios.get<TaskMember>(`/tasks/members/${id}`);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };
}

const taskMemberApi: TaskMemberApi = new TaskMemberApi();
export default taskMemberApi;
