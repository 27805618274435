import queryString from 'query-string';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';

import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import { UserStateProvider } from '../../../../context/UserStateContext';
import { ProviderType } from '../../../../model/types';
import ImportProjectsStep from './ImportProjectsStep';
import { SetupProjectsImportProvider } from './SetupProjectsImportContext';

import styles from './ImportPage.module.scss';

const SetupProjectsImportPage: React.FC<RouteComponentProps<{ hubId: string; platform: ProviderType }>> = (props) => {
    const parsed = queryString.parse(props.location.search);

    return (
        <UserStateProvider>
            <SetupProjectsImportProvider {...props}>
                <LayoutComponent pageId="projects">
                    <>
                        <h1 className={styles.title}>
                            <FormattedMessage id="projects.new.import.title" />
                        </h1>
                        <h4 className={styles.hubs}>
                            <p>Hub: {parsed.hubName}</p>
                            {props.match.params.platform && <p>Platform: {props.match.params.platform}</p>}
                        </h4>
                    </>
                    <ImportProjectsStep />
                </LayoutComponent>
            </SetupProjectsImportProvider>
        </UserStateProvider>
    );
};

export default SetupProjectsImportPage;
