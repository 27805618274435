import Icon, { FileExcelOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Select, Space, Tag, Tooltip, message } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Key, TableRowSelection } from 'antd/lib/table/interface';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import companyApi from '../../../api/CompanyApi';
import hubApi from '../../../api/HubApi';
import projectApi from '../../../api/ProjectApi';
import projectMemberApi from '../../../api/ProjectMemberApi';
import roleApi from '../../../api/RoleApi';
import userApi from '../../../api/UserApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import SelectTableComponent from '../../../components/SelectTableComponent/SelectTableComponent';
import CustomContext from '../../../context/CustomContext';
import { Page } from '../../../model/elements';
import { Company, Hub, Project, ProjectMember, Role, Service } from '../../../model/entities';
import { ServiceType } from '../../../model/types';
import { ReactComponent as CaretDownSvg } from '../../../resources/images/caret-down.svg';
import { ReactComponent as CloudUploadSvg } from '../../../resources/images/cloud-upload.svg';
import { ReactComponent as EditSvg } from '../../../resources/images/edit.svg';
import notificationService from '../../../services/NotificationService';
import rolesService from '../../../services/RolesService';
import stringService from '../../../services/StringService';
import tableService from '../../../services/TableService';
import styles from './ProjectMembersPage.module.scss';
import UpdateCompanyModal from './UpdateCompanyModal/UpdateCompanyModal';
import UpdateRolesModal from './UpdateRolesModal/UpdateRolesModal';

/**
 * Returns the project members page.
 * @returns the project members page.
 */
const ProjectMembersPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const context = useContext(CustomContext);
    const { auth } = context;
    const intl = useIntl();
    const [hubs, setHubs] = useState<Hub[]>([]);
    const [projects, setProjects] = useState<Project[]>([]);
    const [hub, setHub] = useState<Hub>();
    const [searchText, setSearchText] = useState<string>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
    const [selectedProjectIds, setSelectedProjectIds] = useState<string[]>([]);
    const [projectMembersPage, setProjectMembersPage] = useState<Page<ProjectMember>>();
    const [display, setDisplay] = useState<DisplayType>('grouped');
    const [loading, setLoading] = useState<boolean>();
    const [exporting, setExporting] = useState<boolean>();
    const [selectedProjectMembers, setSelectedProjectMembers] = useState<ProjectMember[]>([]);
    const [companyModalVisible, setCompanyModalVisible] = useState<boolean>();
    const [rolesModalVisible, setRolesModalVisible] = useState<boolean>();
    const [roles, setRoles] = useState<Role[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);
                const hubs = await hubApi.list(true);
                setHubs(hubs);

                // select hub and project
                if (window.location.search.includes('hubId')) {
                    const params = new URLSearchParams(window.location.search);
                    const hubId = params.get('hubId');
                    const hub = hubs.find((p) => p.id === hubId);
                    setHub(hub);

                    if (hub) {
                        const responses = await Promise.all([
                            projectApi.list(hub.provider, hub.id),
                            companyApi.list(hub.id),
                        ]);
                        const projects = responses[0];
                        const companies = responses[1];
                        setProjects(projects);
                        setCompanies(companies);
                        if (window.location.search.includes('projectId')) {
                            const projectIds = params.getAll('projectId');
                            setSelectedProjectIds(projectIds);
                            // list project members
                            const page = 0;
                            const size = projectMembersPage?.size || tableService.pageSize;
                            const sortField = projectMembersPage?.sort.field || 'lastName';
                            const sortOrder = projectMembersPage?.sort.order || true;

                            const projectMembersPageUpdated =
                                projectIds.length > 0
                                    ? await projectMemberApi.list(
                                          page,
                                          size,
                                          sortField,
                                          sortOrder,
                                          display === 'grouped',
                                          hub.id,
                                          projectIds,
                                          searchText,
                                          projects.filter(
                                              (p) => /ACC/.test(`${p.platform}`) && projectIds.includes(p.id),
                                          ).length > 0
                                              ? 'ACC'
                                              : 'BIM360',
                                      )
                                    : undefined;
                            setProjectMembersPage(projectMembersPageUpdated);

                            // refresh roles
                            if (hub && projectIds.length > 0) {
                                const roles = (await roleApi.list(hub!.id, projectIds[0])) || [];
                                setRoles(roles);
                            }

                            const url = '/setup/project-members';
                            window.history.replaceState({ path: url }, '', url);
                        }
                    }
                } else if (hubs.length > 0 && !hub) {
                    const hub = hubs[0];
                    const responses = await Promise.all([
                        projectApi.list(hub.provider, hub.id),
                        companyApi.list(hub.id),
                        userApi.getPrincipal(),
                    ]);
                    const projects = responses[0];
                    const companies = responses[1];

                    setHub(hub);
                    setProjects(projects);
                    setCompanies(companies);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [
        display,
        intl,
        projectMembersPage?.size,
        projectMembersPage?.sort.field,
        projectMembersPage?.sort.order,
        searchText,
        hub,
    ]);

    /*** METHODS ***/

    const list = async (selectedProjectIds: string[], display: DisplayType) => {
        // list project members
        const page = 0;
        const size = projectMembersPage?.size || tableService.pageSize;
        const sortField = projectMembersPage?.sort.field || 'lastName';
        const sortOrder = projectMembersPage?.sort.order || true;
        await listProjectMembers(page, size, sortField, sortOrder, display, hub!.id, selectedProjectIds, searchText);

        // refresh roles
        if (hub && selectedProjectIds.length > 0) {
            const roles = (await roleApi.list(hub!.id, selectedProjectIds[0])) || [];
            setRoles(roles);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);
            setSearchText(searchText);
            const page = 0;
            const size = projectMembersPage!.size;
            const sortField = projectMembersPage!.sort.field!;
            const sortOrder = projectMembersPage!.sort.order!;
            await listProjectMembers(
                page,
                size,
                sortField,
                sortOrder,
                display,
                hub!.id,
                selectedProjectIds,
                searchText,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            if (selectedProjectIds.length > 0) {
                const page = pagination.current! - 1;
                const size = pagination.pageSize!;
                const sortField = sorter.field;
                const sortOrder = sorter.order === 'ascend';
                await listProjectMembers(
                    page,
                    size,
                    sortField,
                    sortOrder,
                    display,
                    hub!.id,
                    selectedProjectIds,
                    searchText,
                );
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listProjectMembers = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        display: DisplayType,
        hubId: string,
        selectedProjectIds: string[],
        searchText?: string,
    ) => {
        const projectMembersPage =
            selectedProjectIds.length > 0
                ? await projectMemberApi.list(
                      page,
                      size,
                      sortField,
                      sortOrder,
                      display === 'grouped',
                      hubId,
                      selectedProjectIds,
                      searchText,
                      projects.filter((p) => /ACC/.test(`${p.platform}`) && selectedProjectIds.includes(p.id)).length >
                          0
                          ? 'ACC'
                          : 'BIM360',
                  )
                : undefined;
        setProjectMembersPage(projectMembersPage);
    };

    const exportSpreadSheet = async () => {
        try {
            setExporting(true);
            const sortField = projectMembersPage!.sort.field!;
            const sortOrder = projectMembersPage!.sort.order!;
            await projectMemberApi.exportSpreadSheet(
                sortField,
                sortOrder,
                hub!.id,
                selectedProjectIds,
                display === 'grouped',
                searchText,
                projects.filter((p) => /ACC/.test(`${p.platform}`) && selectedProjectIds.includes(p.id)).length > 0
                    ? 'ACC'
                    : 'BIM360',
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setExporting(false);
        }
    };

    const listProjects = async (hubId: string | undefined) => {
        try {
            setLoading(true);
            const hub = hubs.find((p) => p.id === hubId);
            let projects: Project[] = [];
            let companies: Company[] = [];
            if (hub) {
                const responses = await Promise.all([projectApi.list(hub.provider, hub.id), companyApi.list(hub.id)]);
                projects = responses[0];
                companies = responses[1];
            }
            const selectedProjectIds: string[] = [];
            const projectMembersPage = undefined;

            setHub(hub);
            setProjects(projects);
            setCompanies(companies);
            setSelectedProjectIds(selectedProjectIds);
            setProjectMembersPage(projectMembersPage);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const changeProjects = async (selectedProjectIds: string[]) => {
        try {
            setLoading(true);
            setSelectedProjectIds(selectedProjectIds);
            unselectAll();
            await list(selectedProjectIds, display);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const changeDisplay = async (display: DisplayType) => {
        try {
            setLoading(true);
            setDisplay(display);

            await list(selectedProjectIds, display);
            unselectAll();
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const select = (selectedRowKeys: Key[], selectedRows: ProjectMember[]) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedProjectMembers(selectedRows);
    };

    const unselectAll = () => {
        setSelectedRowKeys([]);
        setSelectedProjectMembers([]);
    };

    const isUpdateCompaniesButtonDisabled = (): boolean => {
        return selectedProjectMembers.length === 0;
    };

    const isUpdateRolesButtonDisabled = (): boolean => {
        return selectedProjectMembers.length === 0;
    };

    const updateCompany = async (values: any) => {
        try {
            // assign company and load project data
            let selectedCompany = companies.find((c) => c.id === values.company);
            const projectMembers = [...selectedProjectMembers].concat(
                selectedProjectMembers.flatMap((pm) => pm.siblings),
            );
            projectMembers.forEach((c) => (c.company = selectedCompany));
            projectMembers.forEach((pm) => loadProject(pm, projects));

            // update company
            const projectMembersWithStatus = await projectMemberApi.updateCompany(projectMembers, hub!.id);
            unselectAll();
            setCompanyModalVisible(false);

            // display message
            if (projectMembersWithStatus.every((m) => m.status.type === 'OK')) {
                message.success(intl.formatMessage({ id: 'status.saved' }));
            } else {
                message.warning(intl.formatMessage({ id: 'status.savedWithErrors' }), 5);
            }

            // refresh project members
            setLoading(true);
            const page = 0;
            const size = projectMembersPage?.size || tableService.pageSize;
            const sortField = projectMembersPage!.sort.field!;
            const sortOrder = projectMembersPage!.sort.order!;
            await listProjectMembers(
                page,
                size,
                sortField,
                sortOrder,
                display,
                hub!.id,
                selectedProjectIds,
                searchText,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const updateRoles = async (values: any) => {
        try {
            // assign roles and load project data
            let selectedRoles: Role[] = values.roles ? roles.filter((r) => values.roles.includes(r.id)) : [];
            const projectMembers = [...selectedProjectMembers].concat(
                selectedProjectMembers.flatMap((pm) => pm.siblings),
            );
            projectMembers.forEach((pm) => (pm.roles = selectedRoles));
            projectMembers.forEach((pm) => loadProject(pm, projects));

            // update roles
            const projectMembersWithStatus = await projectMemberApi.updateRoles(projectMembers, hub!.id);
            unselectAll();
            setRolesModalVisible(false);

            // display message
            if (projectMembersWithStatus.every((m) => m.status.type === 'OK')) {
                message.success(intl.formatMessage({ id: 'status.saved' }));
            } else {
                message.warning(intl.formatMessage({ id: 'status.savedWithErrors' }), 5);
            }

            // refresh project members
            setLoading(true);
            const page = 0;
            const size = projectMembersPage?.size || tableService.pageSize;
            const sortField = projectMembersPage!.sort.field!;
            const sortOrder = projectMembersPage!.sort.order!;
            await listProjectMembers(
                page,
                size,
                sortField,
                sortOrder,
                display,
                hub!.id,
                selectedProjectIds,
                searchText,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    /**
     * Loads the project data (name) of a project member.
     * @param projectMember - the project member
     * @param projects - the projects
     */
    const loadProject = (projectMember: ProjectMember, projects: Project[]) => {
        const project = projects.find((p) => projectMember.project && p.id === projectMember.project.id);
        if (projectMember.project && project) {
            projectMember.project.name = project.name;
            projectMember.project.platform = project.platform;
        }
    };

    /*** COMPONENTS ***/

    const hubOptions = hubs.map((hub) => (
        <Select.Option key={hub.id} value={hub.id}>
            {`${hub.provider} > ${hub.name}`}
        </Select.Option>
    ));

    const projectOptions = projects
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((project) => (
            <Select.Option key={project.id} value={project.id}>
                {project.name}
                {project.platform && (
                    <>
                        {' '}
                        <Tag>{project.platform}</Tag>
                    </>
                )}
            </Select.Option>
        ));

    const placeholder: string = intl.formatMessage({ id: 'projectMembers.search' });
    const disabled = selectedProjectIds.length === 0;
    const projectIdsAsParam = selectedProjectIds.map((id) => 'projectId=' + id).join('&');

    const items = projectMembersPage ? projectMembersPage.content : [];
    const columns: ColumnsType<ProjectMember> = [
        {
            title: <FormattedMessage id="projectMember.lastName" />,
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: true,
            defaultSortOrder: 'ascend',
        },
        {
            title: <FormattedMessage id="projectMember.firstName" />,
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: true,
        },
        {
            title: <FormattedMessage id="projectMember.email" />,
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: <FormattedMessage id="projectMember.company" />,
            dataIndex: 'company',
            key: 'company',
            sorter: true,
            width: 180,
            render: (value, item) => getCompanies(item),
        },
        {
            title: <FormattedMessage id="projectMember.roles" />,
            dataIndex: 'roles',
            key: 'roles',
            sorter: true,
            width: 180,
            render: (value, item) => getRoles(item),
        },
        {
            title: <FormattedMessage id="projectMember.services.projectAdministration" />,
            dataIndex: 'services.projectAdministration',
            key: 'services.projectAdministration',
            align: 'center',
            sorter: true,
            width: 160,
            render: (services, item) => getServiceValue(item.services, 'projectAdministration'),
        },
        {
            title: <FormattedMessage id="projectMember.services.documentManagement" />,
            dataIndex: 'services.documentManagement',
            key: 'services.documentManagement',
            align: 'center',
            sorter: true,
            width: 50,
            render: (services, item) => getServiceValue(item.services, 'documentManagement'),
        },
        {
            title: <FormattedMessage id="projectMember.services.projectManagement" />,
            dataIndex: 'services.projectManagement',
            key: 'services.projectManagement',
            align: 'center',
            sorter: true,
            width: 50,
            render: (services, item) => getServiceValue(item.services, 'projectManagement'),
        },
        {
            title: <FormattedMessage id="projectMember.services.modelCoordination" />,
            dataIndex: 'services.modelCoordination',
            key: 'services.modelCoordination',
            align: 'center',
            sorter: true,
            width: 50,
            render: (services, item) => getServiceValue(item.services, 'modelCoordination'),
        },
        {
            title: <FormattedMessage id="projectMember.services.fieldManagement" />,
            dataIndex: 'services.fieldManagement',
            key: 'services.fieldManagement',
            align: 'center',
            sorter: true,
            width: 50,
            render: (services, item) => getServiceValue(item.services, 'fieldManagement'),
        },
    ];

    // add project column if display all
    if (display === 'all') {
        columns.unshift({
            title: <FormattedMessage id="projectMember.project" />,
            dataIndex: 'project',
            key: 'project',
            sorter: true,
            defaultSortOrder: 'ascend',
            width: 180,
            render: (project) => project.name,
        });
    }

    let rowSelection: TableRowSelection<ProjectMember> | undefined;
    rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys,
        onChange: select,
    };

    return (
        <LayoutComponent pageId="projectMembers">
            <div className={styles.selector}>
                <label>
                    <FormattedMessage id="projectMembers.hub" />
                </label>
                <Select
                    size="large"
                    allowClear
                    className={styles.hubs}
                    value={hub?.id}
                    onChange={listProjects}
                    placeholder={<FormattedMessage id="projectMembers.hub.placeholder" />}
                >
                    {hubOptions}
                </Select>
                <label>
                    <FormattedMessage id="projectMembers.projects" />
                </label>
                <Select
                    size="large"
                    allowClear
                    className={styles.projects}
                    value={selectedProjectIds}
                    onChange={changeProjects}
                    placeholder={<FormattedMessage id="projectMembers.projects.placeholder" />}
                    showSearch
                    filterOption={stringService.filterOptions}
                    mode={'multiple'}
                    maxTagCount={3}
                >
                    {projectOptions}
                </Select>
                <label>
                    <FormattedMessage id="projectMembers.display" />
                </label>
                <Select size="large" allowClear className={styles.display} value={display} onChange={changeDisplay}>
                    <Select.Option key="grouped" value="grouped">
                        <FormattedMessage id="projectMembers.display.grouped" />
                    </Select.Option>
                    <Select.Option key="all" value="all">
                        <FormattedMessage id="projectMembers.display.all" />
                    </Select.Option>
                </Select>
            </div>
            <Divider />
            <div className="toolbar">
                <Search
                    placeholder={placeholder}
                    onSearch={search}
                    size="large"
                    className="search"
                    disabled={disabled}
                />
                <Space>
                    <Tooltip title={<FormattedMessage id="projectMembers.exportSpreadSheet" />}>
                        <Button
                            size="large"
                            icon={<FileExcelOutlined />}
                            onClick={exportSpreadSheet}
                            loading={exporting}
                            disabled={disabled}
                        ></Button>
                    </Tooltip>
                    <Link
                        to={`/setup/project-members/import/${hub?.provider}/${hub?.id}?${projectIdsAsParam}`}
                        style={{ pointerEvents: disabled ? 'none' : undefined }}
                    >
                        <Tooltip title={<FormattedMessage id="projectMembers.import" />}>
                            <Button
                                size="large"
                                icon={<Icon component={CloudUploadSvg} />}
                                disabled={disabled}
                            ></Button>
                        </Tooltip>
                    </Link>
                    <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        disabled={disabled}
                        menu={{
                            items: !rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])
                                ? [
                                      {
                                          key: 'company',
                                          icon: <Icon component={EditSvg} />,
                                          onClick: () => setCompanyModalVisible(true),
                                          disabled: isUpdateCompaniesButtonDisabled(),
                                          label: <FormattedMessage id="projectMembers.updateCompany" />,
                                      },
                                      {
                                          key: 'roles',
                                          icon: <Icon component={EditSvg} />,
                                          onClick: () => setRolesModalVisible(true),
                                          disabled: isUpdateRolesButtonDisabled(),
                                          label: <FormattedMessage id="projectMembers.updateRoles" />,
                                      },
                                  ]
                                : [
                                      {
                                          key: 'company',
                                          icon: <Icon component={EditSvg} />,
                                          onClick: () => setCompanyModalVisible(true),
                                          disabled: isUpdateCompaniesButtonDisabled(),
                                          label: <FormattedMessage id="projectMembers.updateCompany" />,
                                      },
                                      {
                                          key: 'roles',
                                          icon: <Icon component={EditSvg} />,
                                          onClick: () => setRolesModalVisible(true),
                                          disabled: isUpdateRolesButtonDisabled(),
                                          label: <FormattedMessage id="projectMembers.updateRoles" />,
                                      },
                                  ],
                        }}
                    >
                        <Button type="primary" size="large">
                            <FormattedMessage id="button.actions" /> <Icon component={CaretDownSvg} />
                        </Button>
                    </Dropdown>
                </Space>
            </div>
            <SelectTableComponent
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(projectMembersPage)}
                rowKey={(item) => `${item.email}-${item.project.id}`}
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
                rowSelection={rowSelection}
                selectedItems={selectedProjectMembers.map((projectMember) => projectMember.email)}
                onUnselectAll={unselectAll}
            />
            <UpdateCompanyModal
                companies={companies}
                visible={companyModalVisible}
                onUpdate={updateCompany}
                onCancel={() => setCompanyModalVisible(false)}
            />
            <UpdateRolesModal
                roles={roles}
                visible={rolesModalVisible}
                onUpdate={updateRoles}
                onCancel={() => setRolesModalVisible(false)}
            />
        </LayoutComponent>
    );
};

export default ProjectMembersPage;

type DisplayType = 'grouped' | 'all';

const getCompanies = (projectMember: ProjectMember): string => {
    return [projectMember.company]
        .concat(projectMember.siblings.map((pm) => pm.company))
        .filter((c) => c && c.name)
        .map((c) => c!.name)
        .filter((item, index, self) => self.indexOf(item) === index)
        .sort((a, b) => a.localeCompare(b))
        .join(', ');
};

const getRoles = (projectMember: ProjectMember): string => {
    return [...projectMember.roles]
        .concat(projectMember.siblings.flatMap((pm) => pm.roles))
        .filter((c) => c && c.name)
        .map((c) => c!.name)
        .filter((item, index, self) => self.indexOf(item) === index)
        .sort((a, b) => a.localeCompare(b))
        .join(', ');
};

const getServiceValue = (services: Service[], serviceType: ServiceType) => {
    const servicesByType = services.filter((s) => s.name === serviceType);
    const service = servicesByType.length > 0 ? servicesByType[0] : undefined;
    return service && service.access !== 'none' ? <FormattedMessage id={service.access} /> : '-';
};
