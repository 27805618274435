import moment from 'moment';
import { TaskConversionProcessFile } from '../model/entities';
import { syncApiAxios } from './CustomAxios';
import { Page } from '../model/elements';

class TaskConversionProcessFileApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskProjectConversionProcessId: number,
        searchText?: string,
    ): Promise<Page<TaskConversionProcessFile>> => {
        const response = await syncApiAxios.get<Page<TaskConversionProcessFile>>('/task-conversion-process-files', {
            params: { page, size, sortField, sortOrder, taskProjectConversionProcessId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.audit!.created = d.audit!.created && moment.utc(d.audit!.created).local();
            d.audit!.updated = d.audit!.updated && moment.utc(d.audit!.updated).local();
        });

        return response.data;
    };
}

const taskConversionProcessFileApi: TaskConversionProcessFileApi = new TaskConversionProcessFileApi();
export default taskConversionProcessFileApi;
