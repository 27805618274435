import Icon, { FileExcelOutlined, FileOutlined, FolderFilled } from '@ant-design/icons';
import { Button, Descriptions, Select, Space, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import taskSyncApi from '../../../../../api/TaskSyncApi';
import taskSyncFileTargetApi from '../../../../../api/TaskSyncFileTargetApi';
import FileSizeComponent from '../../../../../components/FileSizeComponent/FileSizeComponent';
import LayoutComponent from '../../../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../../../components/TaskStatusComponent/TaskStatusComponent';
import { Page } from '../../../../../model/elements';
import { TaskSync, TaskSyncFileTarget } from '../../../../../model/entities';
import { TaskStatusType, taskStatusTypes } from '../../../../../model/types';
import { ReactComponent as BackSvg } from '../../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../../resources/images/refresh.svg';
import notificationService from '../../../../../services/NotificationService';
import tableService from '../../../../../services/TableService';
import styles from './TaskSyncFileTargetsPage.module.scss';

/**
 * Returns the task sync file targets page.
 * @returns the task sync file targets page.
 */
const TaskSyncFileTargetsPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [task, setTask] = useState<TaskSync>();
    const [taskSyncFileTargetsPage, setTaskSyncFileTargetsPage] = useState<Page<TaskSyncFileTarget>>();
    const [loading, setLoading] = useState<'loading' | 'exporting' | string>();
    const [status, setStatus] = useState<TaskStatusType>();
    const [searchText, setSearchText] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                const id = +params.id;
                let status: TaskStatusType | undefined;
                if (window.location.search.includes('status')) {
                    const params = new URLSearchParams(window.location.search);
                    status = params.get('status') as TaskStatusType;
                }

                const page = taskSyncFileTargetsPage?.number || 0;
                const size = taskSyncFileTargetsPage?.size || tableService.pageSize;
                const sortField = taskSyncFileTargetsPage?.sort.field || 'fileFolder';
                const sortOrder = taskSyncFileTargetsPage?.sort.order || false;
                const responses = await Promise.all([
                    taskSyncApi.get(id),
                    listTaskFileTargets(page, size, sortField, sortOrder, id, status, searchText),
                ]);

                const task = responses[0];

                setTask(task);
                setStatus(status);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [
        intl,
        params.id,
        searchText,
        taskSyncFileTargetsPage?.number,
        taskSyncFileTargetsPage?.size,
        taskSyncFileTargetsPage?.sort.field,
        taskSyncFileTargetsPage?.sort.order,
    ]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading('loading');

            const id = +params.id;
            let status: TaskStatusType | undefined;
            if (window.location.search.includes('status')) {
                const params = new URLSearchParams(window.location.search);
                status = params.get('status') as TaskStatusType;
            }

            const page = taskSyncFileTargetsPage?.number || 0;
            const size = taskSyncFileTargetsPage?.size || tableService.pageSize;
            const sortField = taskSyncFileTargetsPage?.sort.field || 'fileFolder';
            const sortOrder = taskSyncFileTargetsPage?.sort.order || false;
            const responses = await Promise.all([
                taskSyncApi.get(id),
                listTaskFileTargets(page, size, sortField, sortOrder, id, status, searchText),
            ]);

            const task = responses[0];

            setTask(task);
            setStatus(status);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading('loading');

            const page = pagination.current! - 1;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            await listTaskFileTargets(page, pageSize, sortField, sortOrder, task!.id!, status, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading('loading');
            setSearchText(searchText);

            const page = 0;
            const pageSize = taskSyncFileTargetsPage!.size;
            const sortField = taskSyncFileTargetsPage!.sort.field!;
            const sortOrder = taskSyncFileTargetsPage!.sort.order!;
            await listTaskFileTargets(page, pageSize, sortField, sortOrder, task!.id!, status, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const filterByStatus = async (status: TaskStatusType | undefined) => {
        try {
            setLoading('loading');
            setStatus(status);

            const page = 0;
            const pageSize = taskSyncFileTargetsPage!.size;
            const sortField = taskSyncFileTargetsPage!.sort.field!;
            const sortOrder = taskSyncFileTargetsPage!.sort.order!;
            await listTaskFileTargets(page, pageSize, sortField, sortOrder, task!.id!, status, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const listTaskFileTargets = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskId: number,
        status?: TaskStatusType,
        searchText?: string,
    ) => {
        let statuses: TaskStatusType[] = [];
        if (status === 'IN_PROGRESS') {
            statuses = ['IN_PROGRESS', 'DOWNLOADING', 'SYNCHRONIZING', 'UPLOADING'];
        } else if (status) {
            statuses = [status];
        }

        const taskSyncFileTargetsPage = await taskSyncFileTargetApi.listByTaskSync(
            page,
            size,
            sortField,
            sortOrder,
            taskId,
            statuses,
            searchText,
        );
        setTaskSyncFileTargetsPage(taskSyncFileTargetsPage);
    };

    const retry = async (taskSyncFileTarget: TaskSyncFileTarget) => {
        try {
            setLoading(`retrying-${taskSyncFileTarget.id}`);

            await taskSyncFileTargetApi.retry(taskSyncFileTarget.id);
            await list();
        } catch (error) {
            notificationService.displayError(error, intl, [{ status: 400, message: 'task.retry.status.notAllowed' }]);
        } finally {
            setLoading(undefined);
        }
    };

    const isRetryEnabled = (taskSyncFileTarget: TaskSyncFileTarget): boolean => {
        return (
            !!task &&
            !!task.status &&
            ['SUCCESSFUL', 'ERROR', 'FAILED'].includes(task.status) &&
            ['ERROR', 'FAILED'].includes(taskSyncFileTarget.status)
        );
    };

    const exportSpreadSheet = async () => {
        try {
            setLoading('exporting');

            const sortField = taskSyncFileTargetsPage!.sort.field!;
            const sortOrder = taskSyncFileTargetsPage!.sort.order!;
            let statuses: TaskStatusType[] = [];
            if (status === 'IN_PROGRESS') {
                statuses = ['IN_PROGRESS', 'DOWNLOADING', 'SYNCHRONIZING', 'UPLOADING'];
            } else if (status) {
                statuses = [status];
            }

            await taskSyncFileTargetApi.exportSpreadSheet(sortField, sortOrder, task!.id!, statuses, searchText);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** COMPONENTS ***/

    const placeholder: string = intl.formatMessage({ id: 'taskLinkFileTargets.search' });

    const statusOptions = taskStatusTypes
        .filter((s) => ['CREATED', 'IN_PROGRESS', 'SUCCESSFUL', 'ERROR', 'FAILED', 'EMPTY'].includes(s))
        .map((s) => (
            <Select.Option key={s} value={s}>
                <FormattedMessage id={s} />
            </Select.Option>
        ));

    const items = taskSyncFileTargetsPage ? taskSyncFileTargetsPage.content : [];
    const columns: ColumnsType<TaskSyncFileTarget> = [
        {
            title: <FormattedMessage id="taskSyncFileTargets.file.folder" />,
            dataIndex: 'fileFolder',
            key: 'folder',
            sorter: true,
            defaultSortOrder: 'descend',
            align: 'center',
            width: 80,
            render: (folder: boolean, taskSyncFileTarget: TaskSyncFileTarget) => {
                return taskSyncFileTarget.file.folder ? <FolderFilled /> : <FileOutlined />;
            },
        },
        {
            title: <FormattedMessage id="taskSyncFileTargets.target.project" />,
            dataIndex: 'targetProjectName',
            key: 'targetProjectName',
            sorter: true,
            width: 180,
            render: (value: string, taskSyncFileTarget: TaskSyncFileTarget) => taskSyncFileTarget.target?.project.name,
        },
        {
            title: <FormattedMessage id="taskSyncFileTargets.target.folder" />,
            dataIndex: 'targetFolderName',
            key: 'targetFolderName',
            sorter: true,
            width: 200,
            render: (value: string, taskSyncFileTarget: TaskSyncFileTarget) => taskSyncFileTarget.target?.folder.name,
        },
        {
            title: <FormattedMessage id="taskSyncFileTargets.file.path" />,
            dataIndex: 'filePath',
            key: 'filePath',
            render: (value: string[], taskSyncFileTarget: TaskSyncFileTarget) =>
                '/' + taskSyncFileTarget.file.path?.join('/'),
        },
        {
            title: <FormattedMessage id="taskSyncFileTargets.file.name" />,
            dataIndex: 'fileName',
            key: 'fileName',
            sorter: true,
            width: 220,
            render: (value: string, taskSyncFileTarget: TaskSyncFileTarget) => taskSyncFileTarget.file.name,
        },
        {
            title: <FormattedMessage id="taskSyncFileTargets.file.size" />,
            dataIndex: 'fileSize',
            key: 'size',
            width: 100,
            align: 'right',
            sorter: true,
            render: (value: number, taskSyncFileTarget: TaskSyncFileTarget) => (
                <FileSizeComponent value={taskSyncFileTarget.file.size} />
            ),
        },
        {
            title: <FormattedMessage id="taskSyncFileTargets.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'auditCreated',
            sorter: true,
            width: 180,
            align: 'center',
            render: (value: string, taskSyncFileTarget: TaskSyncFileTarget) => (
                <FormattedDate
                    value={taskSyncFileTarget.audit.created as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskSyncFileTargets.audit.updated" />,
            dataIndex: 'auditUpdated',
            key: 'auditUpdated',
            sorter: true,
            width: 180,
            align: 'center',
            render: (value: string, taskSyncFileTarget: TaskSyncFileTarget) => (
                <FormattedDate
                    value={taskSyncFileTarget.audit.updated as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskSyncFileTargets.status" />,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 140,
            align: 'center',
            render: (status: TaskStatusType, taskSyncFileTarget: TaskSyncFileTarget) => (
                <Space>
                    <TaskStatusComponent key="status" status={status} error={taskSyncFileTarget.error} />
                    <Tooltip key="retry" title={<FormattedMessage id="button.retry" />}>
                        <Button
                            type="ghost"
                            size="small"
                            icon={<Icon component={RefreshSvg} />}
                            onClick={() => retry(taskSyncFileTarget)}
                            hidden={!isRetryEnabled(taskSyncFileTarget)}
                            loading={loading === `retrying-${taskSyncFileTarget.id}`}
                            disabled={!!loading}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <LayoutComponent pageId="tasks">
            <Descriptions
                title={
                    <>
                        <span className={styles.title}>
                            #{task?.id} - {task?.name} - <FormattedMessage id="taskSyncFileTargets.targets" />
                        </span>
                        <TaskStatusComponent status={task?.status} />
                        <Space>
                            <Tooltip title={<FormattedMessage id="tasks.exportSpreadSheet" />}>
                                <Button
                                    size="small"
                                    type="ghost"
                                    icon={<FileExcelOutlined />}
                                    onClick={exportSpreadSheet}
                                    loading={loading === 'exporting'}
                                    disabled={!task || !task.id}
                                ></Button>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.back" />}>
                                <Link to={`/task-syncs/${task?.id}`}>
                                    <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.refresh" />}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    icon={<Icon component={RefreshSvg} />}
                                    onClick={list}
                                />
                            </Tooltip>
                        </Space>
                    </>
                }
                column={2}
                size="small"
                className={styles.summary}
            >
                <Descriptions.Item label={<FormattedMessage id="task.source" />}>
                    {task?.source?.provider} &gt; {task?.source?.name}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.target" />}>
                    {task?.target?.provider} &gt; {task?.target?.name}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                    <FormattedDate
                        value={task?.audit?.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                    <FormattedDate
                        value={task?.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item span={2}>{task?.description}</Descriptions.Item>
            </Descriptions>
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" />
                <Space className={styles.buttons}>
                    <Select
                        size="large"
                        className={styles.status}
                        placeholder={<FormattedMessage id="taskSyncFileTargets.status.placeholder" />}
                        value={status}
                        onChange={filterByStatus}
                        allowClear
                    >
                        {statusOptions}
                    </Select>
                </Space>
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(taskSyncFileTargetsPage, { position: ['bottomRight'] })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className={styles.table}
            />
        </LayoutComponent>
    );
};

export default TaskSyncFileTargetsPage;

type ParamsType = { id: string };
