import moment from 'moment';
import { Page } from '../model/elements';
import { TaskProjectAction } from '../model/entities';
import adminApiAxios from './CustomAxios';
import { ProviderType } from '../model/types';

class TaskProjectActionApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskId: number,
        hubId?: string,
        provider?: ProviderType,
        searchText?: string,
    ): Promise<Page<TaskProjectAction>> => {
        const response = await adminApiAxios.get<Page<TaskProjectAction>>('/task-project-actions', {
            params: { page, size, sortField, sortOrder, taskId, searchText, hubId, provider },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.audit!.created = d.audit!.created && moment.utc(d.audit!.created).local();
            d.audit!.updated = d.audit!.updated && moment.utc(d.audit!.updated).local();
        });

        return response.data;
    };
}

const taskProjectActionApi: TaskProjectActionApi = new TaskProjectActionApi();
export default taskProjectActionApi;
