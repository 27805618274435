import moment from 'moment';
import { Page } from '../model/elements';
import { TaskSyncFile, TaskSyncFileStatus } from '../model/entities';
import { syncApiAxios } from './CustomAxios';

class TaskSyncFileApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskSyncLinkId: number,
        searchText?: string,
        parentId?: number,
    ): Promise<Page<TaskSyncFile>> => {
        const response = await syncApiAxios.get<Page<TaskSyncFile>>('/task-sync-files', {
            params: { page, size, sortField, sortOrder, taskSyncLinkId, searchText, parentId },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.audit!.created = d.audit!.created && moment.utc(d.audit!.created).local();
            d.audit!.updated = d.audit!.updated && moment.utc(d.audit!.updated).local();
        });

        return response.data;
    };

    get = async (id: number): Promise<TaskSyncFile> => {
        const response = await syncApiAxios.get<TaskSyncFile>(`/task-sync-files/${id}`);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };

    listStatuses = async (taskSyncId: number): Promise<TaskSyncFileStatus[]> => {
        const response = await syncApiAxios.get<TaskSyncFileStatus[]>('/task-sync-files/statuses', {
            params: { taskSyncId },
        });

        return response.data;
    };
}

const taskSyncFileApi: TaskSyncFileApi = new TaskSyncFileApi();
export default taskSyncFileApi;
