import moment from 'moment';
import { TaskConversionProcessProject } from '../model/entities';
import { syncApiAxios } from './CustomAxios';

class TaskConversionProcessProjectApi {
    get = async (id: number): Promise<TaskConversionProcessProject> => {
        const response = await syncApiAxios.get<TaskConversionProcessProject>(
            `/task-project-conversion-processes/${id}`,
        );
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };
}

const taskConversionProcessProjectApi: TaskConversionProcessProjectApi = new TaskConversionProcessProjectApi();
export default taskConversionProcessProjectApi;
