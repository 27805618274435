import React from 'react';
import { useIntl } from 'react-intl';
import { Steps } from 'antd';

import { useSetupProjectsImport, CurrentStep } from './SetupProjectsImportContext';
import UploadComponent from './UploadComponent';
import SummaryComponent from './SummaryComponent';
import ResultComponent from './ResultComponent';
import styles from './ImportPage.module.scss';

const ImportProjectsStep: React.FC = () => {
    const intl = useIntl();
    const { currentStep } = useSetupProjectsImport();

    return (
        <>
            <Steps
                current={currentStep}
                className={styles.steps}
                items={[
                    {
                        title: intl.formatMessage({ id: 'connectionLinks.import.steps.1' }),
                    },
                    {
                        title: intl.formatMessage({ id: 'connectionLinks.import.steps.2' }),
                    },
                    {
                        title: intl.formatMessage({ id: 'connectionLinks.import.steps.3' }),
                    },
                ]}
            />
            {currentStep === CurrentStep.UPLOAD && <UploadComponent />}
            {currentStep === CurrentStep.SUMMARY && <SummaryComponent />}
            {currentStep >= CurrentStep.RESULT && <ResultComponent />}
        </>
    );
};

export default ImportProjectsStep;
