import { Folder } from '../model/entities';
import { ProviderType } from '../model/types';
import stringService from '../services/StringService';
import { proxyApiAxios } from './CustomAxios';

class FolderApi {
    list = async (provider: ProviderType, hubId: string, projectId: string, folderId?: string): Promise<Folder[]> => {
        const reg = /^(b[.])/;
        const response = await proxyApiAxios.get<Folder[]>('/folders', {
            params: { provider, hubId, projectId: reg.test(projectId) ? projectId : `b.${projectId}`, folderId },
        });
        const folders = response.data.sort((a, b) => stringService.sort(a.name, b.name));

        return folders;
    };

    get = async (id: string, provider: ProviderType, hubId: string, projectId: string): Promise<Folder> => {
        const reg = /^(b[.])/;
        const response = await proxyApiAxios.get<Folder>(`/folders/${id}`, {
            params: { provider, hubId, projectId: reg.test(projectId) ? projectId : `b.${projectId}` },
        });

        return response.data;
    };
}

const folderApi: FolderApi = new FolderApi();
export default folderApi;
