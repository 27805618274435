import moment from 'moment';
import { TaskProject } from '../model/entities';
import adminApiAxios from './CustomAxios';

class TaskProjectApi {
    get = async (id: number): Promise<TaskProject> => {
        const response = await adminApiAxios.get<TaskProject>(`/tasks/projects/${id}`);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };
}

const taskProjectApi: TaskProjectApi = new TaskProjectApi();
export default taskProjectApi;
