import { Form, Select } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Project, ProjectIssue } from '../../../../../../model/entities';
import stringService from '../../../../../../services/StringService';
import { ProviderType, providerTypes } from '../../../../../../model/types';
import { FormInstance } from 'antd/es/form/Form';

const EditableCell: React.FC<Props> = ({
    editing,
    identifier,
    children,
    projects,
    issues,
    sourceProject,
    form,
    ...restProps
}) => {
    return (
        <td {...restProps}>{editing ? getFormItem(form, identifier, projects, issues, sourceProject) : children}</td>
    );
};
export default EditableCell;

const getFormItem = (
    form: FormInstance | undefined,
    identifier: string,
    projects: Project[],
    issues: ProjectIssue[],
    sourceProject: Project,
) => {
    switch (identifier) {
        case 'targetProjectPlatform':
            return (
                <Form.Item
                    name={identifier}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Select onChange={() => form?.resetFields(['targetProjectId'])}>{projectPlatformOptions()}</Select>
                </Form.Item>
            );
        case 'targetProjectId':
            return (
                <Form.Item
                    style={{ margin: 0 }}
                    shouldUpdate={(prevValues, curValues) =>
                        prevValues.targetProjectPlatform !== curValues.targetProjectPlatform
                    }
                >
                    {({ getFieldValue }) => {
                        const targetProjectPlatform = getFieldValue('targetProjectPlatform');
                        return (
                            <Form.Item
                                name={identifier}
                                style={{ margin: 0 }}
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select allowClear showSearch filterOption={stringService.filterOptions}>
                                    {projectOptions(projects, sourceProject, targetProjectPlatform)}
                                </Select>
                            </Form.Item>
                        );
                    }}
                </Form.Item>
            );
        case 'id':
            return (
                <Form.Item
                    name={identifier}
                    style={{ margin: 0 }}
                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                >
                    <Select allowClear showSearch filterOption={stringService.filterOptions}>
                        {issueOptions(issues)}
                    </Select>
                </Form.Item>
            );

        default:
            break;
    }
};

const projectPlatformOptions = () =>
    providerTypes
        .filter((platform) => ['ACC', 'BIM360'].includes(platform))
        .map((platform) => (
            <Select.Option key={platform} value={platform}>
                {platform}
            </Select.Option>
        ));

const projectOptions = (projects: Project[], sourceProject: Project, targetProjectPlatform: ProviderType) =>
    projects
        .filter((project) => project.platform === targetProjectPlatform)
        .filter((project) => project.id !== sourceProject.id)
        .map((project) => (
            <Select.Option key={project.id!} value={project.id}>
                {project.name}
            </Select.Option>
        ));

const issueOptions = (issues: ProjectIssue[]) =>
    issues.map((issue) => (
        <Select.Option key={issue.id!} value={issue.id}>
            {issue.identifier} - {issue.title}
        </Select.Option>
    ));

interface Props extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    identifier: string;
    children: React.ReactNode;
    projects: Project[];
    issues: ProjectIssue[];
    sourceProject: Project;
    form?: FormInstance;
}
