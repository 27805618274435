import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

import Icon from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import { ReactComponent as CloudUploadSvg } from '../../../resources/images/cloud-upload.svg';
import Search from 'antd/lib/input/Search';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSetupProjects } from './SetupProjectsContext';
import styles from './SetupProjectsPage.module.scss';
import { ProviderType, providerTypes } from '../../../model/types';
import CustomContext from '../../../context/CustomContext';

function SelectProjectsPageHeader() {
    /** HOOKS **/

    const { hubs, selectedHubId, isHubsLoading, setSelectedHubId, handleSearch, selectedHub, setPlatform, platform } =
        useSetupProjects();
    const history = useHistory();
    const intl = useIntl();
    const context = useContext(CustomContext);
    const { auth } = context;

    /** METHODS **/

    const handleSelectHub = (hubId: string) => {
        setSelectedHubId(hubId);
        history.replace(`/setup/projects?hubId=${hubId}`);
    };

    const handleSelectPlatform = (value: ProviderType) => {
        setPlatform(value);
        history.replace(`/setup/projects?hubId=${selectedHubId}&platform=${value}`);
    };

    const isHubAdmin = (): boolean => {
        const memberHub = auth?.members.find((m) => m.hubId === selectedHubId);
        if (memberHub && memberHub.bim360 === 'ADMIN') {
            return true;
        } else {
            return false;
        }
    };

    /** COMPONENTS **/

    const hubOptions = hubs.map((hub) => (
        <Select.Option key={hub.id} value={hub.id}>
            {`${hub.provider} > ${hub.name}`}
        </Select.Option>
    ));

    return (
        <div className={styles.pageheader}>
            <div className={styles.select}>
                <div className={styles.selectformbox}>
                    <label>
                        <FormattedMessage id="projects.hub" />
                    </label>
                    <Select
                        size="large"
                        className={styles.options}
                        loading={isHubsLoading}
                        value={selectedHubId}
                        onChange={handleSelectHub}
                        placeholder={<FormattedMessage id="projectMembers.hub.placeholder" />}
                    >
                        {hubOptions}
                    </Select>
                </div>
                {selectedHub?.provider === 'ACC' && (
                    <div className={styles.selectformbox}>
                        <label>
                            <FormattedMessage id="projects.platform" />
                        </label>
                        <Select
                            size="large"
                            className={styles.options}
                            loading={isHubsLoading}
                            value={platform}
                            onChange={handleSelectPlatform}
                            placeholder={<FormattedMessage id="projectMembers.hub.placeholder" />}
                        >
                            {providerTypes
                                .filter((providerType) => ['BIM360', 'ACC'].includes(providerType))
                                .map((providerType) => (
                                    <Select.Option key={`provider-type-${providerType}`} value={providerType}>
                                        {providerType}
                                    </Select.Option>
                                ))}
                        </Select>
                    </div>
                )}
            </div>
            <hr />
            <div className={styles.searchbox}>
                <div>
                    <Search
                        placeholder={intl.formatMessage({ id: 'projects.search' })}
                        onSearch={handleSearch}
                        size="large"
                        className={styles.searchform}
                    />
                </div>
                {selectedHub && isHubAdmin() ? (
                    <div className={styles.inputbuttongroup}>
                        <Link
                            to={`/setup/projects/import/${selectedHub.id}/${platform}?hubName=${selectedHub.name}&hubProvider=${selectedHub.provider}`}
                        >
                            <Tooltip title={<FormattedMessage id="projects.import" />}>
                                <Button size="large" icon={<Icon component={CloudUploadSvg} />}></Button>
                            </Tooltip>
                        </Link>
                    </div>
                ) : (
                    <Tooltip title={<FormattedMessage id="projects.importTooltip" />}>
                        <Button size="large" icon={<Icon component={CloudUploadSvg} />} disabled={true}></Button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
}

export default SelectProjectsPageHeader;
