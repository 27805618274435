import { Connection, ConnectionClone } from '../model/entities';
import { Page } from '../model/elements';
import adminApiAxios from './CustomAxios';

class ConnectionApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
    ): Promise<Page<Connection>> => {
        const response = await adminApiAxios.get<Page<Connection>>('/connections', {
            params: { page, size, sortField, sortOrder, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    get = async (id: number): Promise<Connection> => {
        const response = await adminApiAxios.get<Connection>(`/connections/${id}`);
        return response.data;
    };

    create = async (connection: Connection): Promise<Connection> => {
        const response = await adminApiAxios.post<Connection>('/connections', connection);
        return response.data;
    };

    update = async (connection: Connection): Promise<Connection> => {
        const response = await adminApiAxios.put<Connection>(`/connections/${connection.id}`, connection);
        return response.data;
    };

    delete = async (connection: Connection): Promise<void> => {
        await adminApiAxios.delete(`/connections/${connection.id}`);
    };

    clone = async (connectionClone: ConnectionClone): Promise<Connection> => {
        const response = await adminApiAxios.post<Connection>('/connections/clones', connectionClone);
        return response.data;
    };
}

const connectionApi: ConnectionApi = new ConnectionApi();
export default connectionApi;
