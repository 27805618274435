import qs from 'qs';
import { ProjectIssueType } from '../model/entities';
import { proxyApiAxios } from './CustomAxios';

class ProjectIssueTypeApi {
    list = async (hubId: string, projectId: string[]): Promise<ProjectIssueType[]> => {
        const response = await proxyApiAxios.get<ProjectIssueType[]>('/project-issue-types', {
            params: { hubId, projectId },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });

        return response.data;
    };
}

const projectIssueTypeApi: ProjectIssueTypeApi = new ProjectIssueTypeApi();
export default projectIssueTypeApi;
