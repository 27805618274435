import { Tag } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ProjectSubmissionState } from '../../../model/types';

/**
 * This component represents a project submission state component.
 * @param props - the props
 */
export const ProjectSubmissionStateComponent: React.FC<Props> = (props) => {
    const { state } = props;

    return (
        <>
            {state === 'DRAFT' && (
                <Tag color="default">
                    <FormattedMessage id={state} />
                </Tag>
            )}
            {state === 'SUBMITTED' && (
                <Tag color="blue">
                    <FormattedMessage id={state} />
                </Tag>
            )}
            {state === 'APPROVED' && (
                <Tag color="green">
                    <FormattedMessage id={state} />
                </Tag>
            )}
            {state === 'REJECTED' && (
                <Tag color="red">
                    <FormattedMessage id={state} />
                </Tag>
            )}
        </>
    );
};
export default ProjectSubmissionStateComponent;

interface Props {
    state?: ProjectSubmissionState;
}
