import FileSaver from 'file-saver';
import qs from 'qs';

import { proxyApiAxios } from './CustomAxios';
import { EntityWithStatus, Project, ProjectImport, ProjectTemplate, EntityWithJob } from '../model/entities';
import { ProviderType } from '../model/types';

class ProjectApi {
    /**
     * Returns the projects of the principal. It returns all the projects if the user is hub administrator.
     * @param provider the provider (ACC, BIM360)
     * @param hubId the hub id
     * @param detail if the details should be included
     * @param searchText the search text
     * @returns the projects
     */
    list = async (
        provider: ProviderType,
        hubId: string,
        detail: boolean = false,
        searchText?: string,
    ): Promise<Project[]> => {
        const response = await proxyApiAxios.get<Project[]>('/projects', {
            params: { provider, hubId, detail, searchText },
        });
        return response.data;
    };

    /**
     * Returns the projects of the principal.
     * @param provider the provider (ACC, BIM360)
     * @param hubId the hub id
     * @param searchText the search text
     * @returns the projects
     */
    listUserProjects = async (provider: ProviderType, hubId: string, searchText?: string): Promise<Project[]> => {
        const response = await proxyApiAxios.get<Project[]>('/projects', {
            params: { provider, hubId, searchText, users: '' },
        });
        return response.data;
    };

    listTemplates = async (hubId: string, provider: ProviderType): Promise<ProjectTemplate[]> => {
        const response = await proxyApiAxios.get<ProjectTemplate[]>('/projects/templates', {
            params: { provider, hubId },
        });
        return response.data;
    };

    get = async (id: string, provider: ProviderType, hubId: string, detail: boolean = false): Promise<Project> => {
        const response = await proxyApiAxios.get<Project>(`/projects/${id}`, {
            params: { provider, hubId, detail },
        });
        return response.data;
    };

    import = async (projects: ProjectImport[], hubId: string, providerType: ProviderType) => {
        const response = await proxyApiAxios.post<EntityWithStatus<EntityWithJob<Project>>[]>(
            '/projects/imports',
            projects.map((p) => ({
                ...p,
                startDate: p.startDate?.format('YYYY-MM-DD'),
                endDate: p.endDate?.format('YYYY-MM-DD'),
            })),
            {
                params: { hubId, providerType },
            },
        );
        return response.data;
    };

    exportSpreadSheet = async (provider: ProviderType, hubId: string, hubName: string, searchText?: string) => {
        const response = await proxyApiAxios.get<Blob>(`/projects/spreadsheets`, {
            responseType: 'blob',
            params: { hubId, provider, hubName, searchText },
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: 'repeat' });
            },
        });
        FileSaver.saveAs(response.data, 'link-projects.xlsx');
    };
}

const projectApi: ProjectApi = new ProjectApi();
export default projectApi;
