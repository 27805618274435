/* eslint-disable react-hooks/exhaustive-deps, no-useless-escape */
import { useState, useRef, useEffect, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import moment from 'moment';
import readXlsxFile from 'read-excel-file';
import { Row } from 'read-excel-file/types';

import { ProjectImport } from '../../../../model/entities';
import { useSetupProjectsImport } from './SetupProjectsImportContext';
import { CurrencyType, projectImportTypes, bim360ProjectServicesTypes } from '../../../../model/types';

enum AccColIndex {
    PROJECT_NAME = 0,
    PROJECT_NUMBER = 1,
    ACCOUNT = 2,
    PROJECT_TYPE = 3,
    TEMPLATE = 4,
    START_DATE = 5,
    END_DATE = 6,
}

enum Bim360ColIndex {
    PROJECT_NAME = 0,
    PROJECT_TYPE = 1,
    PROJECT_VALUE = 2,
    PROJECT_CURRENCY = 3,
    PROJECT_NUMBER = 4,
    START_DATE = 5,
    END_DATE = 6,
    LANGUAGE = 7,
    PROJECT_TEMPLATE = 8,
    TEMPLATE = 9,
    SERVICES = 10,
}

// TODO: the new regex should be created and date format should be confirmed

const inputValidationRegex = /[\w\s_\/-]+;[\w\s_\/-]+;[\w\s_\/-]+;[\w\s_\/-]+;[\w\s_\/-]+;[\w\s_\/-]+;[\w\s_\/-]+/gm;

const useUploadController = () => {
    const intl = useIntl();
    const inputRef = useRef<HTMLInputElement>(null);
    const [dragActive, setDragActive] = useState(false);
    const [parsedFileRows, setParsedFileRows] = useState<Row[]>([]);
    const [isInputError, setIsInputError] = useState(false);

    const [inputRows, setInputRows] = useState<Row[]>([]);

    const { setProjects, templates, selectedFile, setSelectedFile, inputValue, setInputValue, platform } =
        useSetupProjectsImport();

    const parseRowsCallback = (row: string[] | Row) => {
        return row as Row;
    };

    const parsedCsvIntoRows = (parsedCsv: string): Row[] => {
        const parsedResult = parsedCsv.split(/\r\n|\r|\n/).map((line) => line.split(','));
        return parsedResult.map(parseRowsCallback);
    };

    const parsedStringIntoRows = (value: string): Row[] => {
        const parsedResult = value.split(/\r\n|\r|\n/).map((line) => line.split(/;/));
        return parsedResult.map(parseRowsCallback);
    };

    const getServiceTypes = (rowIndex: number): string[] => {
        if (parsedFileRows[rowIndex].length <= Bim360ColIndex.SERVICES) {
            return ['doc_manager'];
        }
        const serviceTypes = parsedFileRows[rowIndex]
            .slice(Bim360ColIndex.SERVICES, parsedFileRows[rowIndex].length)
            .map((k) => bim360ProjectServicesTypes.find((t) => t.key === `${k}`)?.value || undefined)
            .filter((v) => !!v) as string[];

        const serviceTypesSet = new Set(serviceTypes);
        serviceTypesSet.add('doc_manager');

        return [...Array.from(serviceTypesSet)];
    };

    useEffect(() => {
        if (inputRows.length > 0) {
            const newProjects = inputRows.map((row, rowIndex): ProjectImport => {
                const today = new Date();
                const tomorrow = new Date(today);
                tomorrow.setDate(tomorrow.getDate() + 1);
                return {
                    id: '',
                    name: `${row[platform === 'BIM360' ? Bim360ColIndex.PROJECT_NAME : AccColIndex.PROJECT_NAME]}`,
                    type: `${
                        row[platform === 'BIM360' ? Bim360ColIndex.PROJECT_TYPE : AccColIndex.PROJECT_TYPE] ||
                        projectImportTypes[0]
                    }`,
                    jobNumber: `${
                        row[platform === 'BIM360' ? Bim360ColIndex.PROJECT_NUMBER : AccColIndex.PROJECT_NUMBER]
                    }`,
                    template:
                        templates.length === 0
                            ? undefined
                            : templates.find(
                                  (template) =>
                                      template.name ===
                                      `${row[platform === 'BIM360' ? Bim360ColIndex.TEMPLATE : AccColIndex.TEMPLATE]}`,
                              ),
                    projectTemplate: row[Bim360ColIndex.PROJECT_TEMPLATE],
                    platform,
                    startDate: moment(
                        `${
                            row[platform === 'BIM360' ? Bim360ColIndex.START_DATE : AccColIndex.START_DATE] ||
                            today.toDateString()
                        }`,
                    ),
                    endDate: moment(
                        `${
                            row[platform === 'BIM360' ? Bim360ColIndex.END_DATE : AccColIndex.END_DATE] ||
                            tomorrow.toDateString()
                        }`,
                    ),
                    language: platform === 'ACC' ? undefined : `${row[Bim360ColIndex.LANGUAGE]}`,
                    value: platform === 'ACC' ? undefined : Number(`${row[Bim360ColIndex.PROJECT_VALUE]}`),
                    currency:
                        platform === 'ACC' ? undefined : (`${row[Bim360ColIndex.PROJECT_CURRENCY]}` as CurrencyType),
                    serviceTypes: platform === 'ACC' ? undefined : getServiceTypes(rowIndex),
                };
            });
            setProjects(newProjects);
        }
    }, [inputRows]);

    // handle uploaded file
    useEffect(() => {
        let fileReader: FileReader;
        let isCancel: boolean = false;

        if (selectedFile && selectedFile.type === 'text/csv') {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const result = e.target?.result;
                if (typeof result === 'string' && !isCancel) {
                    setParsedFileRows(parsedCsvIntoRows(result).slice(1));
                }
            };
            fileReader.readAsText(selectedFile);
        } else if (
            selectedFile &&
            selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            readXlsxFile(selectedFile).then((rows) => {
                const importedFile = rows.slice(1);
                setParsedFileRows(importedFile.map(parseRowsCallback));
            });
        }

        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [selectedFile]);

    const handleDrag = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleFile = function (file?: File) {
        if (
            file?.type !== 'text/csv' &&
            file?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            message.error(intl.formatMessage({ id: 'status.file.format' }));
            return;
        } else if (!file) {
            return;
        }
        setSelectedFile(file);
    };

    const handleDrop = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files[0]);
        }
    };

    const handleChange = function (e: any) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files[0]);
        }
    };

    const handleInputValue = (e: any) => {
        setInputValue(e.target.value);
        if (!inputValidationRegex.test(e.target.value)) {
            setIsInputError(true);
        } else {
            setIsInputError(false);
        }
    };

    const handleSaveInputValue = useCallback(() => {
        if (selectedFile) {
            return inputRows;
        }

        setInputRows(parsedStringIntoRows(inputValue));
        return parsedStringIntoRows(inputValue);
    }, [inputValue, templates]);

    const handleUpload = useCallback(async () => {
        const savedValue = handleSaveInputValue();
        if (!parsedFileRows && inputValue) {
            setInputRows(savedValue);
        } else if (parsedFileRows.length) {
            setInputRows(parsedFileRows);
        }
    }, [parsedFileRows, inputValue]);

    return {
        inputRef,
        handleDrag,
        handleDrop,
        handleChange,
        dragActive,
        selectedFile,
        handleInputValue,
        handleSaveInputValue,
        inputValue,
        handleUpload,
        isInputError,
    };
};

export default useUploadController;
