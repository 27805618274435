import React, { useEffect, createContext, useCallback, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import notificationService from '../../../../services/NotificationService';
import taskProjectActionApi from '../../../../api/TaskProjectActionApi';
import taskProjectApi from '../../../../api/TaskProjectApi';
import { TaskProject, TaskProjectAction } from '../../../../model/entities';
import { Page } from '../../../../model/elements';
import { TablePaginationConfig } from 'antd';
import tableService from '../../../../services/TableService';
import hubApi from '../../../../api/HubApi';
import { ProviderType } from '../../../../model/types';

class TaskProjectPage {
    task: TaskProject | undefined;
    taskProjectActionsPage: Page<TaskProjectAction> | undefined;
    isLoading: boolean = false;
    list: () => Promise<void> = async () => {};
    onSearch: (inputSearchValue: string) => Promise<void> = async (inputSearchValue: string) => {};
    paginate: (pagination: TablePaginationConfig, filters: any, sorter: any) => Promise<void> = async (
        pagination: TablePaginationConfig,
        filters: any,
        sorter: any,
    ) => {};
}

export const TaskProjectContext = createContext(new TaskProjectPage());

export const TaskProjectProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const params = useParams<{ id: string }>();
    const intl = useIntl();
    const [task, setTask] = useState<TaskProject>();
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState<string>();
    const [taskProjectActionsPage, setTaskProjectActionsPage] = useState<Page<TaskProjectAction>>();

    const list = useCallback(async () => {
        try {
            setIsLoading(true);
            let currentTask = await taskProjectApi.get(+params.id);
            const hubs = await hubApi.list();
            currentTask = {
                ...currentTask,
                hub: hubs.find((h) => h.id === currentTask.hub?.id),
            };
            setTask(currentTask);
        } catch (e) {
            notificationService.displayError(e, intl);
        } finally {
            setIsLoading(false);
        }
    }, [intl, params.id]);

    useEffect(() => {
        list();
    }, [list]);

    useEffect(() => {
        if (task?.id) {
            const page = 0;
            const size = taskProjectActionsPage?.size || tableService.pageSize;
            const sortField = taskProjectActionsPage?.sort.field || 'name';
            const sortOrder = taskProjectActionsPage?.sort.order || true;

            listTaskProjectActions(
                page,
                size,
                sortField,
                sortOrder,
                task!.id!,
                task?.hub?.id,
                task?.hub?.provider,
                searchText,
            );
        }
    }, [
        searchText,
        task,
        task?.id,
        taskProjectActionsPage?.size,
        taskProjectActionsPage?.sort.field,
        taskProjectActionsPage?.sort.order,
    ]);

    const onSearch = useCallback(
        async (inputSearchValue: string) => {
            try {
                setIsLoading(true);
                setSearchText(inputSearchValue);
                const page = 0;
                const pageSize = taskProjectActionsPage!.size;
                const sortField = taskProjectActionsPage!.sort.field!;
                const sortOrder = taskProjectActionsPage!.sort.order!;
                await listTaskProjectActions(
                    page,
                    pageSize,
                    sortField,
                    sortOrder,
                    task!.id!,
                    task?.hub?.id,
                    task?.hub?.provider,
                    searchText,
                );
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setIsLoading(false);
            }
        },
        [intl, searchText, task, taskProjectActionsPage],
    );

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setIsLoading(true);
            const page = pagination.current! - 1;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            await listTaskProjectActions(
                page,
                pageSize,
                sortField,
                sortOrder,
                task!.id!,
                task?.hub?.id,
                task?.hub?.provider,
                searchText,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setIsLoading(false);
        }
    };

    const listTaskProjectActions = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskId: number,
        hubId?: string,
        provider?: ProviderType,
        searchText?: string,
    ) => {
        const fetchedTaskMemberActionsPage = await taskProjectActionApi.list(
            page,
            size,
            sortField,
            sortOrder,
            taskId,
            hubId,
            provider,
            searchText,
        );
        setTaskProjectActionsPage(fetchedTaskMemberActionsPage);
    };

    const value = {
        task,
        taskProjectActionsPage,
        isLoading,
        list,
        onSearch,
        paginate,
    };

    return <TaskProjectContext.Provider value={value}>{children}</TaskProjectContext.Provider>;
};

export const useTaskProjectContext = () => {
    const context = useContext(TaskProjectContext);
    return context;
};
