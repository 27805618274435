import FileSaver from 'file-saver';
import { Page } from '../model/elements';
import { IssueImport, ProjectIssue, ProjectIssueFilter } from '../model/entities';
import { ProjectIssueAccStatus, ProjectIssueBim360Status } from '../model/types';
import { proxyApiAxios } from './CustomAxios';

class ProjectIssueApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        projectIssueFilter: ProjectIssueFilter,
    ): Promise<Page<ProjectIssue>> => {
        const formData = this.buildFormData(projectIssueFilter);
        const response = await proxyApiAxios.post<Page<ProjectIssue>>('/project-issues', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: { page, size, sortField, sortOrder },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;

        return response.data;
    };

    listAll = async (
        sortField: string,
        sortOrder: boolean,
        hubId: string,
        projectId: string,
        status?: ProjectIssueBim360Status | ProjectIssueAccStatus,
        typeId?: string,
        subtypeId?: string,
        searchText?: string,
    ): Promise<ProjectIssue[]> => {
        const response = await proxyApiAxios.get<ProjectIssue[]>('/project-issues/all', {
            params: { sortField, sortOrder, hubId, projectId, status, typeId, subtypeId, searchText },
        });

        return response.data;
    };

    exportSpreadSheet = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        projectIssueFilter: ProjectIssueFilter,
    ) => {
        const formData = this.buildFormData(projectIssueFilter);
        const response = await proxyApiAxios.post<Blob>(`/project-issues/spreadsheets/downloads`, formData, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: { page, size, sortField, sortOrder },
        });
        FileSaver.saveAs(response.data, 'link-project-issues.xlsx');
    };

    loadCsv = async (csv: string, hubId: string, sourceProjectId: string): Promise<IssueImport[]> => {
        const response = await proxyApiAxios.post<IssueImport[]>('/project-issues/csvs', csv, {
            headers: {
                'Content-Type': 'text/plain',
            },
            params: { hubId, sourceProjectId },
        });
        return response.data;
    };

    loadSpreadsheet = async (file: any, hubId: string, sourceProjectId: string): Promise<IssueImport[]> => {
        const formData: FormData = new FormData();
        formData.append('issueFile', file);
        const response = await proxyApiAxios.post<any>('/project-issues/spreadsheets', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: { hubId, sourceProjectId },
        });
        return response.data;
    };

    /**
     * Builds a form data from a project issue filter object.
     * @param projectIssueFilter the project issue filter object
     * @returns the formdata
     */
    private buildFormData = (projectIssueFilter: ProjectIssueFilter) => {
        const formData: FormData = new FormData();
        const projectIssueFilterJson = JSON.stringify(projectIssueFilter);
        const projectIssueFilterBlob = new Blob([projectIssueFilterJson], { type: 'application/json' });
        formData.append('issueFilter', projectIssueFilterBlob);

        return formData;
    };
}

const projectIssueApi: ProjectIssueApi = new ProjectIssueApi();
export default projectIssueApi;
