import Icon, { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Form, Modal, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import connectionLinkApi from '../../../../../../../api/ConnectionLinkApi';
import { FolderDataNode } from '../../../../../../../model/elements';
import {
    Connection,
    ConnectionLink,
    ConnectionLinkImport,
    EntityWithStatus,
} from '../../../../../../../model/entities';
import { ReactComponent as CheckmarkSvg } from '../../../../../../../resources/images/checkmark.svg';
import { ReactComponent as CloseSvg } from '../../../../../../../resources/images/close-outline.svg';
import { ReactComponent as EditSvg } from '../../../../../../../resources/images/edit.svg';
import { ReactComponent as SaveSvg } from '../../../../../../../resources/images/save.svg';
import notificationService from '../../../../../../../services/NotificationService';
import EditableCell from './EditableCell/EditableCell';
import styles from './SummaryComponent.module.scss';

/**
 * Returns the connections link import page summary.
 * @props the props
 * @returns the connection link import page summary.
 */
const SummaryComponent = (props: Props): React.ReactElement => {
    const { connectionLinkImports, onBack, onSaveConnectionLinks, connection } = props;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const [connectionLinkImportsUpdated, setConnectionLinkImportsUpdated] = useState<ConnectionLinkImport[]>([]);
    const [editingKey, setEditingKey] = useState<string>();
    const [loading, setLoading] = useState<'loading' | 'saving'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                const connectionLinkImportsUpdated = connectionLinkImports.map((im) => {
                    im.uuid = uuidv4();
                    return im;
                });

                setConnectionLinkImportsUpdated(connectionLinkImportsUpdated);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, connectionLinkImports]);

    /*** METHODS ***/

    const upload = async () => {
        try {
            setLoading('saving');

            const connectionLinkImportsWithProject = connectionLinkImportsUpdated.filter(
                (c) => !!c.sourceProject?.id && !!c.targetProject?.id,
            );

            if (connectionLinkImportsWithProject.some((c) => !c.sourceFolder?.id || !c.targetFolder?.id)) {
                Modal.confirm({
                    title: intl.formatMessage({ id: 'connectionLinks.import.steps.2.errors' }),
                    icon: <ExclamationCircleFilled />,
                    cancelButtonProps: { hidden: true },
                });
            } else {
                const connectionLinks: ConnectionLink[] = connectionLinkImportsWithProject.map(
                    (connectionLinkImport) => {
                        const connectionLink: ConnectionLink = {
                            connectionId: connection?.id,
                            source: {
                                project: connectionLinkImport.sourceProject,
                                folder: connectionLinkImport.sourceFolder,
                                includeAllFiles: connectionLinkImport.includeAllFiles,
                                includeSubfolders: connectionLinkImport.includeSubfolders,
                                maintainFolderStructure: connectionLinkImport.maintainFolderStructure,
                                files: connectionLinkImport.files?.split(',').map((e) => e.trim()) || [],
                                extensions: connectionLinkImport.extensions?.split(',').map((e) => e.trim()) || [],
                            },
                            targets: [
                                {
                                    project: connectionLinkImport.targetProject,
                                    folder: connectionLinkImport.targetFolder,
                                    uuid: '',
                                },
                            ],
                            cloned: connection?.cloned,
                            status: 'ENABLED',
                        };
                        return connectionLink;
                    },
                );

                const result = await connectionLinkApi.import(connectionLinks);

                onSaveConnectionLinks(result);
            }

            //}
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const isEditing = (connectionLinkImport: ConnectionLinkImport) => {
        return `${connectionLinkImport.uuid}` === editingKey;
    };

    const edit = (connectionLinkImport: ConnectionLinkImport) => {
        form.setFieldsValue({
            sourceProject: connectionLinkImport.sourceProject,
            targetProject: connectionLinkImport.targetProject,
            includeAllFiles: connectionLinkImport.includeAllFiles,
            includeSubfolders: connectionLinkImport.includeSubfolders,
            maintainFolderStructure: connectionLinkImport.maintainFolderStructure,
            id: connectionLinkImport.id,
            files: connectionLinkImport.files,
            extensions: connectionLinkImport.extensions,
            sourceFolder: connectionLinkImport.sourceFolder?.name,
            targetFolder: connectionLinkImport.targetFolder?.name,
        });
        setEditingKey(connectionLinkImport.uuid);
    };

    const save = async (key: React.Key) => {
        try {
            const values = await form.validateFields();

            const connectionLinkImports = [...connectionLinkImportsUpdated];
            const connectionLinkImport: ConnectionLinkImport = {
                id: connectionLinkImportsUpdated.find((c) => c.uuid === key)?.id,
                sourceProject: connectionLinkImportsUpdated.find((c) => c.uuid === key)?.sourceProject,
                targetProject: connectionLinkImportsUpdated.find((c) => c.uuid === key)?.targetProject,
                includeAllFiles: values.includeAllFiles,
                includeSubfolders: values.includeSubfolders,
                maintainFolderStructure: values.maintainFolderStructure,
                files: values.files,
                extensions: values.extensions,
                sourceFolder: connectionLinkImportsUpdated.find((c) => c.uuid === key)?.sourceFolder,
                targetFolder: connectionLinkImportsUpdated.find((c) => c.uuid === key)?.targetFolder,
            };

            const index = connectionLinkImports.findIndex((item) => key === item.uuid);

            if (index > -1) {
                const item = connectionLinkImports[index];
                connectionLinkImports.splice(index, 1, {
                    ...item,
                    ...connectionLinkImport,
                });
                setConnectionLinkImportsUpdated(connectionLinkImports);
                setEditingKey(undefined);
            } else {
                connectionLinkImports.push(connectionLinkImport);
                setConnectionLinkImportsUpdated(connectionLinkImports);
                setEditingKey(undefined);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const changeConnectionLinkImports = async (folderNode: FolderDataNode, uuid: string) => {
        const folder = folderNode.folder;
        folder.name = folderNode.path;
        const connectionLinkImportsAux = connectionLinkImportsUpdated.map((cli) => {
            if (cli.uuid === uuid) {
                cli.sourceFolder = folder;
            }
            return cli;
        });
        setConnectionLinkImportsUpdated(connectionLinkImportsAux);
    };

    const changeConnectionLinkImportsTargetFolder = async (folderNode: FolderDataNode, uuid: string) => {
        const folder = folderNode.folder;
        folder.name = folderNode.path;
        const connectionLinkImportsAux = connectionLinkImportsUpdated.map((cli) => {
            if (cli.uuid === uuid) {
                cli.targetFolder = folder;
            }

            return cli;
        });
        setConnectionLinkImportsUpdated(connectionLinkImportsAux);
    };

    /*** COMPONENTS ***/

    const columns: EditableColumnsType<ConnectionLinkImport> = [
        {
            title: <FormattedMessage id="connectionLinks.import.steps.2.sourceProject" />,
            dataIndex: 'sourceProject',
            key: 'sourceProject',
            editable: false,
            render: (value: string, connectionLinkImport: ConnectionLinkImport) =>
                connectionLinkImport.sourceProject?.id === null ? (
                    <div>
                        {connectionLinkImport.sourceProject?.name}
                        <div className={styles.notFound}>
                            <FormattedMessage id="connectionLinks.import.steps.2.error.project" />
                        </div>
                    </div>
                ) : (
                    connectionLinkImport.sourceProject?.name
                ),
        },
        {
            title: <FormattedMessage id="connectionLinks.import.steps.2.sourceFolder" />,
            dataIndex: 'sourceFolder',
            key: 'sourceFolder',
            editable: true,
            width: 200,
            render: (value: string, connectionLinkImport: ConnectionLinkImport) =>
                connectionLinkImport.sourceFolder?.id === null ? (
                    <div>
                        {connectionLinkImport.sourceFolder?.name}
                        <div className={styles.notFound}>
                            <FormattedMessage id="connectionLinks.import.steps.2.error.folder" />
                        </div>
                    </div>
                ) : (
                    connectionLinkImport.sourceFolder?.name
                ),
        },
        {
            title: <FormattedMessage id="connectionLinks.import.steps.2.includeAllFiles" />,
            dataIndex: 'includeAllFiles',
            key: 'includeAllFiles',
            editable: true,
            render: (value: boolean, connectionLinkImport: ConnectionLinkImport) =>
                value ? <FormattedMessage id="button.yes" /> : <FormattedMessage id="button.no" />,
        },
        {
            title: <FormattedMessage id="connectionLinks.import.steps.2.selectedFiles" />,
            dataIndex: 'files',
            key: 'files',
            editable: true,
            width: 180,
            render: (value: string, connectionLinkImport: ConnectionLinkImport) => value,
        },
        {
            title: <FormattedMessage id="connectionLinks.import.steps.2.selectedExtensions" />,
            dataIndex: 'extensions',
            key: 'extensions',
            editable: true,
            render: (value: string, connectionLinkImport: ConnectionLinkImport) => value,
        },
        {
            title: <FormattedMessage id="connectionLinks.import.steps.2.includeSubfolders" />,
            dataIndex: 'includeSubfolders',
            key: 'includeSubfolders',
            editable: true,
            render: (value: boolean, connectionLinkImport: ConnectionLinkImport) =>
                value ? <FormattedMessage id="button.yes" /> : <FormattedMessage id="button.no" />,
        },
        {
            title: <FormattedMessage id="connectionLinks.import.steps.2.maintainFolderStructure" />,
            dataIndex: 'maintainFolderStructure',
            key: 'maintainFolderStructure',
            editable: true,
            render: (value: boolean, connectionLinkImport: ConnectionLinkImport) =>
                value ? <FormattedMessage id="button.yes" /> : <FormattedMessage id="button.no" />,
        },
        {
            title: <FormattedMessage id="connectionLinks.import.steps.2.targetProject" />,
            dataIndex: 'targetProject',
            key: 'targetProject',
            editable: false,
            render: (value: string, connectionLinkImport: ConnectionLinkImport) =>
                connectionLinkImport.targetProject?.id === null ? (
                    <div>
                        {connectionLinkImport.targetProject?.name}
                        <div className={styles.notFound}>
                            <FormattedMessage id="connectionLinks.import.steps.2.error.project" />
                        </div>
                    </div>
                ) : (
                    connectionLinkImport.targetProject?.name
                ),
        },
        {
            title: <FormattedMessage id="connectionLinks.import.steps.2.targetFolder" />,
            dataIndex: 'targetFolder',
            key: 'targetFolder',
            editable: true,
            width: 200,
            render: (value: string, connectionLinkImport: ConnectionLinkImport) =>
                connectionLinkImport.targetFolder?.id === null ? (
                    <div>
                        {connectionLinkImport.targetFolder?.name}
                        <div className={styles.notFound}>
                            <FormattedMessage id="connectionLinks.import.steps.2.error.folder" />
                        </div>
                    </div>
                ) : (
                    connectionLinkImport.targetFolder?.name
                ),
        },
        {
            title: <FormattedMessage id="connectionLinks.import.steps.2.action" />,
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: 100,
            render: (value: string, connectionLinkImport: ConnectionLinkImport) => (
                <>
                    {connectionLinkImport.sourceProject?.id === null ||
                    connectionLinkImport.targetProject?.id === null ? (
                        <Tooltip title={<FormattedMessage id="connectionLinks.import.steps.2.action.error.tooltip" />}>
                            <Tag color="red">
                                <FormattedMessage id="connectionLinks.import.steps.2.action.error" />
                            </Tag>
                        </Tooltip>
                    ) : (
                        <Tooltip title={<FormattedMessage id="connectionLinks.import.steps.2.action.create.tooltip" />}>
                            <Tag color="green">
                                <FormattedMessage id="connectionLinks.import.steps.2.action.create" />
                            </Tag>
                        </Tooltip>
                    )}
                </>
            ),
        },

        {
            dataIndex: 'action',
            align: 'center',
            width: 100,
            render: (value: any, connectionLinkImport: ConnectionLinkImport) => {
                const editable = isEditing(connectionLinkImport);
                if (
                    connectionLinkImport.sourceProject?.id === null ||
                    connectionLinkImport.targetProject?.id === null
                ) {
                    return <></>;
                } else {
                    return editable ? (
                        <Space size={0}>
                            <Button
                                type="link"
                                size="large"
                                icon={<Icon component={CheckmarkSvg} />}
                                onClick={() => save(connectionLinkImport.uuid!)}
                            />
                            <Button
                                type="link"
                                size="large"
                                icon={<Icon component={CloseSvg} />}
                                onClick={() => setEditingKey(undefined)}
                            />
                        </Space>
                    ) : (
                        <Button
                            type="link"
                            size="large"
                            icon={<Icon component={EditSvg} />}
                            disabled={!!editingKey}
                            onClick={() => edit(connectionLinkImport)}
                        />
                    );
                }
            },
        },
    ];

    const mergedColumns: any = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (connectionLinkImport: ConnectionLinkImport) => ({
                identifier: col.key,
                editing: isEditing(connectionLinkImport),
                form,
                connectionLinkImport: connectionLinkImport,
                connection: connection,
                onFolderChange: changeConnectionLinkImports,
                onTargetFolderChange: changeConnectionLinkImportsTargetFolder,
            }),
        };
    });

    return (
        <>
            <Form form={form} component={false}>
                <h3 className={styles.subtitle}>
                    <FormattedMessage id="connectionLinks.import.steps.2.links" />
                </h3>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    dataSource={connectionLinkImportsUpdated}
                    columns={mergedColumns}
                    rowKey={(connectionLink) => connectionLink.uuid!}
                    sortDirections={['ascend']}
                    showSorterTooltip={false}
                    rowClassName={(connectionLink: ConnectionLinkImport) =>
                        connectionLink.sourceProject?.id === null || connectionLink.targetProject?.id === null
                            ? styles.disabledRow
                            : 'editable-row'
                    }
                    pagination={{
                        onChange: () => setEditingKey(undefined),
                        size: 'small',
                        hideOnSinglePage: true,
                    }}
                    loading={loading === 'loading'}
                />
            </Form>
            <Space className={styles.buttons}>
                <Button
                    type="primary"
                    size="large"
                    onClick={upload}
                    icon={<Icon component={SaveSvg} />}
                    loading={loading === 'saving'}
                    disabled={editingKey !== undefined}
                >
                    <FormattedMessage id="button.save" tagName="span" />
                </Button>
                <Button type="text" size="large" onClick={onBack}>
                    <FormattedMessage id="button.back" tagName="span" />
                </Button>
            </Space>
        </>
    );
};

export default SummaryComponent;

interface Props {
    connectionLinkImports: ConnectionLinkImport[];
    onSaveConnectionLinks: (result: EntityWithStatus<ConnectionLink>[]) => void;
    onBack: () => void;
    connection?: Connection;
}

type EditableColumnsType<RecordType = unknown> = EditableColumnType<RecordType>[];

interface EditableColumnType<RecordType> extends ColumnType<RecordType> {
    editable?: boolean;
}
