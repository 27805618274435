import Icon from '@ant-design/icons';
import { Button, Empty, List, Space } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Connection, ConnectionLink, EntityWithStatus } from '../../../../../../../model/entities';
import { ReactComponent as ArrowLeftSvg } from '../../../../../../../resources/images/arrow-left.svg';
import styles from './ResultComponent.module.scss';

/**
 * Returns the connection links import result component.
 * @props the props
 * @returns the connection links import result component.
 */
const ResultComponent = (props: Props): React.ReactElement => {
    const { result, connection } = props;
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <>
            <FailuresComponent result={result} />
            <SuccessesComponent result={result} />
            {result.length === 0 && <Empty className={styles.empty} />}
            <div className={styles.buttons}>
                <Space>
                    <Link to={`/sync/connections/${connection?.id}`}>
                        <Button type="primary" size="large" icon={<Icon component={ArrowLeftSvg} />}>
                            <FormattedMessage id="connectionLinks.import.steps.3.back" tagName="span" />
                        </Button>
                    </Link>
                </Space>
            </div>
        </>
    );
};

export default ResultComponent;

interface Props {
    result: EntityWithStatus<ConnectionLink>[];
    connection?: Connection;
}

const FailuresComponent = (props: Props): React.ReactElement => {
    const { result } = props;

    /*** HOOKS ***/

    const intl = useIntl();

    /*** COMPONENTS ***/

    const failures = result.filter((r) => r.status.type !== 'OK');
    if (failures.length > 0) {
        return (
            <List
                header={
                    <h2>
                        <FormattedMessage id="connectionLinks.import.steps.3.failure" />
                    </h2>
                }
                dataSource={failures}
                renderItem={(failure, index) => {
                    const connectionLink = failure.entity;
                    const sourceProject = connectionLink.source?.project?.name;
                    const targetProject = connectionLink.targets.find(Boolean)?.project?.name;
                    const sourceFolder = connectionLink.source?.folder?.name;
                    const targetFolder = connectionLink.targets.find(Boolean)?.folder?.name;
                    const source = (
                        <div>
                            <span className={styles.source}>
                                <FormattedMessage id="connectionLinks.import.steps.3.source" />
                            </span>
                            <span className={styles.sourceProject}>{sourceProject}</span>
                            <span className={styles.sourceFolder}>{sourceFolder}</span>
                        </div>
                    );
                    const target = (
                        <div>
                            <span className={styles.source}>
                                <FormattedMessage id="connectionLinks.import.steps.3.target" />
                            </span>
                            <span className={styles.sourceProject}>{targetProject}</span>
                            <span className={styles.sourceFolder}>{targetFolder}</span>
                        </div>
                    );
                    return (
                        <List.Item key={index}>
                            <List.Item.Meta title={source} description={target} />
                            <span className={styles.error}>
                                {failure.status.error
                                    ? intl.formatMessage({ id: failure.status.error })
                                    : failure.status.message}
                            </span>
                        </List.Item>
                    );
                }}
                className={styles.failure}
            />
        );
    } else {
        return <></>;
    }
};

const SuccessesComponent = (props: Props): React.ReactElement => {
    const { result } = props;

    /*** COMPONENTS ***/

    const successes = result.filter((r) => r.status.type === 'OK');
    if (successes.length > 0) {
        return (
            <List
                header={
                    <h2>
                        <FormattedMessage id="connectionLinks.import.steps.3.success" />
                    </h2>
                }
                dataSource={successes}
                renderItem={(success, index) => {
                    const connectionLink = success.entity;
                    const sourceProject = connectionLink.source?.project?.name;
                    const targetProject = connectionLink.targets.find(Boolean)?.project?.name;
                    const sourceFolder = connectionLink.source?.folder?.name;
                    const targetFolder = connectionLink.targets.find(Boolean)?.folder?.name;
                    const source = (
                        <div>
                            <span className={styles.source}>
                                <FormattedMessage id="connectionLinks.import.steps.3.source" />
                            </span>
                            <span className={styles.sourceProject}>{sourceProject}</span>
                            <span className={styles.sourceFolder}>{sourceFolder}</span>
                        </div>
                    );
                    const target = (
                        <div>
                            <span className={styles.source}>
                                <FormattedMessage id="connectionLinks.import.steps.3.target" />
                            </span>
                            <span className={styles.sourceProject}>{targetProject}</span>
                            <span className={styles.sourceFolder}>{targetFolder}</span>
                        </div>
                    );
                    return (
                        <List.Item key={index}>
                            <List.Item.Meta title={source} description={target} />
                        </List.Item>
                    );
                }}
                className={styles.success}
            />
        );
    } else {
        return <></>;
    }
};
