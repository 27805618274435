import Icon from '@ant-design/icons';
import { Button, Col, Divider, Row, Statistic, Tooltip } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import taskSyncApi from '../../../../../api/TaskSyncApi';
import FileSizeComponent from '../../../../../components/FileSizeComponent/FileSizeComponent';
import { TaskSync, TaskSyncFileStatus } from '../../../../../model/entities';
import { TaskStatusType } from '../../../../../model/types';
import { ReactComponent as RefreshSvg } from '../../../../../resources/images/refresh.svg';
import notificationService from '../../../../../services/NotificationService';
import styles from './TaskSyncStatusComponent.module.scss';

/**
 * Returns the intro page.
 * @returns the intro page.
 */
const TaskSyncStatusComponent: React.FC<Props> = (props) => {
    const { taskSync, taskSyncFileStatuses, onRetry } = props;
    const max: number = 1000;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'retrying'>();

    /*** METHODS ***/

    const retry = async () => {
        try {
            if (taskSync) {
                setLoading('retrying');
                await taskSyncApi.retry(taskSync.id!, max);
                await onRetry();
            }
        } catch (error) {
            notificationService.displayError(error, intl, [{ status: 400, message: 'task.retry.status.notAllowed' }]);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <div className={styles.panel}>
            <Divider plain orientation="left"></Divider>
            <Row gutter={16} justify="space-between" align="middle">
                <Col span={3}>
                    <Tooltip title={<FormattedMessage id="taskSync.status.created.tooltip" />}>
                        <Link to={`/task-syncs/${taskSync?.id}/task-sync-file-targets?status=CREATED`}>
                            <Statistic
                                title={
                                    <span className={styles.title}>
                                        <FormattedMessage id="CREATED" />
                                    </span>
                                }
                                value={getTaskSyncFileStatusTotalByStatusAndFolder(taskSyncFileStatuses, ['CREATED'])}
                                className={styles.stats}
                            />
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(taskSyncFileStatuses, ['CREATED'], true)}{' '}
                                <FormattedMessage id="taskSync.status.folders" />
                            </p>
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(taskSyncFileStatuses, ['CREATED'], false)}{' '}
                                <FormattedMessage id="taskSync.status.files" />
                            </p>
                            <p>
                                <FileSizeComponent
                                    value={getTaskSyncFileStatusSizeByStatus(taskSyncFileStatuses, ['CREATED'])}
                                />
                            </p>
                        </Link>
                    </Tooltip>
                </Col>

                <Col span={3}>
                    <Tooltip title={<FormattedMessage id="taskSync.status.inProgress.tooltip" />}>
                        <Link to={`/task-syncs/${taskSync?.id}/task-sync-file-targets?status=IN_PROGRESS`}>
                            <Statistic
                                title={
                                    <span className={styles.title}>
                                        <FormattedMessage id="IN_PROGRESS" />
                                    </span>
                                }
                                value={getTaskSyncFileStatusTotalByStatusAndFolder(taskSyncFileStatuses, [
                                    'IN_PROGRESS',
                                    'DOWNLOADING',
                                    'SYNCHRONIZING',
                                    'UPLOADING',
                                ])}
                                className={styles.stats}
                            />
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(
                                    taskSyncFileStatuses,
                                    ['IN_PROGRESS', 'DOWNLOADING', 'SYNCHRONIZING', 'UPLOADING'],
                                    true,
                                )}{' '}
                                <FormattedMessage id="taskSync.status.folders" />
                            </p>
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(
                                    taskSyncFileStatuses,
                                    ['IN_PROGRESS', 'DOWNLOADING', 'SYNCHRONIZING', 'UPLOADING'],
                                    false,
                                )}{' '}
                                <FormattedMessage id="taskSync.status.files" />
                            </p>
                            <p>
                                <FileSizeComponent
                                    value={getTaskSyncFileStatusSizeByStatus(taskSyncFileStatuses, [
                                        'IN_PROGRESS',
                                        'DOWNLOADING',
                                        'SYNCHRONIZING',
                                        'UPLOADING',
                                    ])}
                                />
                            </p>
                        </Link>
                    </Tooltip>
                </Col>
                <Col span={3}>
                    <Tooltip title={<FormattedMessage id="taskSync.status.successful.tooltip" />}>
                        <Link to={`/task-syncs/${taskSync?.id}/task-sync-file-targets?status=SUCCESSFUL`}>
                            <Statistic
                                title={
                                    <span className={styles.title}>
                                        <FormattedMessage id="SUCCESSFUL" />
                                    </span>
                                }
                                value={getTaskSyncFileStatusTotalByStatusAndFolder(taskSyncFileStatuses, [
                                    'SUCCESSFUL',
                                ])}
                                className={`${styles.stats} ${styles.successful}`}
                            />
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(
                                    taskSyncFileStatuses,
                                    ['SUCCESSFUL'],
                                    true,
                                )}{' '}
                                <FormattedMessage id="taskSync.status.folders" />
                            </p>
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(
                                    taskSyncFileStatuses,
                                    ['SUCCESSFUL'],
                                    false,
                                )}{' '}
                                <FormattedMessage id="taskSync.status.files" />
                            </p>
                            <p>
                                <FileSizeComponent
                                    value={getTaskSyncFileStatusSizeByStatus(taskSyncFileStatuses, ['SUCCESSFUL'])}
                                />
                            </p>
                        </Link>
                    </Tooltip>
                </Col>
                <Col span={3}>
                    <Tooltip title={<FormattedMessage id="taskSync.status.failed.tooltip" />}>
                        <Link to={`/task-syncs/${taskSync?.id}/task-sync-file-targets?status=FAILED`}>
                            <Statistic
                                title={
                                    <span className={styles.title}>
                                        <FormattedMessage id="FAILED" />
                                    </span>
                                }
                                value={getTaskSyncFileStatusTotalByStatusAndFolder(taskSyncFileStatuses, ['FAILED'])}
                                className={`${styles.stats} ${styles.failed}`}
                            />
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(taskSyncFileStatuses, ['FAILED'], true)}{' '}
                                <FormattedMessage id="taskSync.status.folders" />
                            </p>
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(taskSyncFileStatuses, ['FAILED'], false)}{' '}
                                <FormattedMessage id="taskSync.status.files" />
                            </p>
                            <p>
                                <FileSizeComponent
                                    value={getTaskSyncFileStatusSizeByStatus(taskSyncFileStatuses, ['FAILED'])}
                                />
                            </p>
                        </Link>
                    </Tooltip>
                </Col>
                <Col span={3}>
                    <Tooltip title={<FormattedMessage id="taskSync.status.error.tooltip" />}>
                        <Link to={`/task-syncs/${taskSync?.id}/task-sync-file-targets?status=ERROR`}>
                            <Statistic
                                title={
                                    <span className={styles.title}>
                                        <FormattedMessage id="ERROR" />
                                    </span>
                                }
                                value={getTaskSyncFileStatusTotalByStatusAndFolder(taskSyncFileStatuses, ['ERROR'])}
                                className={`${styles.stats} ${styles.error}`}
                            />
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(taskSyncFileStatuses, ['ERROR'], true)}{' '}
                                <FormattedMessage id="taskSync.status.folders" />
                            </p>
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(taskSyncFileStatuses, ['ERROR'], false)}{' '}
                                <FormattedMessage id="taskSync.status.files" />
                            </p>
                            <p>
                                <FileSizeComponent
                                    value={getTaskSyncFileStatusSizeByStatus(taskSyncFileStatuses, ['ERROR'])}
                                />
                            </p>
                        </Link>
                    </Tooltip>
                </Col>
                <Col span={3} className={styles.empty}>
                    <Tooltip title={<FormattedMessage id="taskSync.status.empty.tooltip" />}>
                        <Link to={`/task-syncs/${taskSync?.id}/task-sync-file-targets?status=EMPTY`}>
                            <Statistic
                                title={
                                    <span className={styles.title}>
                                        <FormattedMessage id="EMPTY" />
                                    </span>
                                }
                                value={getTaskSyncFileStatusTotalByStatusAndFolder(taskSyncFileStatuses, ['EMPTY'])}
                                className={`${styles.stats} ${styles.title}`}
                            />
                            <p>
                                {getTaskSyncFileStatusCountByStatusAndFolder(taskSyncFileStatuses, ['EMPTY'], true)}{' '}
                                <FormattedMessage id="taskSync.status.folders" />
                            </p>
                        </Link>
                    </Tooltip>
                </Col>
                <Col span={4} className={styles.button}>
                    <Tooltip title={<FormattedMessage id="taskSync.status.retry.tooltip" values={{ max }} />}>
                        <Button
                            type="primary"
                            size="large"
                            icon={<Icon component={RefreshSvg} />}
                            loading={loading === 'retrying'}
                            onClick={retry}
                            disabled={!isRetryEnabled(taskSync, taskSyncFileStatuses) || loading === 'retrying'}
                        >
                            <FormattedMessage id="button.retry" tagName="span" />
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
            <Divider plain orientation="left"></Divider>
        </div>
    );
};
export default TaskSyncStatusComponent;

interface Props {
    taskSync: TaskSync | undefined;
    taskSyncFileStatuses: TaskSyncFileStatus[];
    onRetry: () => Promise<void>;
}

/*** FUNCTIONS ***/

const isRetryEnabled = (taskSync: TaskSync | undefined, taskSyncFileStatuses: TaskSyncFileStatus[]): boolean => {
    return (
        !!taskSync &&
        !!taskSync.status &&
        ['ERROR', 'FAILED'].includes(taskSync.status) &&
        taskSyncFileStatuses.length > 1 &&
        taskSyncFileStatuses
            .map((tsfs) => tsfs.status)
            .every((s) => s === 'SUCCESSFUL' || s === 'ERROR' || s === 'FAILED' || s === 'EMPTY') &&
        taskSyncFileStatuses.map((tsfs) => tsfs.status).some((s) => s === 'ERROR' || s === 'FAILED')
    );
};

const getTaskSyncFileStatusByStatusAndFolder = (
    taskSyncFileStatuses: TaskSyncFileStatus[],
    statuses: TaskStatusType[],
    folder: boolean,
): TaskSyncFileStatus[] => {
    return taskSyncFileStatuses
        .filter((tsfs) => statuses.includes(tsfs.status))
        .filter((tsfs) => tsfs.folder === folder);
};

const getTaskSyncFileStatusSizeByStatus = (
    taskSyncFileStatuses: TaskSyncFileStatus[],
    statuses: TaskStatusType[],
): number => {
    return getTaskSyncFileStatusByStatusAndFolder(taskSyncFileStatuses, statuses, false)
        .map((tsfs) => tsfs.size)
        .reduce((partialSum, a) => partialSum + a, 0);
};

const getTaskSyncFileStatusCountByStatusAndFolder = (
    taskSyncFileStatuses: TaskSyncFileStatus[],
    statuses: TaskStatusType[],
    folder: boolean,
): number => {
    return getTaskSyncFileStatusByStatusAndFolder(taskSyncFileStatuses, statuses, folder)
        .map((tsfs) => tsfs.count)
        .reduce((partialSum, a) => partialSum + a, 0);
};

const getTaskSyncFileStatusTotalByStatusAndFolder = (
    taskSyncFileStatuses: TaskSyncFileStatus[],
    statuses: TaskStatusType[],
): number | undefined => {
    const foldersCount = getTaskSyncFileStatusCountByStatusAndFolder(taskSyncFileStatuses, statuses, true);
    const filesCount = getTaskSyncFileStatusCountByStatusAndFolder(taskSyncFileStatuses, statuses, false);

    return foldersCount + filesCount;
};
