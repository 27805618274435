import { Schedule } from '../model/entities';
import { Page } from '../model/elements';
import adminApiAxios from './CustomAxios';
import moment from 'moment';

class ScheduleApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        connectionId: number,
        searchText?: string,
    ): Promise<Page<Schedule>> => {
        const response = await adminApiAxios.get<Page<Schedule>>('/schedules', {
            params: { page, size, sortField, sortOrder, connectionId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((schedule) => this.loadDates(schedule));

        return response.data;
    };

    get = async (id: number): Promise<Schedule> => {
        const response = await adminApiAxios.get<Schedule>(`/schedules/${id}`);
        this.loadDates(response.data);
        return response.data;
    };

    create = async (schedule: Schedule): Promise<Schedule> => {
        const response = await adminApiAxios.post<Schedule>('/schedules', schedule);
        this.loadDates(response.data);
        return response.data;
    };

    update = async (schedule: Schedule): Promise<Schedule> => {
        const response = await adminApiAxios.put<Schedule>(`/schedules/${schedule.id}`, schedule);
        this.loadDates(response.data);
        return response.data;
    };

    delete = async (schedule: Schedule): Promise<void> => {
        await adminApiAxios.delete(`/schedules/${schedule.id}`);
    };

    /**
     * Loads the dates of a schedule.
     * @param schedule - the schedule
     */
    private loadDates(schedule: Schedule) {
        schedule.started = schedule.started && moment.utc(schedule.started).local();
        schedule.triggered = schedule.triggered && moment.utc(schedule.triggered).local();
        schedule.audit!.created = schedule.audit!.created && moment.utc(schedule.audit!.created).local();
        schedule.audit!.updated = schedule.audit!.updated && moment.utc(schedule.audit!.updated).local();
    }
}

const scheduleApi: ScheduleApi = new ScheduleApi();
export default scheduleApi;
