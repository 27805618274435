/* eslint-disable react-hooks/exhaustive-deps, no-useless-escape */
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import { useHistory } from 'react-router';

import { Hub, Project } from '../../../model/entities';

import hubApi from '../../../api/HubApi';
import projectApi from '../../../api/ProjectApi';
import { message } from 'antd';
import { ProviderType } from '../../../model/types';

class SetupProjects {
    platform: ProviderType | undefined;
    setPlatform: React.Dispatch<React.SetStateAction<ProviderType | undefined>> = () => {};

    hubs: Hub[] = [];
    isHubsLoading: boolean = false;
    selectedHubId: string | undefined;
    setSelectedHubId: React.Dispatch<React.SetStateAction<string | undefined>> = () => {};
    selectedHub: Hub | undefined;

    isProjectsLoading: boolean = false;
    isProjectsExporting: boolean = false;
    projects: Project[] = [];

    handleSearch: (searchValue: string) => void = (searchValue: string) => {};
    exportSpreadSheet: () => void = () => {};
    selectedHubIndex: number = 0;
}

export const SetupProjectsContext = createContext(new SetupProjects());

export const SetupProjectsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const history = useHistory();
    const parsed = queryString.parse(history.location.search);

    const [isHubsLoading, setIsHubsLoading] = useState(false);
    const [hubs, setHubs] = useState<Hub[]>([]);
    const [platform, setPlatform] = useState<ProviderType | undefined>(
        parsed.platform ? (`${parsed.platform}` as ProviderType) : undefined,
    );
    const [selectedHubId, setSelectedHubId] = useState<string | undefined>(
        parsed.hubId ? `${parsed.hubId}` : undefined,
    );
    const [selectedHub, setSelectedHub] = useState<Hub>();

    const [isProjectsExporting, setIsProjectsExporting] = useState(false);
    const [isProjectsLoading, setIsProjectsLoading] = useState(false);
    const [projects, setProjects] = useState<Project[]>([]);
    const [searchText, setSearchText] = useState<string>();

    const exportSpreadSheet = async () => {
        try {
            setIsProjectsExporting(true);
            const selectedHub = hubs[hubs.findIndex((hub) => hub.id === selectedHubId)];
            await projectApi.exportSpreadSheet(selectedHub.provider, selectedHub.id, selectedHub.name, searchText);
        } catch (error) {
            message.error('Cannot export spreadsheet');
        } finally {
            setIsProjectsExporting(false);
        }
    };

    // load hubs list
    const loadHubs = async () => {
        try {
            setIsHubsLoading(true);
            const fetchedHubs = await hubApi.list(true);
            setHubs(fetchedHubs);
            if (fetchedHubs.length > 0 && !parsed.hubId) {
                setSelectedHubId(fetchedHubs[0].id);
            }
        } catch (e) {
            console.log('Load hubs failed:', e);
        } finally {
            setIsHubsLoading(false);
        }
    };

    useEffect(() => {
        loadHubs();
    }, []);

    // load projects list
    const loadProjects = useCallback(
        async (hubId: string, searchValue?: string) => {
            try {
                setIsProjectsLoading(true);
                const fetchedProjects = await projectApi.list(platform!, hubId, true, searchValue);
                setProjects(fetchedProjects.map((project) => ({ ...project, platform: project.platform || 'BIM360' })));
            } catch (e) {
                console.log('Load projects failed:', e);
            } finally {
                setIsProjectsLoading(false);
            }
        },
        [platform],
    );

    const handleSearch = debounce(async (searchValue: string) => {
        await loadProjects(selectedHubId!, searchValue);
        setSearchText(searchValue);
    }, 200);

    useEffect(() => {
        if (selectedHub?.provider !== 'ACC') {
            setPlatform('BIM360');
        }
        if (selectedHub && !platform) {
            setPlatform(selectedHub.provider);
        }
    }, [selectedHub]);

    useEffect(() => {
        if (!hubs) return;
        if (selectedHubId) {
            loadProjects(selectedHubId);
        }
        const selectedHubIndex = hubs.findIndex((hub) => hub.id === selectedHubId);
        setSelectedHub(hubs[selectedHubIndex]);
    }, [selectedHubId, hubs, platform]);

    useEffect(() => {
        if (parsed.platform) {
            setPlatform(parsed.platform as ProviderType);
        }
    }, [parsed]);

    const value = {
        platform,
        setPlatform,
        isHubsLoading,
        hubs,
        selectedHubId,
        setSelectedHubId,
        isProjectsLoading,
        projects,
        selectedHub,
        handleSearch,
        exportSpreadSheet,
        isProjectsExporting,
        selectedHubIndex: hubs.findIndex((hub) => hub.id === selectedHubId),
    };
    return <SetupProjectsContext.Provider value={value}>{children}</SetupProjectsContext.Provider>;
};

export const useSetupProjects = () => {
    const context = useContext(SetupProjectsContext);
    return context;
};
