import Icon from '@ant-design/icons';
import { Button, List, Space } from 'antd';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { TaskProjectIssue } from '../../../../../model/entities';
import { ReactComponent as ArrowLeftSvg } from '../../../../../resources/images/arrow-left.svg';
import styles from './ResultComponent.module.scss';

/**
 * Returns the project issues import result component.
 * @props the props
 * @returns the project issues import result component.
 */
const ResultComponent = (props: Props): React.ReactElement => {
    const { result } = props;
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS ***/

    return (
        <>
            <FailuresComponent result={result} />
            <JobsComponent result={result} />
            <div className={styles.buttons}>
                <Space>
                    <Link to={`/setup/project-issues`}>
                        <Button type="primary" size="large" icon={<Icon component={ArrowLeftSvg} />}>
                            <FormattedMessage id="projectIssues.import.steps.3.back" tagName="span" />
                        </Button>
                    </Link>
                    <Link to={`/tasks`}>
                        <Button type="primary" size="large">
                            <FormattedMessage id="projectIssues.import.steps.3.tasks" tagName="span" />
                        </Button>
                    </Link>
                </Space>
            </div>
        </>
    );
};

export default ResultComponent;

interface Props {
    result: TaskProjectIssue;
}

const FailuresComponent = (props: Props): React.ReactElement => {
    const { result } = props;

    /*** HOOKS ***/

    const intl = useIntl();

    /*** COMPONENTS ***/

    // We leave this like a list so we don't change how we display the results from other imports
    const results = [result];

    if (result.status === 'ERROR') {
        return (
            <List
                header={
                    <h2>
                        <FormattedMessage id="projectIssues.import.steps.3.failure" />
                    </h2>
                }
                dataSource={results}
                renderItem={(taskProjectIssue, index) => {
                    return (
                        <List.Item key={index}>
                            <List.Item.Meta title={taskProjectIssue.name} />
                            <span className={styles.error}>{intl.formatMessage({ id: taskProjectIssue.status })}</span>
                        </List.Item>
                    );
                }}
                className={styles.failure}
            />
        );
    } else {
        return <></>;
    }
};

const JobsComponent = (props: Props): React.ReactElement => {
    const { result } = props;

    /*** HOOKS ***/

    const intl = useIntl();

    /*** COMPONENTS ***/

    // We leave this like a list so we don't change how we display the results from other imports
    const results = [result];

    if (result.status === 'IN_PROGRESS') {
        return (
            <List
                header={
                    <h2>
                        <FormattedMessage id="projectIssues.import.steps.3.jobs" />
                    </h2>
                }
                dataSource={results}
                renderItem={(taskProjectIssue, index) => {
                    return (
                        <List.Item key={index}>
                            <List.Item.Meta title={taskProjectIssue.name} />
                            <span className={styles.inProgress}>
                                {intl.formatMessage({ id: taskProjectIssue.status })}
                            </span>
                        </List.Item>
                    );
                }}
                className={styles.success}
            />
        );
    } else {
        return <></>;
    }
};
