import Icon, { FileOutlined, FolderFilled, RightOutlined } from '@ant-design/icons';
import { Button, Descriptions, Space, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import taskSyncApi from '../../../../../api/TaskSyncApi';
import taskSyncFileApi from '../../../../../api/TaskSyncFileApi';
import taskSyncLinkApi from '../../../../../api/TaskSyncLinkApi';
import FileSizeComponent from '../../../../../components/FileSizeComponent/FileSizeComponent';
import LayoutComponent from '../../../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../../../components/TaskStatusComponent/TaskStatusComponent';
import { Page } from '../../../../../model/elements';
import { TaskSync, TaskSyncFile, TaskSyncLink } from '../../../../../model/entities';
import { TaskStatusType } from '../../../../../model/types';
import { ReactComponent as BackSvg } from '../../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../../resources/images/refresh.svg';
import notificationService from '../../../../../services/NotificationService';
import tableService from '../../../../../services/TableService';
import styles from './TaskSyncLinkPage.module.scss';

/**
 * Returns the task sync link page.
 * @returns the task sync link page.
 */
const TaskSyncLinkPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [task, setTask] = useState<TaskSync>();
    const [taskLink, setTaskLink] = useState<TaskSyncLink>();
    const [taskSyncFilesPage, setTaskSyncFilesPage] = useState<Page<TaskSyncFile>>();
    const [loading, setLoading] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>();
    const [parentId, setParentId] = useState<number>();
    const [folders, setFolders] = useState<TaskSyncFile[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);
                const id = +params.id;
                const page = 0;
                const size = taskSyncFilesPage?.size || tableService.pageSize;
                const sortField = taskSyncFilesPage?.sort.field || 'folder';
                const sortOrder = taskSyncFilesPage?.sort.order || true;
                const responses = await Promise.all([
                    taskSyncLinkApi.get(id),
                    listTaskFiles(page, size, sortField, sortOrder, id, searchText, parentId),
                ]);

                const taskLink = responses[0];
                const task = await taskSyncApi.get(taskLink.taskId!);

                setTask(task);
                setTaskLink(taskLink);
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [
        intl,
        params.id,
        parentId,
        searchText,
        taskSyncFilesPage?.size,
        taskSyncFilesPage?.sort.field,
        taskSyncFilesPage?.sort.order,
    ]);

    /*** METHODS ***/

    const list = async () => {
        try {
            setLoading(true);
            const id = +params.id;
            const page = 0;
            const size = taskSyncFilesPage?.size || tableService.pageSize;
            const sortField = taskSyncFilesPage?.sort.field || 'folder';
            const sortOrder = taskSyncFilesPage?.sort.order || true;
            const responses = await Promise.all([
                taskSyncLinkApi.get(id),
                listTaskFiles(page, size, sortField, sortOrder, id, searchText, parentId),
            ]);

            const taskLink = responses[0];
            const task = await taskSyncApi.get(taskLink.taskId!);

            setTask(task);
            setTaskLink(taskLink);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current! - 1;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            listTaskFiles(page, pageSize, sortField, sortOrder, taskLink!.id!, searchText, parentId);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);
            setSearchText(searchText);

            const page = 0;
            const pageSize = taskSyncFilesPage!.size;
            const sortField = taskSyncFilesPage!.sort.field!;
            const sortOrder = taskSyncFilesPage!.sort.order!;
            listTaskFiles(page, pageSize, sortField, sortOrder, taskLink!.id!, searchText, parentId);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const filterByFolder = async (taskSyncFile: TaskSyncFile) => {
        try {
            setLoading(true);
            setParentId(taskSyncFile.id);

            const page = 0;
            const pageSize = taskSyncFilesPage?.size || tableService.pageSize;
            const sortField = taskSyncFilesPage?.sort.field || 'folder';
            const sortOrder = taskSyncFilesPage?.sort.order || true;
            listTaskFiles(page, pageSize, sortField, sortOrder, taskLink!.id!, searchText, taskSyncFile.id);
            const foldersUpdated = folders;
            foldersUpdated.push(taskSyncFile);
            setFolders(foldersUpdated);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listTaskFiles = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskSyncLinkId: number,
        searchText?: string,
        parentId?: number,
    ) => {
        const taskSyncFilesPage = await taskSyncFileApi.list(
            page,
            size,
            sortField,
            sortOrder,
            taskSyncLinkId,
            searchText,
            parentId,
        );
        setTaskSyncFilesPage(taskSyncFilesPage);
    };

    /**
     * Function for updating folder route in the table
     */
    const updateFolderList = (taskSyncFile: TaskSyncFile, buttonClicked: boolean) => {
        try {
            setLoading(true);

            const updatedFolderList: TaskSyncFile[] = folders;

            // We find we have the element in the array from we are going to remove elements
            const indexOfObject = folders.findIndex((object) => {
                return object.id === taskSyncFile.id;
            });

            // Remove from the list the elements from as the select one
            if (buttonClicked) {
                updatedFolderList.splice(indexOfObject, folders.length + 1 - (indexOfObject + 1));
            } else {
                updatedFolderList.splice(indexOfObject + 1, folders.length + 1 - (indexOfObject + 1));
            }

            // Filter the table again by the last element of the list
            const parentId =
                updatedFolderList.length > 0 ? updatedFolderList[updatedFolderList.length - 1].id : undefined;

            const page = 0;
            const pageSize = taskSyncFilesPage?.size || tableService.pageSize;
            const sortField = taskSyncFilesPage?.sort.field || 'folder';
            const sortOrder = taskSyncFilesPage?.sort.order || true;
            listTaskFiles(page, pageSize, sortField, sortOrder, taskLink!.id!, searchText, parentId);

            setFolders(updatedFolderList);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    // TODO: check if this is cleaner than separate in a component
    const getLink = (
        taskSyncFile: TaskSyncFile,
        element: React.ReactElement | string,
    ): React.ReactElement | undefined => {
        if (taskSyncFile.folder) {
            return (
                <span onClick={() => filterByFolder(taskSyncFile)} className={styles.link}>
                    {element}
                </span>
            );
        } else {
            return <Link to={`/task-sync-files/${taskSyncFile.id}`}>{element}</Link>;
        }
    };

    const getTableHeader = () => {
        return folders.map((folder, index) => {
            if (index === 0) {
                return (
                    <>
                        <Button
                            icon={<RightOutlined className={styles.rightArrow} />}
                            type="text"
                            onClick={() => updateFolderList(folder, true)}
                            className={styles.rightArrowButton}
                        ></Button>{' '}
                        <Button type="link" onClick={() => updateFolderList(folder, false)}>
                            {folder.name}
                        </Button>
                    </>
                );
            } else {
                return (
                    <>
                        <RightOutlined className={styles.rightArrowIcon} />{' '}
                        <Button type="link" onClick={() => updateFolderList(folder, false)}>
                            {folder.name}
                        </Button>
                    </>
                );
            }
        });
    };

    /*** COMPONENTS ***/

    const placeholder: string = intl.formatMessage({ id: 'taskLinkFiles.search' });
    const items = taskSyncFilesPage ? taskSyncFilesPage.content : [];
    const columns: ColumnsType<TaskSyncFile> = [
        {
            title: <FormattedMessage id="taskLinkFile.source" />,
            dataIndex: 'folder',
            key: 'folder',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string, taskSyncFile: TaskSyncFile) => {
                if (taskSyncFile.folder) {
                    return getLink(
                        taskSyncFile,
                        <Button icon={<FolderFilled />} type="link">
                            {taskSyncFile.name}
                        </Button>,
                    );
                } else {
                    return getLink(
                        taskSyncFile,
                        <Button icon={<FileOutlined />} type="link">
                            {taskSyncFile.name}
                        </Button>,
                    );
                }
            },
        },
        {
            title: <FormattedMessage id="taskLinkFile.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'created',
            sorter: true,
            width: 240,
            align: 'center',
            render: (value: string, taskSyncFile: TaskSyncFile) =>
                getLink(
                    taskSyncFile,
                    <FormattedDate
                        value={taskSyncFile.audit.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />,
                ),
        },
        {
            title: <FormattedMessage id="taskLinkFile.audit.updated" />,
            dataIndex: 'auditUpdated',
            key: 'updated',
            sorter: true,
            width: 240,
            align: 'center',
            render: (value: string, taskSyncFile: TaskSyncFile) =>
                getLink(
                    taskSyncFile,
                    <FormattedDate
                        value={taskSyncFile.audit.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />,
                ),
        },
        {
            title: <FormattedMessage id="taskLinkFile.status" />,
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            width: 200,
            align: 'center',
            render: (status: TaskStatusType, taskSyncFile: TaskSyncFile) =>
                getLink(taskSyncFile, <TaskStatusComponent status={status} />),
        },
        {
            title: <FormattedMessage id="taskLinkFile.source.size" />,
            dataIndex: 'size',
            key: 'size',
            width: 100,
            align: 'right',
            sorter: true,
            render: (value: number, taskSyncFile: TaskSyncFile) =>
                getLink(taskSyncFile, <FileSizeComponent value={taskSyncFile.size} />),
        },
    ];

    return (
        <LayoutComponent pageId="tasks">
            <Descriptions
                title={
                    <>
                        <span className={styles.title}>
                            #{task?.id} - {task?.name} - <FormattedMessage id="taskLink.title" />
                        </span>
                        <TaskStatusComponent status={taskLink?.status} />
                        <Space>
                            <Tooltip title={<FormattedMessage id="button.back" />}>
                                <Link to={`/task-syncs/${task?.id}`}>
                                    <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.refresh" />}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    icon={<Icon component={RefreshSvg} />}
                                    onClick={list}
                                />
                            </Tooltip>
                        </Space>
                    </>
                }
                column={2}
                size="small"
                className={styles.summary}
            >
                <Descriptions.Item label={<FormattedMessage id="task.source" />}>
                    {task?.source?.provider} &gt; {task?.source?.name} &gt; {taskLink?.source?.project?.name} &gt;{' '}
                    {taskLink?.source?.folder?.name}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.target" />}>
                    {task?.target?.provider} &gt; {task?.target?.name}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                    <FormattedDate
                        value={taskLink?.audit?.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                    <FormattedDate
                        value={taskLink?.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
            </Descriptions>{' '}
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
            </div>
            {getTableHeader()}
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(taskSyncFilesPage, { position: ['bottomRight'] })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </LayoutComponent>
    );
};

export default TaskSyncLinkPage;

type ParamsType = { id: string };
