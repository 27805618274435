import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Spin, Button } from 'antd';
import { ReactComponent as ArrowLeftSvg } from '../../../../resources/images/arrow-left.svg';
import { EntityWithStatus, Project, EntityWithJob } from '../../../../model/entities';
import { useSetupProjectsImport } from './SetupProjectsImportContext';
import Icon from '@ant-design/icons';
import styles from './ImportPage.module.scss';

function ResultComponent() {
    const { results, isResultsLoading, handleFinishStep, platform } = useSetupProjectsImport();

    const getResultMessageComponent = (result: EntityWithStatus<EntityWithJob<Project>>) => {
        if (isResultsLoading) {
            return <Spin size="small" />;
        }
        let messages: React.ReactNode = [];

        if (result.status.error || result.status.type === 'INTERNAL_ERROR') {
            messages = [<p>{result.status.message}</p>];
        } else {
            messages = [<p>{result.entity.entity.status}</p>];
        }

        return (
            <div
                className={
                    result.status.error || result.status.type === 'INTERNAL_ERROR' ? styles.error : styles.success
                }
            >
                {messages}
            </div>
        );
    };

    return (
        <>
            <div className={styles.resultbox}>
                <div className={styles.resultlistbox}>
                    {results.map((result, index) => {
                        return (
                            <div className={styles.resultlistitem} key={`result-${index}`}>
                                <div>{`${result.entity.entity.platform || platform} > ${
                                    result.entity.entity.name
                                }`}</div>
                                {getResultMessageComponent(result)}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={styles.laststepbuttonbox}>
                <Button
                    className={styles.backbutton}
                    type="primary"
                    size="large"
                    onClick={handleFinishStep}
                    loading={false}
                    icon={<Icon component={ArrowLeftSvg} />}
                >
                    <FormattedMessage id="projects.new.import.excel.steps.3.back" />
                </Button>
            </div>
        </>
    );
}

export default ResultComponent;
