import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { useSetupProjects } from './SetupProjectsContext';
import { Project } from '../../../model/entities';
import styles from './SetupProjectsPage.module.scss';

function SetupProjectsPageBody() {
    const { projects, selectedHub } = useSetupProjects();
    const columns: ColumnsType<Project> = [
        {
            title: <FormattedMessage id="projects.field.1" />,
            key: 'name',
            dataIndex: 'name',
            width: 300,
            sorter: true,
        },
        {
            title: <FormattedMessage id="projects.field.2" />,
            key: 'jobNumber',
            dataIndex: 'jobNumber',
            render: (_, item) => item.jobNumber || '-',
            width: 200,
            sorter: true,
        },
        {
            title: <FormattedMessage id="projects.field.3" />,
            render: () => selectedHub?.name || '-',
            width: 200,
        },
        {
            title: <FormattedMessage id="projects.field.4" />,
            key: 'type',
            dataIndex: 'type',
            width: 300,
            sorter: true,
        },
        {
            title: <FormattedMessage id="projects.field.5" />,
            key: 'platform',
            dataIndex: 'platform',
            width: 100,
            sorter: true,
        },
    ];

    return (
        <div className={styles.pagebody}>
            <Table rowKey="id" sortDirections={['ascend', 'descend']} dataSource={projects} columns={columns} />
        </div>
    );
}

export default SetupProjectsPageBody;
