import configuration from '../configs/IConfiguration';
import { CustomAuth, Organization } from '../model/entities';
import AwsAuthService from './AwsAuthService';
import AzureAuthService from './AzureAuthService';

export interface IAuthService {
    getAuth: () => Promise<CustomAuth | undefined>;
    getAuthByOrganization: (organization: Organization) => Promise<CustomAuth | undefined>;
    getCachedAuth: () => CustomAuth | undefined;
    getAccessToken: () => Promise<string | undefined>;
    getOrganizationId: () => string | undefined;
    signIn: () => Promise<void>;
    signOut: () => Promise<void>;
    isAuthenticated: () => Promise<boolean>;
}

// Export authService for the current platform
let authService: IAuthService;
if (configuration.getPlatform() === 'azure') {
    authService = new AzureAuthService();
} else {
    authService = new AwsAuthService();
}
export default authService;
