import axios from 'axios';
import qs from 'qs';
import { AutodeskOauthResponse } from '../model/entities';

class AutodeskApi {
    getToken = async (
        code: string,
        client_id: string,
        redirect_uri: string,
        code_verifier: string,
    ): Promise<AutodeskOauthResponse> => {
        const grant_type = 'authorization_code';
        const response = await axios.post<AutodeskOauthResponse>(
            'https://developer.api.autodesk.com/authentication/v2/token',
            qs.stringify({ code, grant_type, client_id, redirect_uri, code_verifier }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        );
        return response.data;
    };

    refreshToken = async (client_id: string, refresh_token: string, scope: string): Promise<AutodeskOauthResponse> => {
        const grant_type = 'refresh_token';
        const response = await axios.post<AutodeskOauthResponse>(
            'https://developer.api.autodesk.com/authentication/v2/token',
            qs.stringify({ grant_type, client_id, refresh_token, scope }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        );
        return response.data;
    };
}

const autodeskApi: AutodeskApi = new AutodeskApi();
export default autodeskApi;
