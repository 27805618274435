import Icon, { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Form, Modal, Space, Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import hubApi from '../../../../../api/HubApi';
import projectIssueApi from '../../../../../api/ProjectIssueApi';
import projectIssueAttachmentApi from '../../../../../api/ProjectIssueAttachmentApi';
import taskProjectIssuesApi from '../../../../../api/TaskProjectIssueApi';
import { Hub, IssueImport, Project, ProjectIssue, TaskProjectIssue } from '../../../../../model/entities';
import { providerTypes } from '../../../../../model/types';
import { ReactComponent as CheckmarkSvg } from '../../../../../resources/images/checkmark.svg';
import { ReactComponent as CloseSvg } from '../../../../../resources/images/close-outline.svg';
import { ReactComponent as EditSvg } from '../../../../../resources/images/edit.svg';
import { ReactComponent as SaveSvg } from '../../../../../resources/images/save.svg';
import notificationService from '../../../../../services/NotificationService';
import EditableCell from './EditableCell/EditableCell';
import styles from './SummaryComponent.module.scss';
import CustomContext from '../../../../../context/CustomContext';

/**
 * Returns the project issues import page summary.
 * @props the props
 * @returns the project issues import page summary.
 */
const SummaryComponent = (props: Props): React.ReactElement => {
    const { hubId, issueImports, onBack, onSaveIssues, projects, project } = props;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const { auth } = useContext(CustomContext);
    const organizationId = auth!.organization!.id;
    const [issueImportsUpdated, setIssueImportsUpdated] = useState<IssueImport[]>([]);
    const [issues, setIssues] = useState<ProjectIssue[]>([]);
    const [editingKey, setEditingKey] = useState<string>();
    const [hub, setHub] = useState<Hub>();
    const [loading, setLoading] = useState<'loading' | 'saving'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                if (project) {
                    const issueImportsUpdated = issueImports.map((im) => {
                        im.uuid = uuidv4();
                        return im;
                    });

                    const responses = await Promise.all([
                        projectIssueApi.listAll('title', true, hubId, project.id),
                        hubApi.list(true),
                    ]);
                    const issues = responses[0];
                    const hubs = responses[1];
                    const hub = hubs.find((h) => h.id === hubId);

                    setIssueImportsUpdated(issueImportsUpdated);
                    setIssues(issues);
                    setHub(hub);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [hubId, intl, issueImports, project]);

    /*** METHODS ***/

    const upload = async () => {
        try {
            setLoading('saving');

            if (issueImportsUpdated.some((i) => !i.targetProjectId || !i.id)) {
                Modal.confirm({
                    title: intl.formatMessage({ id: 'projectIssues.import.steps.2.errors' }),
                    icon: <ExclamationCircleFilled />,
                    cancelButtonProps: { hidden: true },
                });
            } else {
                const taskProjectIssue: TaskProjectIssue = {
                    organizationId,
                    sourceHub: hub!,
                    sourceProject: project!,
                    issues: issueImportsUpdated.map((issueImport) => {
                        return {
                            issueId: issueImport.id!,
                            title: issueImport.issueTitle!,
                            identifier: issueImport.identifier!,
                            action: issues.find((i) => i.id === issueImport.id),
                            attachments: [],
                            targetHub: hub!,
                            targetProject: projects.find((p) => p.id === issueImport.targetProjectId)!,
                        };
                    }),
                };

                // Load attachments
                await loadAttachments(taskProjectIssue);

                const result = await taskProjectIssuesApi.create(taskProjectIssue);

                onSaveIssues(result);
            }
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const loadAttachments = async (task: TaskProjectIssue) => {
        for (const taskProjectIssueAction of task.issues) {
            const attachments = await projectIssueAttachmentApi.list(
                task.sourceHub.id,
                task.sourceProject.id,
                taskProjectIssueAction.issueId,
            );
            taskProjectIssueAction.attachments = attachments.map((a) => ({ name: a.name, attachmentId: a.id! }));
        }
    };

    const isEditing = (issueImport: IssueImport) => {
        return `${issueImport.uuid}` === editingKey;
    };

    const edit = (issueImport: IssueImport) => {
        const targetProjectPlatform = providerTypes
            .filter((p) => ['ACC', 'BIM360'].includes(p))
            .filter((p) => p === issueImport.targetProjectPlatform)
            .find(Boolean);

        form.setFieldsValue({
            targetProjectPlatform,
            targetProjectId: issueImport.targetProjectId,
            id: issueImport.id,
        });
        setEditingKey(issueImport.uuid);
    };

    const save = async (key: React.Key) => {
        try {
            const values = await form.validateFields();

            const issueImports = [...issueImportsUpdated];
            const issueImport: IssueImport = {
                id: values.id,
                identifier: issues.find((i) => i.id === values.id)!.identifier,
                issueTitle: issues.find((i) => i.id === values.id)!.title,
                targetHubId: hubId,
                targetProjectId: values.targetProjectId,
                targetProjectName: projects.find((p) => p.id === values.targetProjectId)!.name,
                targetProjectPlatform: (projects.find((p) => p.id === values.targetProjectId)!.platform ||
                    project!.platform)!,
            };

            const index = issueImports.findIndex((item) => key === item.uuid);

            if (index > -1) {
                const item = issueImports[index];
                issueImports.splice(index, 1, {
                    ...item,
                    ...issueImport,
                });
                setIssueImportsUpdated(issueImports);
                setEditingKey(undefined);
            } else {
                issueImports.push(issueImport);
                setIssueImportsUpdated(issueImports);
                setEditingKey(undefined);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    /*** COMPONENTS ***/

    const columns: EditableColumnsType<IssueImport> = [
        {
            title: <FormattedMessage id="projectIssues.platform" />,
            dataIndex: 'targetProjectPlatform',
            key: 'targetProjectPlatform',
            width: 120,
            editable: true,
            render: (value: string, issueImport: IssueImport) => getTargetProjectPlatformColumn(issueImport),
        },
        {
            title: <FormattedMessage id="projectIssues.targetProject" />,
            dataIndex: 'targetProjectId',
            key: 'targetProjectId',
            width: 550,
            editable: true,
            render: (value: string, issueImport: IssueImport) => getTargetProjectColumn(issueImport, projects, project),
        },
        {
            title: <FormattedMessage id="projectIssues.issue" />,
            dataIndex: 'id',
            key: 'id',
            editable: true,
            render: (value: string, issueImport: IssueImport) => getIdColumn(issueImport, issues),
        },

        {
            dataIndex: 'action',
            align: 'center',
            width: 100,
            render: (value: any, issueImport: IssueImport) => {
                const editable = isEditing(issueImport);
                return editable ? (
                    <Space size={0}>
                        <Button
                            type="link"
                            size="large"
                            icon={<Icon component={CheckmarkSvg} />}
                            onClick={() => save(issueImport.uuid!)}
                        />
                        <Button
                            type="link"
                            size="large"
                            icon={<Icon component={CloseSvg} />}
                            onClick={() => setEditingKey(undefined)}
                        />
                    </Space>
                ) : (
                    <Button
                        type="link"
                        size="large"
                        icon={<Icon component={EditSvg} />}
                        disabled={!!editingKey}
                        onClick={() => edit(issueImport)}
                    />
                );
            },
        },
    ];

    const mergedColumns: any = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (issueImport: IssueImport) => ({
                identifier: col.key,
                editing: isEditing(issueImport),
                projects: projects,
                issues: issues,
                sourceProject: project,
                form,
            }),
        };
    });

    return (
        <>
            <Form form={form} component={false}>
                <h3 className={styles.subtitle}>
                    <FormattedMessage id="projectIssues.import.steps.2.issues" />
                </h3>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    dataSource={issueImportsUpdated}
                    columns={mergedColumns}
                    rowKey={(taskIssue) => taskIssue.uuid!}
                    sortDirections={['ascend']}
                    showSorterTooltip={false}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: () => setEditingKey(undefined),
                        size: 'small',
                        hideOnSinglePage: true,
                    }}
                    loading={loading === 'loading'}
                />
            </Form>
            <Space className={styles.buttons}>
                <Button
                    type="primary"
                    size="large"
                    onClick={upload}
                    icon={<Icon component={SaveSvg} />}
                    loading={loading === 'saving'}
                >
                    <FormattedMessage id="button.save" tagName="span" />
                </Button>
                <Button type="text" size="large" onClick={onBack}>
                    <FormattedMessage id="button.back" tagName="span" />
                </Button>
            </Space>
        </>
    );
};

export default SummaryComponent;

interface Props {
    hubId: string;
    project?: Project;
    projects: Project[];
    issueImports: IssueImport[];
    onSaveIssues: (result: TaskProjectIssue) => void;
    onBack: () => void;
}

type EditableColumnsType<RecordType = unknown> = EditableColumnType<RecordType>[];

interface EditableColumnType<RecordType> extends ColumnType<RecordType> {
    editable?: boolean;
}

const getTargetProjectPlatformColumn = (issueImport: IssueImport) => {
    const allowedProviderTypes = providerTypes.filter((p) => ['ACC', 'BIM360'].includes(p));
    if (allowedProviderTypes.some((platform) => platform === issueImport.targetProjectPlatform)) {
        return issueImport.targetProjectPlatform;
    } else {
        return (
            <>
                <div>{issueImport.targetProjectPlatform}</div>
                <div className={styles.notFound}>
                    <FormattedMessage id="projectIssues.import.steps.2.notFound" />
                </div>
            </>
        );
    }
};

const getTargetProjectColumn = (issueImport: IssueImport, projects: Project[], project?: Project) => {
    if (projects.some((project) => project.id === issueImport.targetProjectId)) {
        return issueImport.targetProjectName;
    } else if (issueImport.targetProjectName === project?.name) {
        return (
            <>
                <div>{issueImport.targetProjectName}</div>
                <div className={styles.notFound}>
                    <FormattedMessage id="projectIssues.import.steps.2.errorSourceProject" />
                </div>
            </>
        );
    } else {
        return (
            <>
                <div>{issueImport.targetProjectName}</div>
                <div className={styles.notFound}>
                    <FormattedMessage id="projectIssues.import.steps.2.notFound" />
                </div>
            </>
        );
    }
};

const getIdColumn = (issueImport: IssueImport, issues: ProjectIssue[]) => {
    if (issues.some((issue) => issue.id === issueImport.id)) {
        return (
            <div>
                {issueImport.identifier} - {issueImport.issueTitle}
            </div>
        );
    } else {
        return (
            <>
                <div>
                    {issueImport.identifier} - {issueImport.issueTitle}
                </div>
                <div className={styles.notFound}>
                    <FormattedMessage id="projectIssues.import.steps.2.notFound" />
                </div>
            </>
        );
    }
};
