import Icon from '@ant-design/icons';
import { Button, Form, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import companyApi from '../../../../../api/CompanyApi';
import projectMemberApi from '../../../../../api/ProjectMemberApi';
import roleApi from '../../../../../api/RoleApi';
import { Company, EntityWithStatus, Project, ProjectMember, Role, Service } from '../../../../../model/entities';
import { ProjectMemberProductType } from '../../../../../model/types';
import { ReactComponent as CheckmarkSvg } from '../../../../../resources/images/checkmark.svg';
import { ReactComponent as CloseSvg } from '../../../../../resources/images/close-outline.svg';
import { ReactComponent as EditSvg } from '../../../../../resources/images/edit.svg';
import { ReactComponent as SaveSvg } from '../../../../../resources/images/save.svg';
import notificationService from '../../../../../services/NotificationService';
import EditableCell from './EditableCell/EditableCell';
import styles from './SummaryComponent.module.scss';

/**
 * Returns the project members import page summary.
 * @props the props
 * @returns the project members import page summary.
 */
const SummaryComponent = (props: Props): React.ReactElement => {
    const { hubId, onBack, onSaveMembers, projectMembers, projects } = props;
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const [projectMembersUpdated, setProjectMembersUpdated] = useState<ProjectMember[]>([]);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [roles, setRoles] = useState<Role[]>([]);
    const [editingKey, setEditingKey] = useState<string>();
    const [saving, setSaving] = useState<boolean>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const projectMembersUpdated = [...projectMembers];
                const response = await Promise.all([companyApi.list(hubId), roleApi.list(hubId, projects[0].id)]);
                const companies = response[0];
                const roles = response[1];
                setProjectMembersUpdated(projectMembersUpdated);
                setCompanies(companies);
                setRoles(roles);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [hubId, intl, projectMembers, projects]);

    /*** METHODS ***/

    const upload = async () => {
        try {
            setSaving(true);
            const projectMembersWithProject: ProjectMember[] = [];
            let platform: 'BIM360' | 'ACC' = 'BIM360';
            for (const project of projects) {
                if (project.platform === 'ACC') {
                    platform = 'ACC';
                }
                for (const projectMember of projectMembersUpdated) {
                    projectMember.services = projectMember.services.filter(
                        (s) => s.access === 'member' || s.access === 'administrator',
                    );
                    projectMembersWithProject.push(
                        Object.assign({}, projectMember, {
                            project: {
                                id: project.id,
                                name: project.name,
                                platform: project.platform,
                            },
                        }),
                    );
                }
            }

            const result = await projectMemberApi.import(projectMembersWithProject, hubId, platform);

            onSaveMembers(result);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setSaving(false);
        }
    };

    const isEditing = (projectMember: ProjectMember) => {
        return projectMember.email === editingKey;
    };

    const edit = (projectMember: ProjectMember) => {
        form.setFieldsValue({
            id: projectMember.id,
            email: projectMember.email,
            company: projectMember.company,
            projectAdmin: projectMember.projectAdmin,
            roles: projectMember.roles.map((r) => r.id),
            products: projectMember.services
                .filter((s) => s.access === 'member' || s.access === 'administrator')
                .map((s) => s.name),
            bim360: undefined,
        });
        setEditingKey(projectMember.email);
    };

    const save = async (key: React.Key) => {
        try {
            const values = await form.validateFields();
            const productsList: Service[] =
                values.products.length > 0
                    ? values.products.map((item: ProjectMemberProductType) => {
                          return { name: item, access: values.projectAdmin === true ? 'administrator' : 'member' };
                      })
                    : [];
            const projectMember: ProjectMember = {
                email: values.email,
                company: values.company,
                roles: roles.filter((r) => values.roles.includes(r.id)),
                projectAdmin: values.projectAdmin,
                services: productsList,
                siblings: [],
            };

            const projectMembers = [...projectMembersUpdated];
            const index = projectMembers.findIndex((item) => key === item.email);
            if (index > -1) {
                const item = projectMembers[index];
                projectMembers.splice(index, 1, {
                    ...item,
                    ...projectMember,
                });
                setProjectMembersUpdated(projectMembers);
                setEditingKey(undefined);
            } else {
                projectMembers.push(projectMember);
                setProjectMembersUpdated(projectMembers);
                setEditingKey(undefined);
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    /*** COMPONENTS ***/

    const columns: EditableColumnsType<ProjectMember> = [
        {
            title: <FormattedMessage id="projectMember.email" />,
            dataIndex: 'email',
            key: 'email',
            width: 350,
            editable: true,
        },
        {
            title: <FormattedMessage id="projectMember.company" />,
            dataIndex: ['company', 'id'],
            key: 'company',
            editable: true,
            width: 200,
            render: (value: string) => companies.find((c) => c.id === value)?.name,
        },
        {
            title: <FormattedMessage id="projectMember.projectAdmin" />,
            dataIndex: 'projectAdmin',
            key: 'projectAdmin',
            editable: true,
            width: 250,
            align: 'center',
            render: (value: boolean, projectMember: ProjectMember) =>
                projectMember.id ? (
                    '-'
                ) : value ? (
                    <FormattedMessage id="button.yes" />
                ) : (
                    <FormattedMessage id="button.no" />
                ),
        },
        {
            title: <FormattedMessage id="projectMember.roles" />,
            dataIndex: 'roles',
            key: 'roles',
            editable: true,
            render: (value: Role[]) => value.map((r) => r.name).join(', '),
        },
        {
            title: <FormattedMessage id="projectMember.products" />,
            dataIndex: 'products',
            key: 'products',
            editable: true,
            render: (value: any, projectMember: ProjectMember) =>
                projectMember.services
                    .filter((s) => s.access === 'member' || s.access === 'administrator')
                    .map((s) => intl.formatMessage({ id: `projectMember.products.${s.name}` }))
                    .join(', '),
        },
        {
            title: <FormattedMessage id="projectMembers.import.steps.2.action" />,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 100,
            render: (value: string, projectMember: ProjectMember) => (
                <>
                    {value ? (
                        <Tooltip title={<FormattedMessage id="projectMembers.import.steps.2.action.update.tooltip" />}>
                            <Tag color="blue">
                                <FormattedMessage id="projectMembers.import.steps.2.action.update" />
                            </Tag>
                        </Tooltip>
                    ) : (
                        <Tooltip title={<FormattedMessage id="projectMembers.import.steps.2.action.create.tooltip" />}>
                            <Tag color="green">
                                <FormattedMessage id="projectMembers.import.steps.2.action.create" />
                            </Tag>
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            dataIndex: 'action',
            align: 'center',
            width: 100,
            render: (value: any, record: ProjectMember) => {
                const editable = isEditing(record);
                return editable ? (
                    <Space size={0}>
                        <Button
                            type="link"
                            size="large"
                            icon={<Icon component={CheckmarkSvg} />}
                            onClick={() => save(record.email!)}
                        />
                        <Button
                            type="link"
                            size="large"
                            icon={<Icon component={CloseSvg} />}
                            onClick={() => setEditingKey(undefined)}
                        />
                    </Space>
                ) : (
                    <Button
                        type="link"
                        size="large"
                        icon={<Icon component={EditSvg} />}
                        disabled={!!editingKey}
                        onClick={() => edit(record)}
                    />
                );
            },
        },
    ];

    const mergedColumns: any = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (projectMember: ProjectMember) => ({
                projectMember,
                identifier: col.key,
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(projectMember),
                companies: companies,
                roles: roles,
            }),
        };
    });

    return (
        <>
            <Form form={form} component={false}>
                <h3 className={styles.subtitle}>
                    <FormattedMessage id="projectMembers.import.steps.2.members" />
                </h3>
                <Table
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    dataSource={projectMembersUpdated}
                    columns={mergedColumns}
                    rowKey="email"
                    sortDirections={['ascend']}
                    showSorterTooltip={false}
                    rowClassName="editable-row"
                    pagination={{
                        onChange: () => setEditingKey(undefined),
                        size: 'small',
                        hideOnSinglePage: true,
                    }}
                />
            </Form>
            <Space className={styles.buttons}>
                <Button
                    type="primary"
                    size="large"
                    onClick={upload}
                    icon={<Icon component={SaveSvg} />}
                    loading={saving}
                >
                    <FormattedMessage id="button.save" tagName="span" />
                </Button>
                <Button type="text" size="large" onClick={onBack}>
                    <FormattedMessage id="button.back" tagName="span" />
                </Button>
            </Space>
        </>
    );
};

export default SummaryComponent;

interface Props {
    hubId: string;
    projects: Project[];
    projectMembers: ProjectMember[];
    onSaveMembers: (result: EntityWithStatus<ProjectMember>[]) => void;
    onBack: () => void;
}

type EditableColumnsType<RecordType = unknown> = EditableColumnType<RecordType>[];

interface EditableColumnType<RecordType> extends ColumnType<RecordType> {
    editable?: boolean;
}
