/* eslint-disable react-hooks/exhaustive-deps, no-useless-escape */
import React, { useState, createContext, useContext, useEffect } from 'react';

import { Principal } from '../model/entities';
import userApi from '../api/UserApi';

class UserState {
    principal: Principal | undefined;
    isPrincipalStateLoading: boolean = false;
}

export const PrincipalStateContext = createContext(new UserState());

export const UserStateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [principal, setPrincipal] = useState<Principal>();
    const [isPrincipalStateLoading, setIsPrincipalStateLoading] = useState(false);

    const loadPrincipalState = async () => {
        try {
            setIsPrincipalStateLoading(true);
            const principalState = await userApi.getPrincipal();
            setPrincipal(principalState);
        } catch (e) {
            console.log('Load principal failed:', e);
        } finally {
            setIsPrincipalStateLoading(false);
        }
    };

    useEffect(() => {
        loadPrincipalState();
    }, []);

    return (
        <PrincipalStateContext.Provider value={{ principal, isPrincipalStateLoading }}>
            {children}
        </PrincipalStateContext.Provider>
    );
};

export const usePrincipalState = () => {
    const context = useContext(PrincipalStateContext);
    return context;
};
