import { Result, Spin } from 'antd';
import React from 'react';
import styles from './LoadingComponent.module.scss';
import Icon from '@ant-design/icons';
import LayoutComponent from '../LayoutComponent/LayoutComponent';
import WrapperComponent from '../WrapperComponent/WrapperComponent';

/**
 * The loading component.
 * @returns  the loading component
 */
const LoadingComponent: React.FC = () => {
    return (
        <LayoutComponent pageId="home">
            <WrapperComponent>
                <Result
                    icon={<Icon />}
                    title={
                        <>
                            <Spin size="large" className={styles.spin} />
                        </>
                    }
                    className={styles.result}
                />
            </WrapperComponent>
        </LayoutComponent>
    );
};
export default LoadingComponent;
