import moment from 'moment';
import numericService from './NumericService';
import stringService from './StringService';

/**
 * Service implemented for exporting files functionallity. For sending the files with the sorting applied in the table
 */
class ComparatorService {
    sort = (a: any, b: any, sortField: string, sortOrder?: boolean): number => {
        const fieldA = a[`${sortField}`];
        const fieldB = b[`${sortField}`];
        if (typeof fieldA === 'string' || typeof fieldB === 'string') {
            return sortOrder ? stringService.sort(fieldA, fieldB) : stringService.sort(fieldB, fieldA);
        } else if (typeof fieldA === 'number' || typeof fieldB === 'number') {
            return sortOrder ? numericService.sort(fieldA, fieldB) : numericService.sort(fieldB, fieldA);
        } else if (moment.isMoment(fieldA) || moment.isMoment(fieldB)) {
            return sortOrder ? (fieldA.isAfter(fieldB) ? 1 : -1) : fieldA.isAfter(fieldB) ? -1 : 1;
        } else if (typeof fieldA === 'boolean' || typeof fieldB === 'boolean') {
            if (sortOrder) {
                if (fieldA === fieldB) return 0;
                if (fieldA) return 1;
                return -1;
            } else {
                if (fieldA === fieldB) return 0;
                if (fieldA) return -1;
                return 1;
            }
        } else {
            return stringService.sort(fieldA, fieldB);
        }
    };
}

const comparatorService: ComparatorService = new ComparatorService();
export default comparatorService;
