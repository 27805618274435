import Icon from '@ant-design/icons';
import { Button, Descriptions, Space, Table, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import taskConversionProcessIssueApi from '../../../../../../api/TaskConversionProcessIssueApi';
import taskConversionProcessIssueFileApi from '../../../../../../api/TaskConversionProcessIssueFileApi';
import LayoutComponent from '../../../../../../components/LayoutComponent/LayoutComponent';
import TaskStatusComponent from '../../../../../../components/TaskStatusComponent/TaskStatusComponent';
import { Page } from '../../../../../../model/elements';
import { TaskConversionProcessIssue, TaskConversionProcessIssueFile } from '../../../../../../model/entities';
import { TaskStatusType } from '../../../../../../model/types';
import { ReactComponent as BackSvg } from '../../../../../../resources/images/back.svg';
import { ReactComponent as RefreshSvg } from '../../../../../../resources/images/refresh.svg';
import notificationService from '../../../../../../services/NotificationService';
import tableService from '../../../../../../services/TableService';
import styles from './TaskConversionProcessIssueFilesPage.module.scss';
import FileSizeComponent from '../../../../../../components/FileSizeComponent/FileSizeComponent';

/**
 * Returns the task conversion process issue files page.
 * @returns the task conversion process issue files page.
 */
const TaskConversionProcessIssueFilesPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [taskConversionProcessIssue, setTaskConversionProcessIssue] = useState<TaskConversionProcessIssue>();
    const [taskConversionProcessIssueFilesPage, setTaskConversionProcessIssueFilesPage] =
        useState<Page<TaskConversionProcessIssueFile>>();
    const [loading, setLoading] = useState<boolean>();
    const [searchText, setSearchText] = useState<string>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);
                const taskConversionProcessIssue = await taskConversionProcessIssueApi.get(+params.id);
                setTaskConversionProcessIssue(taskConversionProcessIssue);

                await listTaskConversionProcessIssueFiles(
                    0,
                    tableService.pageSize,
                    'name',
                    true,
                    taskConversionProcessIssue.id!,
                );
            } catch (error) {
                notificationService.displayError(error, intl);
            } finally {
                setLoading(false);
            }
        };
        init();
    }, [intl, params.id]);

    /*** METHODS ***/

    const refresh = async () => {
        try {
            setLoading(true);
            const taskConversionProcessIssue = await taskConversionProcessIssueApi.get(+params.id);
            setTaskConversionProcessIssue(taskConversionProcessIssue);

            const page = 0;
            const size = taskConversionProcessIssueFilesPage?.size || tableService.pageSize;
            const sortField = taskConversionProcessIssueFilesPage?.sort.field || 'name';
            const sortOrder = taskConversionProcessIssueFilesPage?.sort.order || true;
            await listTaskConversionProcessIssueFiles(
                page,
                size,
                sortField,
                sortOrder,
                taskConversionProcessIssue.id!,
                searchText,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const paginate = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        try {
            setLoading(true);
            const page = pagination.current! - 1;
            const pageSize = pagination.pageSize!;
            const sortField = sorter.field;
            const sortOrder = sorter.order === 'ascend';
            await listTaskConversionProcessIssueFiles(
                page,
                pageSize,
                sortField,
                sortOrder,
                taskConversionProcessIssue!.id!,
                searchText,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const search = async (searchText: string) => {
        try {
            setLoading(true);
            setSearchText(searchText);

            const page = 0;
            const pageSize = taskConversionProcessIssueFilesPage!.size;
            const sortField = taskConversionProcessIssueFilesPage!.sort.field!;
            const sortOrder = taskConversionProcessIssueFilesPage!.sort.order!;
            await listTaskConversionProcessIssueFiles(
                page,
                pageSize,
                sortField,
                sortOrder,
                taskConversionProcessIssue!.id!,
                searchText,
            );
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    const listTaskConversionProcessIssueFiles = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskId: number,
        searchText?: string,
    ) => {
        const taskMemberActionsPage = await taskConversionProcessIssueFileApi.list(
            page,
            size,
            sortField,
            sortOrder,
            taskId,
            searchText,
        );
        setTaskConversionProcessIssueFilesPage(taskMemberActionsPage);
    };

    /*** COMPONENTS ***/

    const placeholder: string = intl.formatMessage({ id: 'taskConversionProcessIssueFiles.search' });
    const items = taskConversionProcessIssueFilesPage ? taskConversionProcessIssueFilesPage.content : [];
    const columns: ColumnsType<TaskConversionProcessIssueFile> = [
        {
            title: <FormattedMessage id="taskConversionProcessIssueFile.name" />,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: 'ascend',
        },
        {
            title: <FormattedMessage id="taskConversionProcessIssueFile.size" />,
            dataIndex: 'size',
            key: 'size',
            width: 140,
            align: 'right',
            sorter: true,
            render: (value: number) => <FileSizeComponent value={value} />,
        },
        {
            title: <FormattedMessage id="taskConversionProcessIssueFile.audit.created" />,
            dataIndex: 'auditCreated',
            key: 'created',
            width: 240,
            align: 'center',
            render: (value: string, taskConversionProcessIssueFile: TaskConversionProcessIssueFile) => (
                <FormattedDate
                    value={taskConversionProcessIssueFile.audit!.created as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskConversionProcessIssueFile.audit.updated" />,
            dataIndex: 'auditUpdated',
            key: 'updated',
            width: 240,
            align: 'center',
            render: (value: string, taskConversionProcessIssueFile: TaskConversionProcessIssueFile) => (
                <FormattedDate
                    value={taskConversionProcessIssueFile.audit!.updated as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                    second="2-digit"
                />
            ),
        },
        {
            title: <FormattedMessage id="taskConversionProcessIssueFile.status" />,
            dataIndex: 'status',
            key: 'status',
            width: 200,
            align: 'center',
            render: (status: TaskStatusType) => <TaskStatusComponent status={status} />,
        },
    ];

    return (
        <LayoutComponent pageId="tasks">
            <Descriptions
                title={
                    <>
                        <span className={styles.title}>
                            #{taskConversionProcessIssue?.id} - {taskConversionProcessIssue?.title}
                        </span>
                        <TaskStatusComponent status={taskConversionProcessIssue?.status} />
                        <Space>
                            <Tooltip title={<FormattedMessage id="button.back" />}>
                                <Link
                                    to={`/task-conversion-issues/${taskConversionProcessIssue?.taskProjectConversionProcessId}`}
                                >
                                    <Button type="ghost" size="small" icon={<Icon component={BackSvg} />} />
                                </Link>
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="button.refresh" />}>
                                <Button
                                    type="ghost"
                                    size="small"
                                    icon={<Icon component={RefreshSvg} />}
                                    onClick={refresh}
                                />
                            </Tooltip>
                        </Space>
                    </>
                }
                column={2}
                size="small"
                className={styles.summary}
            >
                <Descriptions.Item label={<FormattedMessage id="taskConversionProcessProject.projectName" />}>
                    {taskConversionProcessIssue?.title}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="taskConversionProcessIssueFile.issue" />}>
                    {taskConversionProcessIssue?.identifier} - {taskConversionProcessIssue?.title}
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.created" />}>
                    <FormattedDate
                        value={taskConversionProcessIssue?.audit?.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
                <Descriptions.Item label={<FormattedMessage id="task.audit.updated" />}>
                    <FormattedDate
                        value={taskConversionProcessIssue?.audit?.updated as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                        second="2-digit"
                    />
                </Descriptions.Item>
            </Descriptions>
            <div className="toolbar">
                <Search placeholder={placeholder} onSearch={search} size="large" className="search" />
            </div>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(taskConversionProcessIssueFilesPage, {
                    position: ['bottomRight'],
                })}
                rowKey="id"
                onChange={paginate}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading}
            />
        </LayoutComponent>
    );
};

export default TaskConversionProcessIssueFilesPage;

type ParamsType = { id: string };
