import { ProjectIssueAttachment } from '../model/entities';
import { proxyApiAxios } from './CustomAxios';

class ProjectIssueAttachmentApi {
    list = async (hubId: string, projectId: string, issueId: string): Promise<ProjectIssueAttachment[]> => {
        const response = await proxyApiAxios.get<ProjectIssueAttachment[]>('/project-issue-attachments', {
            params: { hubId, projectId, issueId },
        });

        return response.data;
    };
}

const projectIssueAttachmentApi: ProjectIssueAttachmentApi = new ProjectIssueAttachmentApi();
export default projectIssueAttachmentApi;
