import { Tag, Tooltip } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EntityStatusErrorType, ProjectStatusType, TaskStatusType } from '../../model/types';

/**
 * This component represents a task status component.
 * @param props - the props
 */
export const TaskStatusComponent: React.FC<Props> = (props) => {
    const { status, error } = props;

    return (
        <>
            {status === 'CREATED' && (
                <Tag color="default">
                    <FormattedMessage id={status} />
                </Tag>
            )}
            {(status === 'IN_PROGRESS' || status === 'PENDING') && (
                <Tag color="blue">
                    <FormattedMessage id={status} />
                </Tag>
            )}
            {status === 'SYNCHRONIZING' && (
                <Tag color="geekblue">
                    <FormattedMessage id={status} />
                </Tag>
            )}
            {status === 'DOWNLOADING' && (
                <Tag color="geekblue">
                    <FormattedMessage id={status} />
                </Tag>
            )}
            {status === 'UPLOADING' && (
                <Tag color="geekblue">
                    <FormattedMessage id={status} />
                </Tag>
            )}
            {status === 'ARCHIVED' && (
                <Tag color="geekblue">
                    <FormattedMessage id={status} />
                </Tag>
            )}
            {(status === 'SUCCESSFUL' || status === 'ACTIVE') && (
                <Tag color="green">
                    <FormattedMessage id={status} />
                </Tag>
            )}
            {(status === 'FAILED' || status === 'SUSPENDED') && (
                <Tooltip title={error && <FormattedMessage id={error} />}>
                    <Tag color="orange">
                        <FormattedMessage id={status} />
                    </Tag>
                </Tooltip>
            )}
            {status === 'EMPTY' && (
                <Tag color="default">
                    <FormattedMessage id={status} />
                </Tag>
            )}
            {status === 'ERROR' &&
                (error === 'TARGET_FILE_SIZE_NOT_VALID' ? (
                    <Tooltip key="error" title={<FormattedMessage id="taskSyncFileTarget.error.fileSize" />}>
                        <Tag color="red">
                            <FormattedMessage id={status} />
                        </Tag>
                    </Tooltip>
                ) : (
                    <Tag color="red">
                        <FormattedMessage id={status} />
                    </Tag>
                ))}
        </>
    );
};
export default TaskStatusComponent;

interface Props {
    status?: TaskStatusType | ProjectStatusType;
    error?: EntityStatusErrorType;
}
