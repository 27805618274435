import moment from 'moment';
import { Page } from '../model/elements';
import { TaskSyncLink } from '../model/entities';
import { syncApiAxios } from './CustomAxios';

class TaskSyncLinkApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskSyncId: number,
        searchText?: string,
    ): Promise<Page<TaskSyncLink>> => {
        const response = await syncApiAxios.get<Page<TaskSyncLink>>('/task-sync-links', {
            params: { page, size, sortField, sortOrder, taskSyncId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.audit!.created = d.audit!.created && moment.utc(d.audit!.created).local();
            d.audit!.updated = d.audit!.updated && moment.utc(d.audit!.updated).local();
        });

        return response.data;
    };

    get = async (id: number): Promise<TaskSyncLink> => {
        const response = await syncApiAxios.get<TaskSyncLink>(`/task-sync-links/${id}`);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };
}

const taskSyncLinkApi: TaskSyncLinkApi = new TaskSyncLinkApi();
export default taskSyncLinkApi;
