import { Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router';
import connectionApi from '../../../../../../api/ConnectionApi';
import LayoutComponent from '../../../../../../components/LayoutComponent/LayoutComponent';
import { Connection, ConnectionLink, ConnectionLinkImport, EntityWithStatus } from '../../../../../../model/entities';
import notificationService from '../../../../../../services/NotificationService';
import styles from './ImportPage.module.scss';
import ResultComponent from './ResultComponent/ResultComponent';
import SummaryComponent from './SummaryComponent/SummaryComponent';
import UploadComponent from './UploadComponent/UploadComponent';

/**
 * Returns the connection links import page.
 * @returns the connection links import page.
 */
const ImportPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [step, setStep] = useState<number>(0);
    const [connectionLinkImports, setConnectionLinkImports] = useState<ConnectionLinkImport[]>([]);
    const [connection, setConnection] = useState<Connection>();
    const [result, setResult] = useState<EntityWithStatus<ConnectionLink>[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const connection = await connectionApi.get(+params.id);

                setConnection(connection);
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [intl, params.id]);

    /*** METHODS ***/

    const showConnectionLinkImports = (connectionLinkImports: ConnectionLinkImport[]) => {
        const step = 1;
        setConnectionLinkImports(connectionLinkImports);
        setStep(step);
    };

    const showResults = (result: EntityWithStatus<ConnectionLink>[]) => {
        const step = 2;
        setResult(result);
        setStep(step);
    };

    const back = () => {
        const stepUpdated = step - 1;
        setStep(stepUpdated);
    };

    /*** COMPONENTS ***/

    return (
        <LayoutComponent pageId="connections">
            <h1 className={styles.title}>{connection?.name}</h1>
            <h4 className={styles.hubs}>
                <p>
                    <FormattedMessage
                        id="connectionLinks.import.sourceHub"
                        values={{ source: connection?.source?.name }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id="connectionLinks.import.targetHub"
                        values={{ target: connection?.target?.name }}
                    />
                </p>
            </h4>
            <Steps
                current={step}
                className={styles.steps}
                items={[
                    { title: <FormattedMessage id="connectionLinks.import.steps.1" /> },
                    { title: <FormattedMessage id="connectionLinks.import.steps.2" /> },
                    { title: <FormattedMessage id="connectionLinks.import.steps.3" /> },
                ]}
            />
            <div hidden={step !== 0}>
                <UploadComponent connection={connection} onConnectionLinkImports={showConnectionLinkImports} />
            </div>
            {step === 1 && (
                <SummaryComponent
                    connectionLinkImports={connectionLinkImports}
                    onSaveConnectionLinks={showResults}
                    onBack={back}
                    connection={connection}
                />
            )}
            {step === 2 && result && <ResultComponent result={result} connection={connection} />}
        </LayoutComponent>
    );
};

export default ImportPage;

type ParamsType = { id: string };
