import { ProjectSubmission } from '../model/entities';
import { Page } from '../model/elements';
import adminApiAxios from './CustomAxios';
import moment from 'moment';
import FileSaver from 'file-saver';

class ProjectSubmissionApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string,
    ): Promise<Page<ProjectSubmission>> => {
        const response = await adminApiAxios.get<Page<ProjectSubmission>>('/project-submissions', {
            params: { page, size, sortField, sortOrder, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((schedule) => this.loadDates(schedule));

        return response.data;
    };

    get = async (id: number): Promise<ProjectSubmission> => {
        const response = await adminApiAxios.get<ProjectSubmission>(`/project-submissions/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (projectSubmission: ProjectSubmission, file: File): Promise<ProjectSubmission> => {
        const formData: FormData = this.buildFormData(projectSubmission, file);
        const response = await adminApiAxios.post<ProjectSubmission>('/project-submissions', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        this.loadDates(response.data);

        return response.data;
    };

    update = async (projectSubmission: ProjectSubmission, file?: File): Promise<ProjectSubmission> => {
        const formData: FormData = this.buildFormData(projectSubmission, file);
        const response = await adminApiAxios.post<ProjectSubmission>(
            `/project-submissions/${projectSubmission.id}`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (projectSubmission: ProjectSubmission): Promise<void> => {
        await adminApiAxios.delete(`/project-submissions/${projectSubmission.id}`);
    };

    download = async (projectSubmission: ProjectSubmission): Promise<void> => {
        const response = await adminApiAxios.get<Blob>(`/project-submissions/${projectSubmission.id}/contacts-file`, {
            responseType: 'blob',
        });
        FileSaver.saveAs(response.data, projectSubmission.contactsFile!.name);
    };

    /**
     * Builds a form data from a project submission.
     * @param projectSubmission the project submission
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (projectSubmission: ProjectSubmission, file?: File) => {
        const formData: FormData = new FormData();
        projectSubmission.id && formData.append('id', projectSubmission.id.toString());
        projectSubmission.organizationId &&
            formData.append('organizationId', projectSubmission.organizationId.toString());
        projectSubmission.projectCode && formData.append('projectCode', projectSubmission.projectCode);
        projectSubmission.projectCity && formData.append('projectCity', projectSubmission.projectCity);
        projectSubmission.projectCountry && formData.append('projectCountry', projectSubmission.projectCountry);
        projectSubmission.projectType && formData.append('projectType', projectSubmission.projectType);
        projectSubmission.practicalCompletionDate &&
            formData.append('practicalCompletionDate', projectSubmission.practicalCompletionDate.format('YYYY-MM-DD'));
        projectSubmission.designTemplate && formData.append('designTemplate', projectSubmission.designTemplate);
        projectSubmission.templateGeneration &&
            formData.append('templateGeneration', projectSubmission.templateGeneration);
        projectSubmission.state && formData.append('state', projectSubmission.state);
        file && formData.append('contactsFile.name', file.name);
        file && formData.append('contactsFile.file', file);

        return formData;
    };

    /**
     * Loads the dates of a schedule.
     * @param schedule - the schedule
     */
    private loadDates(projectSubmission: ProjectSubmission) {
        projectSubmission.practicalCompletionDate =
            projectSubmission.practicalCompletionDate && moment.utc(projectSubmission.practicalCompletionDate).local();
        projectSubmission.audit!.created =
            projectSubmission.audit!.created && moment.utc(projectSubmission.audit!.created).local();
        projectSubmission.audit!.updated =
            projectSubmission.audit!.updated && moment.utc(projectSubmission.audit!.updated).local();
    }
}

const projectSubmissionApi: ProjectSubmissionApi = new ProjectSubmissionApi();
export default projectSubmissionApi;
