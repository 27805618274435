import { Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from '@ant-design/icons';
import { ReactComponent as SaveSvg } from '../../../../resources/images/save.svg';
import EditableCell from './EditableCell/EditableCell';
import styles from './ImportPage.module.scss';
import { useSetupProjectsImport } from './SetupProjectsImportContext';

function SummaryComponent() {
    const intl = useIntl();
    const { projects, handleGoBack, handleSubmitProjects, isResultsLoading, platform } = useSetupProjectsImport();

    const fieldNames = intl
        .formatMessage({
            id: `projects.new.import.excel.steps.1.text.placeholder.${`${platform}`.toLowerCase()}`,
        })
        .replace(/\s/g, '_')
        .split(/;_|;/);

    return (
        <>
            <div className={platform === 'BIM360' ? `${styles.bim360} ${styles.summarybox}` : styles.summarybox}>
                <table>
                    <colgroup>
                        <col span={1} />
                        <col span={1} />
                        <col span={1} />
                        <col span={1} />
                        <col span={1} />
                        <col span={1} />
                        <col span={1} />
                        <col span={1} />
                        {platform === 'BIM360' && (
                            <>
                                <col span={1} />
                                <col span={1} />
                                <col span={1} />
                                <col span={1} />
                            </>
                        )}
                    </colgroup>
                    <thead>
                        <tr>
                            {fieldNames.map((field, index) => (
                                <td key={`import-projects-field-${index}`}>{field}</td>
                            ))}
                            <td className={styles.action}>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {projects.map((_, index) => (
                            <EditableCell key={`project-index${index}`} projectIndex={index} />
                        ))}
                    </tbody>
                </table>
            </div>
            <div className={styles.buttongroup}>
                <Button
                    className={styles.loadbutton}
                    size="large"
                    type="primary"
                    icon={<Icon component={SaveSvg} />}
                    onClick={handleSubmitProjects}
                    disabled={false}
                    loading={isResultsLoading}
                >
                    <FormattedMessage id="button.save" />
                </Button>
                <Button className={styles.backbutton} size="large" type="link" onClick={handleGoBack} loading={false}>
                    <FormattedMessage id="button.back" />
                </Button>
            </div>
        </>
    );
}

export default SummaryComponent;
