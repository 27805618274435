import { Auth } from '@aws-amplify/auth';
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types';
import { CognitoUser } from 'amazon-cognito-identity-js';
import userApi from '../api/UserApi';
import configuration from '../configs/IConfiguration';
import { CustomAuth, Organization, Principal } from '../model/entities';
import autodeskService from '../services/AutodeskService';
import { IAuthService } from './IAuthService';

export default class AwsAuthService implements IAuthService {
    auth: CustomAuth | undefined;
    awsCognitoClient: AuthOptions;

    constructor() {
        this.awsCognitoClient = Auth.configure(configuration.getAuth());
    }

    getAuth = async (): Promise<CustomAuth | undefined> => {
        try {
            const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
            if (cognitoUser) {
                this.auth = await this.createAuthFromCognito();
            } else {
                await Auth.federatedSignIn();
            }
        } catch (error) {
            console.log(error);
            this.auth = undefined;
            await Auth.federatedSignIn();
        }

        return this.auth;
    };

    getAuthByOrganization = async (organization: Organization): Promise<CustomAuth | undefined> => {
        try {
            if (this.auth) {
                this.auth.organization = organization;
                this.auth = await this.createAuthFromCognito();
            }
        } catch (error) {
            console.log(error);
            this.auth = undefined;
            await Auth.federatedSignIn();
        }

        return this.auth;
    };

    getCachedAuth = (): CustomAuth | undefined => this.auth;

    getAccessToken = async () => {
        const session = await Auth.currentSession();
        return session.getAccessToken().getJwtToken();
    };

    getOrganizationId = (): string | undefined => {
        let organizationId: string | undefined;
        if (this.auth && this.auth.organization) {
            organizationId = this.auth.organization.id;
        } else {
            organizationId = localStorage.getItem('organizationId') || undefined;
        }

        return organizationId;
    };

    signIn = async () => {
        await Auth.federatedSignIn();
    };

    signOut = async () => {
        autodeskService.logOut();
        await Auth.signOut();
        this.auth = undefined;
    };

    isAuthenticated = async () => {
        let isAuthenticated: boolean = false;

        try {
            const cognitoUser: CognitoUser = await Auth.currentUserInfo();
            isAuthenticated = !!cognitoUser;
        } catch (error) {}

        return isAuthenticated;
    };

    /**
     * Returns the auth from the principal.
     * @returns the auth
     */
    private async createAuthFromCognito(): Promise<CustomAuth | undefined> {
        // get principal
        let customAuth: CustomAuth | undefined;
        try {
            const principal: Principal = await userApi.getPrincipal();
            customAuth = {
                id: principal.id!,
                email: principal.email!,
                firstName: principal.firstName!,
                lastName: principal.lastName!,
                organization: principal.organization,
                organizations: principal.organizations,
                authorities: principal.authorities,
                initialized: principal.authorities.length > 0,
                members: [],
            };
        } catch (error: any) {
            console.log(error);
            // user not authenticated in resource server - non initialized user
            customAuth = {
                id: '',
                email: '',
                firstName: '',
                lastName: '',
                organizations: [],
                authorities: [],
                initialized: false,
                members: [],
            };
        }

        return customAuth;
    }
}
