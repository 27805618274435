import Icon, { CopyOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Popconfirm, Row, Select, Space, Tabs, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useHistory, useParams } from 'react-router-dom';
import connectionApi from '../../../../api/ConnectionApi';
import hubApi from '../../../../api/HubApi';
import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import WrapperComponent from '../../../../components/WrapperComponent/WrapperComponent';
import CustomContext from '../../../../context/CustomContext';
import { Connection, Hub } from '../../../../model/entities';
import { ReactComponent as SaveSvg } from '../../../../resources/images/save.svg';
import { ReactComponent as TrashCanSvg } from '../../../../resources/images/trash-can.svg';
import notificationService from '../../../../services/NotificationService';
import rolesService from '../../../../services/RolesService';
import stringService from '../../../../services/StringService';
import ConnectionCloneModal from './ConnectionCloneModal/ConnectionCloneModal';
import ConnectionLinksComponent from './ConnectionLinksComponent/ConnectionLinksComponent';
import styles from './ConnectionPage.module.scss';
import ConnectionSchedulesComponent from './ConnectionSchedulesComponent/ConnectionSchedulesComponent';
import ConnectionTasksComponent from './ConnectionTasksComponent/ConnectionTasksComponent';

/**
 * Returns the connection page.
 * @returns the connection page.
 */
const ConnectionPage = (): React.ReactElement => {
    /*** HOOKS ***/

    const [form] = Form.useForm();
    const intl = useIntl();
    const params = useParams<ParamsType>();
    const history = useHistory();
    const context = useContext(CustomContext);
    const { auth } = context;
    const organizationId = auth!.organization!.id;
    const [connection, setConnection] = useState<Connection>();
    const [hubs, setHubs] = useState<Hub[]>([]);
    const [connectionCloneModalVisible, setConnectionCloneModalVisible] = useState<boolean>();
    const [saving, setSaving] = useState<boolean>();
    const [loading, setLoading] = useState<false | 'deleting'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                if (params.id === 'new') {
                    const connection: Connection = { organizationId };
                    const hubs = await hubApi.list();
                    setConnection(connection);
                    setHubs(hubs);
                    form.setFieldsValue(connection);
                } else {
                    const responses = await Promise.all([connectionApi.get(+params.id), hubApi.list()]);
                    const connection = responses[0];
                    const hubs = responses[1];
                    setConnection(connection);
                    setHubs(hubs);
                    form.setFieldsValue(connection);
                }
            } catch (error) {
                notificationService.displayError(error, intl);
            }
        };
        init();
    }, [form, intl, organizationId, params.id]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setSaving(true);
            const sourceHub = hubs.find((h) => h.id === values.source.id);
            const targetHub = hubs.find((h) => h.id === values.target.id);
            let connectionUpdated: Connection = Object.assign({}, connection, values, {
                source: sourceHub,
                target: targetHub,
            });
            connectionUpdated = connectionUpdated.id
                ? await connectionApi.update(connectionUpdated)
                : await connectionApi.create(connectionUpdated);
            message.success(intl.formatMessage({ id: 'status.saved' }));
            setConnection(connectionUpdated);
        } catch (error) {
            notificationService.displayError(error, intl, [{ status: 409, message: 'connection.status.duplicate' }]);
        } finally {
            setSaving(false);
        }
    };

    const remove = async () => {
        try {
            setLoading('deleting');
            await connectionApi.delete(connection!);
            history.push(`/sync/connections`);
        } catch (error) {
            notificationService.displayError(error, intl);
        } finally {
            setLoading(false);
        }
    };

    /*** COMPONENTS ***/

    const sourceHubsOptions = hubs
        .sort((a, b) => stringService.sort(a.name, b.name))
        .map((hub) => (
            <Select.Option key={hub.id} value={hub.id}>
                {hub.provider} - {hub.name}
            </Select.Option>
        ));
    const targetHubsOptions = hubs
        .sort((a, b) => stringService.sort(a.name, b.name))
        .map((hub) => (
            <Select.Option key={hub.id} value={hub.id}>
                {hub.provider} - {hub.name}
            </Select.Option>
        ));

    return (
        <LayoutComponent pageId="connections">
            <WrapperComponent
                title={
                    <>
                        <FormattedMessage id="connection.title" />
                        {connection && connection.cloned && (
                            <Tag className={styles.tag} color="orange">
                                <FormattedMessage id="connection.cloned" />
                            </Tag>
                        )}
                    </>
                }
            >
                <Form form={form} onFinish={save} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label={<FormattedMessage id="connection.name" />}
                                name="name"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Input maxLength={100} size="large" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item label={<FormattedMessage id="connection.description" />} name="description">
                                <TextArea maxLength={300} size="large" rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label={<FormattedMessage id="connection.source" />}
                                name={['source', 'id']}
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select size="large" disabled={connection && !!connection.id}>
                                    {sourceHubsOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<FormattedMessage id="connection.target" />}
                                name={['target', 'id']}
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Select size="large" disabled={connection && !!connection.id}>
                                    {targetHubsOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item className="buttons">
                        <Space>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                icon={<Icon component={SaveSvg} />}
                                loading={saving}
                                ghost={!!connection?.id}
                            >
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                            <Button
                                type="ghost"
                                size="large"
                                hidden={
                                    !connection || !connection.id || !rolesService.hasAllRoles(auth, ['ROLE_ADMIN'])
                                }
                                icon={<CopyOutlined />}
                                onClick={() => setConnectionCloneModalVisible(true)}
                            >
                                <FormattedMessage id="button.clone" tagName="span" />
                            </Button>
                            <Popconfirm
                                title={<FormattedMessage id="connection.delete" />}
                                onConfirm={remove}
                                okText={<FormattedMessage id="button.yes" />}
                                cancelText={<FormattedMessage id="button.no" />}
                            >
                                <Button
                                    type="ghost"
                                    size="large"
                                    hidden={!connection || !connection.id}
                                    icon={<Icon component={TrashCanSvg} />}
                                    loading={loading === 'deleting'}
                                >
                                    <FormattedMessage id="button.delete" tagName="span" />
                                </Button>
                            </Popconfirm>
                            <Link to="/sync/connections">
                                <Button type="text" size="large">
                                    <FormattedMessage id="button.back" tagName="span" />
                                </Button>
                            </Link>
                        </Space>
                    </Form.Item>
                </Form>
                {connectionCloneModalVisible && connection && connection.id && (
                    <ConnectionCloneModal
                        connectionId={connection.id}
                        onSave={() => setConnectionCloneModalVisible(false)}
                        onCancel={() => setConnectionCloneModalVisible(false)}
                    />
                )}
                <ConnectionTabsComponent connection={connection} />
            </WrapperComponent>
        </LayoutComponent>
    );
};

export default ConnectionPage;

type ParamsType = { id: string };

const ConnectionTabsComponent = (props: TabsProps): React.ReactElement => {
    const { connection } = props;
    return (
        <Tabs
            defaultActiveKey="links"
            size="large"
            items={[
                {
                    key: 'links',
                    label: <FormattedMessage id="connection.links" />,
                    children: connection && connection.id && <ConnectionLinksComponent connection={connection} />,
                },
                {
                    key: 'schedules',
                    label: <FormattedMessage id="connection.schedules" />,
                    children: connection && connection.id && <ConnectionSchedulesComponent connection={connection} />,
                },
                {
                    key: 'tasks',
                    label: <FormattedMessage id="connection.tasks" />,
                    children: connection && connection.id && <ConnectionTasksComponent connection={connection} />,
                },
            ]}
        />
    );
};

interface TabsProps {
    connection?: Connection;
}
