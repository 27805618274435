import React from 'react';
import { Form, Input, Select } from 'antd';
import { Company, Member } from '../../../../../../model/entities';
import { FormattedMessage } from 'react-intl';

const EditableCell: React.FC<Props> = ({
    editing,
    identifier,
    dataIndex,
    title,
    record,
    index,
    children,
    companies,
    ...restProps
}) => {
    return <td {...restProps}>{editing ? getFormItem(identifier, dataIndex, companies) : children}</td>;
};
export default EditableCell;

const getFormItem = (identifier: string, dataIndex: any, companies: Company[]) => {
    switch (identifier) {
        case 'email':
            return (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        { required: true, message: <FormattedMessage id="status.mandatory" /> },
                        {
                            type: 'email',
                            message: <FormattedMessage id="status.email.invalid" />,
                        },
                    ]}
                >
                    <Input maxLength={200} />
                </Form.Item>
            );
        case 'firstName':
            return (
                <Form.Item name={dataIndex} style={{ margin: 0 }}>
                    <Input maxLength={100} />
                </Form.Item>
            );
        case 'lastName':
            return (
                <Form.Item name={dataIndex} style={{ margin: 0 }}>
                    <Input maxLength={100} />
                </Form.Item>
            );
        case 'company':
            return (
                <Form.Item name={dataIndex} style={{ margin: 0 }}>
                    <Select allowClear>{companyOptions(companies)}</Select>
                </Form.Item>
            );
        default:
            break;
    }
};

const companyOptions = (companies: Company[]) =>
    companies.map((company) => (
        <Select.Option key={company.id!} value={company.id}>
            {company.name}
        </Select.Option>
    ));

interface Props extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    identifier: string;
    dataIndex: any;
    title: any;
    record: Member;
    index: number;
    children: React.ReactNode;
    companies: Company[];
}
