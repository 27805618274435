import React from 'react';

import LayoutComponent from '../../../../components/LayoutComponent/LayoutComponent';
import TaskProjectSummary from './TaskProjectSummary';
import TaskProjectTable from './TaskProjectTable';
import TaskProjectToolbar from './TaskProjectToolbar';
import { TaskProjectProvider } from './TaskProjectContext';

const TaskProjectPage: React.FC = () => {
    return (
        <TaskProjectProvider>
            <LayoutComponent pageId="tasks">
                <TaskProjectSummary />
                <TaskProjectToolbar />
                <TaskProjectTable />
            </LayoutComponent>
        </TaskProjectProvider>
    );
};

export default TaskProjectPage;
