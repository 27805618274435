import moment from 'moment';
import { TaskProjectIssue } from '../model/entities';
import adminApiAxios from './CustomAxios';

class TaskProjectIssuesApi {
    get = async (id: number): Promise<TaskProjectIssue> => {
        const response = await adminApiAxios.get<TaskProjectIssue>(`/tasks/project-issues/${id}`);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };

    create = async (task: TaskProjectIssue): Promise<TaskProjectIssue> => {
        const response = await adminApiAxios.post<TaskProjectIssue>('/tasks/project-issues', task);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };
}

const taskProjectIssuesApi: TaskProjectIssuesApi = new TaskProjectIssuesApi();
export default taskProjectIssuesApi;
