import moment from 'moment';
import { Page } from '../model/elements';
import { TaskProjectIssueAction } from '../model/entities';
import adminApiAxios from './CustomAxios';

class TaskProjectIssueActionApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taskId: number,
        searchText?: string,
    ): Promise<Page<TaskProjectIssueAction>> => {
        const response = await adminApiAxios.get<Page<TaskProjectIssueAction>>('/task-project-issue-actions', {
            params: { page, size, sortField, sortOrder, taskId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.audit!.created = d.audit!.created && moment.utc(d.audit!.created).local();
            d.audit!.updated = d.audit!.updated && moment.utc(d.audit!.updated).local();
        });

        return response.data;
    };

    get = async (id: number): Promise<TaskProjectIssueAction> => {
        const response = await adminApiAxios.get<TaskProjectIssueAction>(`/task-project-issue-actions/${id}`);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };
}

const taskProjectIssueActionApi: TaskProjectIssueActionApi = new TaskProjectIssueActionApi();
export default taskProjectIssueActionApi;
