import { Hub } from '../model/entities';
import { proxyApiAxios } from './CustomAxios';

class HubApi {
    list = async (application?: boolean): Promise<Hub[]> => {
        const response = await proxyApiAxios.get<Hub[]>('/hubs', {
            params: { application },
        });
        return response.data;
    };
}

const hubApi: HubApi = new HubApi();
export default hubApi;
