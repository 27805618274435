import moment from 'moment';
import { Page } from '../model/elements';
import { TaskSync } from '../model/entities';
import adminApiAxios, { syncApiAxios } from './CustomAxios';

class TaskSyncApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        connectionId: number,
        searchText?: string,
    ): Promise<Page<TaskSync>> => {
        const response = await adminApiAxios.get<Page<TaskSync>>('/tasks/syncs', {
            params: { page, size, sortField, sortOrder, connectionId, searchText },
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((d) => {
            d.audit!.created = d.audit!.created && moment.utc(d.audit!.created).local();
            d.audit!.updated = d.audit!.updated && moment.utc(d.audit!.updated).local();
        });

        return response.data;
    };

    get = async (id: number): Promise<TaskSync> => {
        const response = await adminApiAxios.get<TaskSync>(`/tasks/syncs/${id}`);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };

    create = async (task: TaskSync): Promise<TaskSync> => {
        const response = await adminApiAxios.post<TaskSync>('/tasks/syncs', task);
        response.data.audit!.created = response.data.audit!.created && moment.utc(response.data.audit!.created).local();
        response.data.audit!.updated = response.data.audit!.updated && moment.utc(response.data.audit!.updated).local();

        return response.data;
    };

    retry = async (id: number, max: number): Promise<TaskSync> => {
        const response = await syncApiAxios.post<TaskSync>(
            `/task-syncs/${id}/retries`,
            {},
            {
                params: { max },
            },
        );

        return response.data;
    };
}

const taskSyncApi: TaskSyncApi = new TaskSyncApi();
export default taskSyncApi;
