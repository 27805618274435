import React from 'react';
import styles from './SelectionComponent.module.scss';
import { Button, Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';

const SelectionComponent: React.FC<Props> = (props: Props) => {
    const { label, items, onClearAll } = props;

    return (
        <Collapse bordered={false} ghost className={styles.main}>
            <Collapse.Panel
                header={
                    <>
                        {label} {items.length}
                    </>
                }
                key="panel"
            >
                <div className={styles.content}>
                    {items.map((item: React.ReactNode | string, i: number) => (
                        <span key={i}>
                            {i !== 0 && ', '}
                            {item}
                        </span>
                    ))}

                    <Button type="link" className={styles.clear} onClick={onClearAll} hidden={items.length === 0}>
                        <FormattedMessage id="button.clearAll" />
                    </Button>
                </div>
            </Collapse.Panel>
        </Collapse>
    );
};
export default SelectionComponent;

interface Props {
    label: React.ReactNode | string;
    items: React.ReactNode[] | string[];
    onClearAll: () => void;
}
